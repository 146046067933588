<template>
  <div class="contact-info">
    <Alert
      v-if="showEmailSpellingWarning"
      variant="warning"
      :show-icon="false"
      :dismissible="false"
      class="mt-3"
    >
      {{ $t('checkout.email.warning') }}
    </Alert>
    <b-form-row
      v-if="shouldIncludeContactInput()"
      class="required mt-3"
      :class="{ 'mb-3': !enableSms }"
    >

      <!-- Email -->
      <b-tooltip
        v-if="disableEmail"
        :target="`email-target`"
        :title="$t('checkout.email.tooltip')"
      />

      <b-col
        v-if="shouldInclude('email')"
        md="4"
      >
        <div id="email-target">
          <label
            :for="`email-${_uid}`"
            class="sr-only sr-only-focusable"
          >{{ emailLabel }}</label>
          <b-form-input
            :id="`email-${_uid}`"
            ref="emailInputRef"
            v-model="extraFieldsData.email"
            :disabled="disableEmail"
            :state="!v$.extraFieldsData.email.$error"
            name="email"
            type="email"
            autocomplete="email"
            :placeholder="emailLabel"
            aria-describedby="email-validation-errors"
            @input="v$.extraFieldsData.email.$reset"
            @blur="validateAndEmit('email') && shouldShowSpellingWarning()"
          />
          <ValidationErrors
            id="email-validation-errors"
            :validator="v$.extraFieldsData.email"
            :errors="{
              required: $t('extra_fields.email.required'),
              email: $t('extra_fields.email.invalid'),
              maxLength: $t('field_max_length', { len: 100 }),
              nonCard: $t('card.prevent.wrong.input.error'),
            }"
          />
        </div>
      </b-col>

      <!-- Phone -->
      <b-col
        v-if="shouldInclude('phone')"
        md="4"
        class="mt-2 mt-md-0"
      >
        <label
          :for="`phone-number-${_uid}`"
          class="sr-only sr-only-focusable"
        >{{ phoneLabel }}</label>
        <b-form-input
          :id="`phone-number-${_uid}`"
          ref="phoneInputRef"
          v-model="extraFieldsData.phone"
          :state="!v$.extraFieldsData.phone.$error"
          name="phone"
          type="tel"
          :placeholder="phoneLabel"
          autocomplete="tel"
          aria-describedby="phone-validation-errors"
          @input="v$.extraFieldsData.phone.$reset()"
          @blur="validateAndEmit('phone')"
        />
        <ValidationErrors
          id="phone-validation-errors"
          :validator="v$.extraFieldsData.phone"
          :errors="{
            required: $t('extra_fields.phone.required'),
            maxLength: $t('field_max_length', { len: 30 }),
            validPhone: $t('extra_fields.phone.invalid'),
          }"
        />
      </b-col>

      <div
        v-if="shouldIncludeRequiredMessage()"
        class="form-group ml-3 my-md-auto mt-3"
      >
        * {{ $t('required.default') }}</div>
    </b-form-row>

    <b-form-row
      v-if="enableSms"
      class="mt-1 ml-0"
    >
      <b-col
        md="12"
        class="ml-0"
      >
        <b-form-group
          v-slot="{ ariaDescribedby }"
          :label="loggedIn ? $t('checkout.all_messages.preference') : $t('checkout.receipt.preference')"
          label-class="font-weight-bold pl-0"
          label-cols="auto"
        >
          <b-form-radio-group
            v-model="extraFieldsData.contactPreference"
            :aria-describedby="ariaDescribedby"
            name="contact-preference"
          >
            <b-form-radio value="email">{{ $t('email.label') }}</b-form-radio>
            <span id="sms-tooltip">
              <b-form-radio value="sms">{{ $t('my-settings.messaging.sms') }}</b-form-radio>
            </span>
          </b-form-radio-group>
          <b-tooltip
            :disabled="extraFieldsData.contactPreference !== 'sms'"
            target="sms-tooltip"
            placement="bottom"
            :title="$t('messaging.ensure_mobile')"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-row
      v-if="shouldIncludeMessagingLabel"
      class="mb-3 mx-0"
      :class="{'mt-3': !shouldIncludeContactInput() && !enableSms}"
    >
      {{ messagingLabel() }}
    </b-row>
    <b-row
      v-if="enableSms && loggedIn"
      class="mb-3 mx-0 small"
    >
      {{ $t('checkout.all_messages.fineprint') }}
    </b-row>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'
import { nonCard, validPhone } from '@grantstreet/psc-js/utils/validators.js'
import levenshtein from 'js-levenshtein'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { mapGetters } from 'vuex'

// Returns true if the passed extra field is displayed in this context.
function isExtraFieldDisplayed (vm, fieldName) {
  const field = vm.extraFields[fieldName]
  if (!field) return false
  if (vm.isSavedTender && !field.showForSavedTenders) return false
  if (vm.tenderProvidesContactInfo && !vm.loggedIn) return false
  return true
}

function requireExtraField (fieldName) {
  return helpers.withParams({ type: 'requireField', fieldName }, function (val) {
    const field = this.extraFields[fieldName]
    if (!isExtraFieldDisplayed(this, fieldName)) return true
    if (!field.required) return true
    return required.$validator(val)
  })
}

export default {
  components: { Alert, ValidationErrors },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    extraFields: {
      type: Object,
      required: true,
    },
    extraFieldsData: {
      type: Object,
      required: true,
    },
    isSavedTender: {
      type: Boolean,
      default: false,
    },
    enableSms: {
      type: Boolean,
      required: true,
    },
    selectedTenderFormType: {
      type: String,
      required: true,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      showEmailSpellingWarning: false,
    }
  },
  computed: {
    ...mapGetters('eWallet', [
      'loggedIn',
    ]),

    emailLabel () {
      return this.asterisk('email', this.$t('email.label'))
    },

    phoneLabel () {
      return this.asterisk('phone', this.$t('extra_fields.phone.label'))
    },

    receiptOptions () {
      return [
        { text: this.$t('email.label'), value: 'email' },
        { text: this.$t('my-settings.messaging.sms'), value: 'sms' },
      ]
    },

    isImmediatePayPal () {
      return this.selectedTenderFormType === 'paypal' && !this.cartHasDelayedPayments
    },

    isDelayedPaypal () {
      return this.selectedTenderFormType === 'paypal' && this.cartHasDelayedPayments
    },

    tenderProvidesContactInfo () {
      return this.isDelayedPaypal
        ? ['apple', 'google'].includes(this.selectedTenderFormType)
        : ['apple', 'google', 'paypal', 'venmo'].includes(this.selectedTenderFormType)
    },

    disableEmail () {
      return this.loggedIn && this.extraFields.email.disableIfLoggedIn
    },

    shouldIncludeMessagingLabel () {
      // Either we display the required message when sms is selected, or when an
      // anonymous user pays with a tender that provides contact info
      return (this.enableSms && this.extraFieldsData.contactPreference === 'sms') ||
              (this.tenderProvidesContactInfo && !this.loggedIn)
    },

  },

  validations: {
    extraFieldsData: {
      // The extra checks are because we only want to validate the fields if the
      // parent app specified they want to include them.
      email: {
        required: requireExtraField('email'),
        email: function (val) {
          const field = this.extraFields.email
          if (!field) return true
          return email.$validator(val)
        },
        maxLength: maxLength(100),
        nonCard,
      },

      phone: {
        required: requireExtraField('phone'),
        maxLength: maxLength(30),

        validPhone: function (val) {
          if (!val) return true
          if (!isExtraFieldDisplayed(this, 'phone')) return true

          return validPhone(val)
        },
        // We do allow card number matches here (following PEx's example)
      },
    },
  },

  mounted () {
    // Return current state unless asked for a re-validation
    EventBus.$on('ewallet.paypalValidation', this.handleValidation)
    EventBus.$on('ewallet.venmoValidation', this.handleValidation)
    EventBus.$on('ewallet.applePayValidation', this.handleValidation)
    EventBus.$on('ewallet.googlePayValidation', this.handleValidation)
  },

  beforeUnmount () {
    EventBus.$off('ewallet.paypalValidation', this.handleValidation)
    EventBus.$off('ewallet.venmoValidation', this.handleValidation)
    EventBus.$off('ewallet.applePayValidation', this.handleValidation)
    EventBus.$off('ewallet.googlePayValidation', this.handleValidation)
  },

  methods: {
    // Two different ways to handle validation. By parent request
    // (this.$ref.validate()) and upon request via event (see mounted hook).

    // When a parent asks for validation directly check both sources
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    // Respond to request by event
    handleValidation ({ touch }) {
      return touch ? this.validateAndEmit() : this.emitValidation()
    },

    emitValidation () {
      EventBus.$emit('ewallet.validation.contactInfo', !this.v$.$invalid)
    },

    validateAndEmit (field) {
      if (field) {
        this.v$.extraFieldsData?.[field]?.$touch()
      }
      else {
        this.v$.extraFieldsData.$touch()
      }
      this.emitValidation()
    },
    focusOnError () {
      if (this.v$.extraFieldsData.email.$error) {
        this.$refs.emailInputRef.focus()
      }
      else if (this.v$.extraFieldsData.phone.$error) {
        this.$refs.phoneInputRef.focus()
      }
    },

    shouldInclude (fieldName) {
      const field = this.extraFields[fieldName]
      return field && (!this.isSavedTender || field.showForSavedTenders) && (!this.tenderProvidesContactInfo || this.loggedIn)
    },

    shouldIncludeRequiredMessage () {
      return !this.tenderProvidesContactInfo && !this.loggedIn
    },

    shouldIncludeContactInput () {
      return (this.loggedIn || !this.tenderProvidesContactInfo) &&
             (this.shouldInclude('email') || this.shouldInclude('phone') || this.shouldIncludeRequiredMessage())
    },
    shouldShowSpellingWarning () {
      if (!this.extraFieldsData.email) return false
      // Simple regex to capture the second level/top level email domains.
      const emailDomainRegex = /@([A-Za-z0-9.-]+)\.([A-Za-z]+)/
      const match = this.extraFieldsData.email.toLowerCase().match(emailDomainRegex)
      if (!match) return false
      const commonSecondLevelDomains = [ 'aol', 'gmail', 'hotmail', 'yahoo']
      const commonTopLevelDomains = ['com', 'net', 'org' ]
      let shouldShow = false
      shouldShow = commonSecondLevelDomains.some(
        domain => {
          const score = levenshtein(match[1], domain)
          return score >= 1 && score <= 2
        },
      )
      // TLDs are smaller than second level domains, so their max score is 1.
      shouldShow = shouldShow || commonTopLevelDomains.some(
        domain => levenshtein(match[2], domain) === 1,
      )
      this.showEmailSpellingWarning = shouldShow
    },
    messagingLabel () {
      if (this.tenderProvidesContactInfo && !this.loggedIn) {
        const wallet = this.$t(this.selectedTenderFormType + '.friendly_name.default')
        if (this.extraFieldsData.contactPreference === 'sms') {
          return this.$t('messaging.select-sms-tooltip.wallet', { wallet })
        }
        else {
          return this.$t('messaging.select-email-tooltip.wallet', { wallet })
        }
      }
      else {
        return this.$t('messaging.mobile_required')
      }
    },

    asterisk (fieldName, str) {
      const field = this.extraFields[fieldName]
      if (field && field.required) str += ' *'
      return str
    },
  },
}
</script>
