<template>
  <div
    v-if="hasFee && isSelectionView"
    class="fee-notice"
  >
    <div v-if="hideFeeMessage">
      <slot name="termsWithoutFee">
        <strong
          class="text-body font-weight-bold"
        >
          <span v-dompurify-html="termsNotice" />

        </strong>
        {{ additionalCopy }}
      </slot>
    </div>
    <div v-else>
      <div
        v-if="shouldShowCheckbox"
        class="position-relative"
      >
        <b-form-checkbox
          ref="feeCheckbox"
          v-model="convenienceFeeAgreement"
          :state="!v$.convenienceFeeAgreement.$error"
          class="align-items-start"
          inline
          data-test="fee-wrapper"
          aria-describedby="convenience-fee-checkbox-validation-errors"
          @change="v$.convenienceFeeAgreement.$reset()"
          @blur="v$.convenienceFeeAgreement.$touch()"
        >
          <span
            data-test="fee-text-with-checkbox"
          >
            <slot name="textWithCheckbox">
              <strong
                class="text-body"
              >
                <span v-dompurify-html="convenienceFeeAgreementText" />
                <ConvenienceFeeModal
                  v-if="showModal"
                  :translated-fee-keys="translatedFeeKeys"
                  class="ml-1"
                />
              </strong>
              {{ additionalCopy }}
            </slot>
          </span>

          <ValidationErrors
            id="convenience-fee-checkbox-validation-errors"
            :validator="v$.convenienceFeeAgreement"
            :errors="$t('fee.agreement.required', {
              feeName,
            })"
          />
        </b-form-checkbox>
      </div>
      <div
        v-else
        data-test="fee-text-without-checkbox"
      >
        <slot name="textWithoutCheckbox">
          <strong
            class="text-body font-weight-bold"
          >
            <span v-dompurify-html="convenienceFeeNotice" />
            <ConvenienceFeeModal
              v-if="showModal"
              :translated-fee-keys="translatedFeeKeys"
              :use-cost-linked-pricing="useCostLinkedPricing"
              class="ml-1"
            />
          </strong>
          {{ additionalCopy }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { checked } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import ConvenienceFeeModal from './ConvenienceFeeModal.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { amountIsNonzero } from '@grantstreet/psc-js/utils/numbers.js'

export default {
  emits: ['input'],
  components: {
    ValidationErrors,
    ConvenienceFeeModal,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    value: {
      type: Boolean,
      // Really only required if shouldShowCheckbox === true
      default: false,
    },
    fee: {
      type: String,
      default: '',
    },
    translatedFeeKeys: {
      type: Array,
      default: () => ([]),
    },
    shouldShowCheckbox: {
      type: Boolean,
      default: false,
    },
    tenderType: {
      type: String,
      required: true,
    },
    formType: {
      type: [String, Number],
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    additionalCopy: {
      type: String,
      default: '',
    },
    hideFeeMessage: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    convenienceFeeAgreement: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },

    hasFee () {
      return amountIsNonzero(this.fee)
    },

    convenienceFeeAgreementText () {
      return this.$t('fee.agreement.default', {
        fee: this.fee,
        feeName: this.feeName,
        tenderType: this.$t(`${this.tenderType}.friendly_name.default`),
      })
    },

    convenienceFeeNotice () {
      // Venmo is weird. The tender type is PayPal while the form type is Venmo.
      // When displaying to the user, there might be confusion if we display
      // both to the user in the notice so we have to manually set it here.
      const tenderType = this.formType === 'venmo'
        ? this.$t('venmo.friendly_name.default')
        : this.$t(`${this.tenderType}.friendly_name.default`)

      return this.$t('fee.notice', {
        fee: this.fee,
        feeName: this.feeName,
        buttonText: this.buttonText,
        tenderType,
      })
    },

    termsNotice () {
      return this.$t('terms.agreement', {
        buttonText: this.buttonText,
      })
    },

    feeName () {
      return this.$t('fee.name')
    },
  },

  watch: {
    $route () {
      if (this.shouldShowCheckbox) {
        this.convenienceFeeAgreement = false
      }
    },
  },

  mounted () {
    // Return current state unless asked for a re-validation
    EventBus.$on('ewallet.paypalValidation', this.handleValidation)
    EventBus.$on('ewallet.venmoValidation', this.handleValidation)
    EventBus.$on('ewallet.applePayValidation', this.handleValidation)
    EventBus.$on('ewallet.googlePayValidation', this.handleValidation)
  },

  beforeUnmount () {
    EventBus.$off('ewallet.paypalValidation', this.handleValidation)
    EventBus.$off('ewallet.venmoValidation', this.handleValidation)
    EventBus.$off('ewallet.applePayValidation', this.handleValidation)
    EventBus.$off('ewallet.googlePayValidation', this.handleValidation)
  },

  validations: {
    convenienceFeeAgreement: {
      checked: function (field) {
        if (!this.shouldShowCheckbox || !this.hasFee || !this.isSelectionView) {
          return true
        }
        return checked(field)
      },
    },
  },

  methods: {
    // Two different ways to handle validation. By parent request
    // (this.$ref.validate()) and upon request via event (see mounted hook).

    // When a parent asks for validation directly check both local input and
    // children
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    // Respond to request by event
    handleValidation ({ touch }) {
      return touch ? this.validateAndEmit() : this.emitValidation()
    },

    validateAndEmit () {
      this.v$.$touch()
      this.emitValidation()
    },

    emitValidation () {
      EventBus.$emit('ewallet.validation.feeAgreement', !this.v$.convenienceFeeAgreement.$invalid)
    },

    focusOnError () {
      if (this.v$.convenienceFeeAgreement.checked.$error) {
        this.$refs.feeCheckbox.focus()
      }
    },
  },
}
</script>
<style lang="scss" scoped>

.fee-notice ::v-deep .form-check-label {
  margin-top: -0.125rem !important;
}

</style>
