/**
 * Note: This is the external API interface for E-wallet. That means that any
 * changes made to this file should keep our API consumers in mind. It's a good
 * idea to make changes as backwards-compatiable as possible.
 *
 * Any changes should also be reflected in the README documentation as well!
 */

import { z } from 'zod'

// Perl doesn't have native support for booleans, so typically they'll just
// pass in a 0 or a 1 value.
const PerlBoolean = z.union([z.boolean(), z.literal(0), z.literal(1)])

/**
 * Helper schema for external API methods.
 **/

const extraFields = ['email', 'phone', 'contactPreference'] as const
type ExtraFieldsType = typeof extraFields[number]
const languages = ['en', 'es'] as const
const paymentTenders = ['bank', 'card', 'paypal'] as const
type PaymentTender = typeof paymentTenders[number]
const paymentFormTypes = [...paymentTenders, 'google', 'apple', 'venmo'] as const

const billingAddressSchema = z.object({
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  country: z.string(),
})

const convenienceFeesSchema = z.object(
  paymentTenders.reduce((obj, key) => {
    obj[key] = z.string().nullish()
    return obj
  }, {}),
)

const defaultBillingAddressSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().nullish(),
  country: z.string().nullish(),
})

const extraFieldsObjectSchema = z.object({
  required: PerlBoolean,
  initialValue: z.string().nullish(),
  showForSavedTenders: PerlBoolean.nullish(),
  disableIfLoggedIn: PerlBoolean.nullish(),
})

type ExtraFieldsObject = z.infer<typeof extraFieldsObjectSchema>
type ExtraFields = {
  [key in ExtraFieldsType]?: ExtraFieldsObject;
}

const extraFieldsSchema = z.object(
  extraFields.reduce((obj, key) => {
    obj[key] = extraFieldsObjectSchema.nullish()
    return obj
  }, {}),
)

const languageObjectSchema = z.object(
  languages.reduce((obj, key) => {
    obj[key] = z.string().nullish()
    return obj
  }, {}),
)

const paypalConfigSchema = z.object({
  clientId: z.string(),
  createOrder: z.function(),
  createVaultSetupToken: z.function().nullish(),
  error: z.function().nullish(),
  merchantIds: z.array(z.string()),
  onApprove: z.function(),
  savePayPalTender: z.function().nullish(),
  pushConfirmationWithOrderId: z.function().nullish(),
  success: z.function().nullish(),
  useVenmo: PerlBoolean.nullish(),
})

const textOverridesSchema = z.object({
  'submit_button': languageObjectSchema.nullish(),
  'tender_manager.spm_disclosure.saving_tender_to_wallet': languageObjectSchema.nullish(),
  'tender_manager.spm_disclosure.saving_one_time_payment': languageObjectSchema.nullish(),
})

/**
 * External API interface for attach().
 * Anything below here should be validated in the attach() function.
 */

export const elementSelectorSchema = z.string()

export const eWalletArgsSchema = z.object({
  allowedMethods: z.array(z.enum(paymentTenders)).nullish(),
  alwaysGetWarehouseToken: PerlBoolean.nullish(),
  alwaysGetWarehouseTokenForBanks: PerlBoolean.nullish(),
  alwaysSaveTender: PerlBoolean.nullish(),
  beforeSubmit: z.function().nullish(),
  cartHasDelayedPayments: PerlBoolean.nullish(),
  convenienceFees: convenienceFeesSchema.nullish(),
  dataVaultToken: z.string().nullish(),
  defaultBillingAddress: defaultBillingAddressSchema.nullish(),
  disabledFields: z.array(z.enum(['address2', 'bankCategoryPersonal'])).nullish(),
  // See PSC-9164.
  environment: z.enum(['test', 'beta', 'stage', 'demo', 'prod']).nullish(),
  extraFields: extraFieldsSchema.nullish(),
  feeName: z.enum(['convenience', 'service', 'processing']).nullish(),
  getUpdatedFee: z.function().nullish(),
  initialTenderId: z.string().or(z.number()).nullish(),
  jwt: z.string(),
  language: z.enum(languages).nullish(),
  lastNameRequired: PerlBoolean.nullish(),
  maxReturnFee: z.string().nullish(),
  onFormTypeChange: z.function().nullish(),
  onSubmit: z.function().nullish(),
  onTypeChange: z.function().nullish(),
  onValidationError: z.function().nullish(),
  paypalConfig: paypalConfigSchema.nullish(),
  preExpandCardForm: PerlBoolean.nullish(),
  preFillAddressLabel: languageObjectSchema.nullish(),
  profile: z.object({ 'user_id': z.string() }).nullish(),
  refreshJwt: z.function().nullish(),
  showFeeCheckbox: PerlBoolean.nullish(),
  showOneTimeUseDisclosure: PerlBoolean.nullish(),
  textOverrides: textOverridesSchema.nullish(),
  view: z.enum(['selection', 'management']).nullish(),
})

/**
 * External API interface for bank tender object.
 * This should be validated in the following external methods:
 * -  beforeSubmit()
 * -  getUpdatedFee()
 * -  onSubmit()
 */

const bankTenderSchema = z.object({
  bankAccountCategory: z.string(),
  bankAccountType: z.string(),
  bankName: z.string().nullish(),
  bankRoutingNumber: z.string(),
  // needsBillingAddress could be disabled for bank tenders.
  billingAddress: billingAddressSchema.nullish(),
  lastDigits: z.string(),
  source: z.string(),
  // A tender won't be saved and grab a tender ID if:
  //   1. alwaysGetWarehouseTokenForBanks && alwaysSaveTender are both false
  //   2. useVaultToken is true
  tenderId: z.number().nullish(),
  type: z.literal('bank'),
  userName: z.string(),
})

/**
 * External API interface for card tender object.
 * This should be validated in the following external methods:
 * - beforeSubmit()
 * - getUpdatedFee()
 * - onSubmit()
 */

const cardTenderSchema = z.object({
  cardBrand: z.string(),
  cardExpirationMonth: z.string(),
  cardExpirationYear: z.string(),
  billingAddress: billingAddressSchema,
  lastDigits: z.string(),
  source: z.string(),
  // A tender won't be saved and grab a tender ID if:
  //   1. alwaysGetWarehouseToken && alwaysSaveTender are both false
  //   2. useVaultToken is true
  tenderId: z.number().nullish(),
  type: z.literal('card'),
  userName: z.string(),
})

/**
 * External API interface for PayPal tender object.
 * This should be validated in the following external methods:
 * - createOrder()
 */

const paypalTenderSchema = z.object({
  source: z.string(),
  type: z.literal('paypal'),
  userName: z.string().nullish(),
})

/**
 * External API interface for success ID in paypalConfig.
 * This should be validated in the following external methods:
 * - success()
 */

export const paypalSuccessSchema = z.string()

/**
 * External API interface for payment form types.
 * This should be validated in the following external methods:
 * - onFormTypeChange()
 */

export const onFormTypeChangeSchema = z.enum(paymentFormTypes)

/**
 * External API interface for payment tenders.
 * This should be validated in the following external methods:
 * - onTypeChange()
 */

export const onTypeChangeSchema = z.enum(paymentTenders)

/**
 * External API interface for form validation errors when submitting.
 * This should be validated in the following external methods:
 * - onValidationError()
 */

export const onValidationErrorSchema = z.array(z.string())

/**
 * A helper method to dynamically grab the tender schema for the external API interface.
 * This should be utilized in the following external methods:
 * - beforeSubmit()
 * - getUpdatedFee()
 * - onSubmit()
 * @type - The tender type. This is either `card`, `bank`, or `paypal`.
 */

export const getTenderSchema = (type : PaymentTender) => {
  let schema : z.ZodObject<z.ZodRawShape>
  if (type === 'bank') {
    schema = bankTenderSchema
  }
  else if (type === 'card') {
    schema = cardTenderSchema
  }
  else if (type === 'paypal') {
    schema = paypalTenderSchema
  }
  else {
    throw new Error(`Unknown payment type: ${type}`)
  }
  return schema
}

/**
 * A helper method to dynamically generate the extra fields schema for the
 * external API interface. This should be utilized in the following
 * external methods:
 * - beforeSubmit()
 * - onSubmit()
 * @param extraFieldsObject - An object that contains extra fields
 * ('email', 'phone', 'contactPreference') as keys and their data as values.
 * This is the extraFields object that's passed as a E-Wallet prop.
 */
export const generateExtraFieldsSchema = (extraFieldsObject : ExtraFields) => {
  /**
  * Filters out which extra fields a user must fill out vs. can optionally fill
  * out (e.g., a user must input their email but can optionally input their phone).
  */
  const requiredExtraFields = Object.keys(extraFieldsObject).filter(
    key => extraFieldsObject[key].required,
  )
  return z.object(extraFields.reduce((obj, type) => {
    obj[type] = requiredExtraFields.includes(type) ? z.string() : z.string().nullish()
    return obj
  }, {}))
}
