import Vue from 'vue'

// This is a follows the API store pattern that GH uses. This store should be a
// stand-in for when the parent app hasn't included an API module already
export default {
  namespaced: true,

  state: {
    requestService: null,
  },

  getters: {
    ewallet: (state) => {
      return state.ewallet
    },
    requestService: (state) => {
      return state.requestService
    },
  },

  mutations: {
    setEWalletApi (state, api) {
      api.app = 'e-wallet-ui'
      Vue.set(state, 'ewallet', api)
    },
    setRequestApi (state, api) {
      api.app = 'e-wallet-ui'
      Vue.set(state, 'requestService', api)
    },
  },
}
