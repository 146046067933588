<template>
  <!-- TODO: remove this file - it duplicates ContactInfo and I believe is unused -->
  <div
    :class="{ 'form-inline d-flex row show info-form': inline }"
    class="extra-fields"
  >
    <!-- Email -->
    <b-form-group
      v-if="shouldInclude('email')"
      :label="emailLabel"
      :label-for="'email-' + _uid"
      :label-cols-md="!inline ? 3 : false"
      :class="{ 'flex-fill mt-0 col-12 mt-sm-2 col-md-6 col-lg-4 col-xl-3': inline }"
    >
      <b-form-input
        :id="'email-' + _uid"
        v-model="extraFieldsData.email"
        :state="!v$.extraFieldsData.email.$error"
        :class="{ 'w-100': inline }"
        name="email"
        type="email"
        autocomplete="email"
        @input="v$.extraFieldsData.email.$reset()"
        @blur="v$.extraFieldsData.email.$touch();"
      />
      <ValidationErrors
        :validator="v$.extraFieldsData.email"
        :errors="{
          required: $t('extra_fields.email.required'),
          email: $t('extra_fields.email.invalid'),
          maxLength: $t('field_max_length', { len: 100 }),
          nonCard: $t('card.prevent.wrong.input.error'),
        }"
      />
    </b-form-group>

    <!-- Phone -->
    <b-form-group
      v-if="shouldInclude('phone')"
      :label="phoneLabel"
      :label-for="'phone-number-' + _uid"
      :label-cols-md="!inline ? 3 : false"
      :class="{ 'flex-fill mt-0 col-12 mt-sm-2 col-md-6 col-lg-4 col-xl-3': inline }"
    >
      <b-form-input
        :id="'phone-number-' + _uid"
        v-model="extraFieldsData.phone"
        :state="!v$.extraFieldsData.phone.$error"
        :class="{ 'w-100': inline }"
        name="phone"
        type="tel"
        autocomplete="tel"
        @input="v$.extraFieldsData.phone.$reset()"
        @blur="handlePhoneBlur"
      />
      <ValidationErrors
        :validator="v$.extraFieldsData.phone"
        :errors="{
          required: $t('extra_fields.phone.required'),
          maxLength: $t('field_max_length', { len: 30 }),
          validPhone: $t('extra_fields.phone.invalid'),
        }"
      />
    </b-form-group>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, maxLength, helpers } from '@vuelidate/validators'
import { nonCard, validPhone } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'

// Returns true if the passed extra field is displayed in this context.
function isExtraFieldDisplayed (vm, fieldName) {
  const field = vm.extraFields[fieldName]
  if (!field) return false
  if (vm.isSavedTender && !field.showForSavedTenders) return false
  return true
}

function requireExtraField (fieldName) {
  return helpers.withParams({ type: 'requireField', fieldName }, function (val) {
    const field = this.extraFields[fieldName]
    if (!isExtraFieldDisplayed(this, fieldName)) return true
    if (!field.required) return true
    return required.$validator(val)
  })
}

export default {
  components: { ValidationErrors },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    extraFields: {
      type: Object,
      required: true,
    },
    extraFieldsData: {
      type: Object,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    isSavedTender: {
      type: Boolean,
      default: false,
    },
    checkMistakenCardNumber: {
      type: Function,
      default: () => {},
    },
    validationInitiator: {
      type: String,
      default: null,
    },
  },

  computed: {
    emailLabel () {
      return this.asterisk('email', this.$t('extra_fields.email.label'))
    },

    phoneLabel () {
      return this.asterisk('phone', this.$t('extra_fields.phone.label'))
    },
  },

  validations: {
    extraFieldsData: {
      // The extra checks are because we only want to validate the fields if the
      // parent app specified they want to include them.
      email: {
        required: requireExtraField('email'),
        email: function (val) {
          const field = this.extraFields.email
          if (!field) return true
          return email.$validator(val)
        },
        maxLength: maxLength(100),
        nonCard,
      },

      phone: {
        required: requireExtraField('phone'),
        maxLength: maxLength(30),

        validPhone: function (val) {
          if (!val) return true
          if (!isExtraFieldDisplayed(this, 'phone')) return true

          return validPhone(val)
        },
        // We do allow card number matches here (following PEx's example)
      },
    },
  },

  methods: {
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    shouldInclude (fieldName) {
      const field = this.extraFields[fieldName]
      return field && (!this.isSavedTender || field.showForSavedTenders)
    },

    asterisk (fieldName, str) {
      const field = this.extraFields[fieldName]
      if (field && field.required) str += ' *'
      return str
    },

    handlePhoneBlur () {
      this.v$.extraFieldsData.phone.$touch()
      this.checkMistakenCardNumber(
        this.extraFieldsData.phone,
        () => {
          this.extraFieldsData.phone = ''
          this.v$.extraFieldsData.phone.$reset()
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.form-inline ::v-deep {
  .form-group > * {
    width: 100%;
  }

  label {
    display: block !important;
  }
}
</style>
