<template>
  <div class="billing-address-info">
    <b-form-group
      :label="(billing ? $t('address.label') : $t('delivery.address_label')) + ' *'"
      :label-for="'address-' + _uid"
      :label-cols-md="billing || useWideLabel ? 3 : 2"
      class="align-items-start"
    >
      <b-form-checkbox
        v-if="preFillAddressLabel &&
          preFillAddressLabel[$i18n.locale] &&
          defaultAddressExists"
        :checked="isAddressPrefilled"
        class="align-items-start mb-3 mt-md-3 mt-1"
        inline
        @change="changePreFillCheckbox"
      >
        {{ preFillAddressLabel[$i18n.locale] }}
      </b-form-checkbox>

      <b-form-input
        :id="'address-' + _uid"
        ref="address1"
        v-model="billingData.address1"
        :state="!v$.billingData.address1.$error"
        :placeholder="$t('address.street.label')"
        name="address1"
        autocomplete="address-line1"
        :aria-describedby="'address1-validation-errors-' + _uid"
        @blur="handleField('address1')"
      />
      <ValidationErrors
        :id="'address1-validation-errors-' + _uid"
        :validator="v$.billingData.address1"
        :errors="{
          required: $t('address.required'),
          maxLength: $t('field_max_length', { len: 49 }),
          nonCard: $t('card.prevent.wrong.input.error'),
          notOnlyDigits: $t('validation.cannot_contain_only_digits'),
        }"
      />
    </b-form-group>

    <!-- address2 can be removed via configuration -->
    <b-form-group
      v-if="!disabledFields.address2"
      :label-cols-md="billing || useWideLabel ? 3 : 2"
      label-class="pb-0"
    >
      <b-form-input
        :id="'address-line-2-' + _uid"
        ref="address2"
        v-model="billingData.address2"
        :aria-label="'address-line-2'"
        :state="!v$.billingData.address2.$error"
        :placeholder="$t('address.secondary.label')"
        name="address2"
        autocomplete="address-line2"
        :aria-describedby="'address2-validation-errors-' + _uid"
        @blur="handleField('address2')"
      />
      <ValidationErrors
        :id="'address2-validation-errors-' + _uid"
        :validator="v$.billingData.address2"
        :errors="{
          maxLength: $t('field_max_length', { len: 49 }),
          nonCard: $t('card.prevent.wrong.input.error'),
          notOnlyDigits: $t('validation.cannot_contain_only_digits'),
        }"
      />
    </b-form-group>

    <b-form-row class="mb-2 required">
      <b-col
        :cols="billing || useWideLabel ? 3 : 2"
        class="d-none d-md-block"
      />
      <b-col md="5">
        <b-form-input
          :id="'city-' + _uid"
          ref="city"
          v-model="billingData.city"
          :aria-label="'city'"
          :state="!v$.billingData.city.$error"
          :placeholder="$t('address.city.label')"
          name="city"
          autocomplete="address-level2"
          :aria-describedby="'city-validation-errors-' + _uid"
          @blur="handleField('city')"
        />
        <ValidationErrors
          :id="'city-validation-errors-' + _uid"
          :validator="v$.billingData.city"
          :errors="{
            required: $t('address.city.required'),
            maxLength: $t('field_max_length', { len: 50 }),
            nonCard: $t('card.prevent.wrong.input.error'),
          }"
        />
      </b-col>
      <b-col
        :md="billing || useWideLabel ? 4 : 5"
        class="mt-2 mt-md-0"
      >
        <template v-if="billingData.country === 'US'">
          <b-form-select
            :id="'state-' + _uid"
            ref="state"
            v-model="billingData.state"
            :aria-label="'state'"
            :state="!v$.billingData.state.$error"
            :options="stateCodes"
            :select-size="1"
            name="state"
            autocomplete="address-level1"
            value-field="code"
            text-field="name"
            :aria-describedby="'state-validation-errors-' + _uid"
            @change="v$.billingData.state.$reset()"
            @blur="validateAndEmit('state')"
          />
        </template>
        <template v-else>
          <b-form-input
            :id="'state-' + _uid"
            ref="state"
            v-model="billingData.state"
            :aria-label="'state'"
            :state="!v$.billingData.state.$error"
            :placeholder="$t('address.state.label')"
            name="state"
            autocomplete="address-level1"
            :aria-describedby="'state-validation-errors-' + _uid"
            @blur="handleField('state')"
          />
        </template>
        <ValidationErrors
          :id="'state-validation-errors-' + _uid"
          :validator="v$.billingData.state"
          :errors="{
            required: $t('address.state.required'),
            maxLength: $t('field_max_length', { len: 20 }),
            nonCard: $t('card.prevent.wrong.input.error'),
          }"
        />
      </b-col>
    </b-form-row>

    <b-form-row class="mb-2">
      <b-col
        :cols="billing || useWideLabel ? 3 : 2"
        class="d-none d-md-block"
      />
      <b-col
        cols="12"
        :md="billing || useWideLabel ? 3 : 4"
      >
        <b-form-input
          :id="'postal-code-' + _uid"
          ref="postalCode"
          v-model="billingData.postalCode"
          :aria-label="'postal-code'"
          :state="!v$.billingData.postalCode.$error"
          :placeholder="$t('address.postal_code.label')"
          name="postal-code"
          :type="billingData.country !== 'US' ? 'text': 'tel'"
          autocomplete="postal-code"
          :aria-describedby="'postal-code-validation-errors-' + _uid"
          @blur="handleField('postalCode')"
        />
        <ValidationErrors
          :id="'postal-code-validation-errors-' + _uid"
          :validator="v$.billingData.postalCode"
          :errors="{
            required: $t('address.postal_code.required'),
            maxLength: $t('field_max_length', { len: 10 }),
            validPostalCode: postalCodeFormatMessage,
            nonCard: $t('card.prevent.wrong.input.error'),
          }"
        />
      </b-col>
      <b-col
        :id="'country-col-' + _uid"
        cols="12"
        md="6"
        class="mt-2 mt-md-0"
      >
        <b-form-select
          :id="'country-' + _uid"
          ref="country"
          v-model="billingData.country"
          :aria-label="'country'"
          :state="!v$.billingData.country.$error"
          :options="allowedCountryCodes"
          :select-size="1"
          :disabled="!allowInternational"
          name="country"
          autocomplete="country"
          value-field="code"
          text-field="name"
          :aria-describedby="'country-validation-errors-' + _uid"
          @blur="() => {
            v$.billingData.country.$touch()

            // Changing the country may have
            // A. Cleared and .touch()ed the state
            // B. Guessed the state from the postal code and .touch()ed the
            // state

            // Postal code may not be valid still
            validateAndEmit('postalCode')
          }"
        />
        <ValidationErrors
          :id="'country-validation-errors-' + _uid"
          :validator="v$.billingData.country"
          :errors="{
            required: $t('address.country.required'),
            validCountry: $t('address.country.invalid'),
          }"
        />
        <b-tooltip
          v-if="!allowInternational && noInternationalExplanation"
          :target="'country-col-' + _uid"
          placement="top"
          triggers="hover"
          :title="noInternationalExplanation"
        />
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, maxLength } from '@vuelidate/validators'
import { nonCard, notOnlyDigitsMoreThan } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '../components/ValidationErrors.vue'
import countryCodes, { countryCodesWithoutPostalCodes } from '../utils/country-codes.js'
import stateCodes from '../utils/state-codes.js'
import postalCodeRegexes from 'zipcodes-regex'
import EventBus from '../utils/event-bus.ts'
import { getUsStateFromZip } from '@grantstreet/psc-js/utils/addresses.js'

export default {
  components: { ValidationErrors },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    allowInternational: {
      type: Boolean,
      default: true,
    },
    // TODO: Stop mutating props. If we fixed this then billingData would be
    // much more stable and easy to deal with. We could probably get rid of this
    // component's watchers
    billingData: {
      type: Object,
      required: true,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    noInternationalExplanation: {
      type: String,
      default: '',
    },
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
    billing: {
      type: Boolean,
      default: true,
    },
    validationInitiator: {
      type: String,
      default: null,
    },
    useWideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      stateCodes: [
        { name: this.$t('address.state.select'), code: null, disabled: true },
        ...stateCodes,
      ],
      // Have we sent a Google Analytics event for the user modifying the
      // default billing address?
      sentChangeGaEvent: false,
    }
  },

  computed: {
    allowedCountryCodes () {
      return this.allowInternational
        ? Object.values(countryCodes)
        : [ countryCodes.US ]
    },

    // Does the current address state match the pre-filled default address?
    isAddressPrefilled () {
      const current = this.billingData
      const original = this.defaultBillingAddress
      const addressesAreEqual = current.address1 === original.address1 &&
        current.city === original.city &&
        current.state === original.state &&
        current.postalCode === original.postalCode &&
        current.country === original.country &&
        (
          this.disabledFields.address2 ||
          current.address2 === original.address2
        )

      if (
        !addressesAreEqual &&
        this.defaultAddressExists &&
        !this.sentChangeGaEvent
      ) {
        /* eslint-disable-next-line vue/no-side-effects-in-computed-properties */
        this.sentChangeGaEvent = true

        // There was a default billing address and the user changed it or
        // unchecked the pre-fill checkbox
        this.$gtag.event('Modified Pre-filled Address', { 'event_category': 'E-Wallet' })
      }

      return addressesAreEqual
    },

    defaultAddressExists () {
      const address = this.defaultBillingAddress || {}
      return address.address1 ||
        address.address2 ||
        address.city ||
        address.state ||
        address.postalCode
    },

    postalCodeFormatMessage () {
      // I don't thiiiink .country can not be a valid code, but lets be safe
      const { name: country, code, format } = countryCodes[this.billingData.country] || {}

      return (format && country)
        ? this.$t('address.postal_code.formatting', { country, format })
        : this.$t('address.postal_code.invalid', { countryCode: code })
    },
  },

  watch: {
    // Since we mutate the billingData prop we should probably keep these as
    // watchers for the time being. We want to fire these on each change and
    // can't guarantee that the parent won't mutate things, so we probably need
    // watchers for now.
    'billingData.country': function (newCountry) {
      this.maybeClearState(newCountry)
      this.guessUsState({ ...this.billingData, country: newCountry })
    },

    'billingData.postalCode': function (newCode) {
      this.guessUsState({ ...this.billingData, postalCode: newCode })
    },

    // If the default address changes (e.g., because of a cart change), set the
    // address to the new default.
    defaultBillingAddress (newVal) {
      this.billingData.address1 = newVal.address1
      this.billingData.address2 = newVal.address2
      this.billingData.city = newVal.city
      this.billingData.country = newVal.country || 'US'
      this.billingData.state = newVal.state || null
      this.billingData.postalCode = newVal.postalCode
    },
  },

  validations: {
    billingData: {
      // PEx only allows 100 chars for the combined address. We join the two
      // addresses together with a newline before sending to PEx, hence the 49.
      address1: {
        required,
        maxLength: maxLength(49),
        nonCard,
        notOnlyDigits: notOnlyDigitsMoreThan(5),
      },
      address2: {
        maxLength: maxLength(49),
        nonCard,
        notOnlyDigits: notOnlyDigitsMoreThan(5),
      },
      city: {
        required,
        maxLength: maxLength(50),
        nonCard,
      },
      state: {
        required,
        // PEx requirement
        maxLength: maxLength(20),
        nonCard,
      },
      postalCode: {
        required: requiredIf(function () {
          return !countryCodesWithoutPostalCodes.includes(this.billingData.country)
        }),
        maxLength: maxLength(10),
        validPostalCode (value) {
          // required, will catch this
          if (!value) {
            return true
          }
          value = value.trim()

          const regex = postalCodeRegexes[this.billingData.country || 'US']
          if (regex) {
            return new RegExp(regex, 'i').test(value)
          }

          // If we don't know how to validate this country, assume it's valid
          return true
        },
        nonCard,
      },
      country: {
        required,
        validCountry (value) {
          // required, will catch this
          if (!value) {
            return true
          }

          // Must return boolean
          // Don't need to use allowedCountryCodes since there are do dupe keys
          return Boolean(countryCodes[value])
        },
      },
    },
  },

  mounted () {
    // Return current state unless asked for a re-validation
    if (this.validationInitiator) {
      EventBus.$on(`ewallet.${this.validationInitiator}Validation`, this.handleValidation)
    }
  },

  beforeUnmount () {
    if (this.validationInitiator) {
      EventBus.$off(`ewallet.${this.validationInitiator}Validation`, this.handleValidation)
    }
  },

  methods: {

    // Two different ways to handle validation. By parent request
    // (this.$ref.validate()) and upon request via event (see mounted hook).

    handleField (field) {
      if (this.billingData[field]) {
        this.billingData[field] = this.billingData[field].trim()
      }
      this.validateAndEmit(field)
    },

    // When a parent asks for validation directly check both local input and
    // children
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },

    // Respond to request by event
    handleValidation ({ touch }) {
      return touch ? this.validateAndEmit() : this.emitValidation()
    },

    emitValidation () {
      EventBus.$emit('ewallet.validation.billingData', !this.v$.$invalid)
    },

    validateAndEmit (field) {
      if (field) {
        this.v$.billingData?.[field]?.$touch()
      }
      else {
        this.v$.billingData.$touch()
      }
      this.emitValidation()
    },
    focusOnError () {
      for (const key in this.v$.billingData) {
        if (this.v$.billingData[key].$error) {
          this.$refs[key].focus()
          return
        }
      }
    },

    maybeClearState (country) {
      if (country !== 'US') {
        this.billingData.state = null
        this.v$.billingData.state.$reset()
      }
    },

    // Guess the US state
    guessUsState ({ postalCode, country } = this.billingData) {
      if (country === 'US') {
        const state = getUsStateFromZip(postalCode)
        if (state) {
          this.billingData.state = state
          this.v$.billingData.state.$touch()
        }
      }
    },

    // Fired on "pre-fill?" checkbox change. newVal is the new boolean value.
    changePreFillCheckbox (newVal) {
      if (newVal) {
        this.billingData.address1 = this.defaultBillingAddress.address1
        this.billingData.city = this.defaultBillingAddress.city
        this.billingData.state = this.defaultBillingAddress.state
        this.billingData.postalCode = this.defaultBillingAddress.postalCode
        this.billingData.country = this.defaultBillingAddress.country
        if (!this.disabledFields.address2) {
          this.billingData.address2 = this.defaultBillingAddress.address2
        }
      }
      else {
        this.billingData.address1 = ''
        this.billingData.city = ''
        this.billingData.state = null
        this.billingData.postalCode = ''
        this.billingData.country = 'US'
        if (!this.disabledFields.address2) {
          this.billingData.address2 = ''
        }

        this.v$.billingData.$reset()
      }
    },

  },
}
</script>
