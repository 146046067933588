import Vue, { createApp, reactive, h as createElement } from 'vue'
import { createStore } from 'vuex'
import { createVueWait } from 'vue-wait'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import installEWallet, { initialize as initializeEWallet } from '@grantstreet/e-wallet-vue'
import {
  sentryException,
  chainClient,
  vueErrorHandler,
} from '@grantstreet/e-wallet-vue/sentry.js'
import jwtDecode from 'jwt-decode'
import { eWalletServiceEnv } from '@grantstreet/e-wallet-vue/src/utils.js'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import api from '@grantstreet/e-wallet-vue/src/store/api.js'
import RequestApi from '@grantstreet/request-api'
import { initWidgetEnvWithFallback } from '@grantstreet/psc-environment/environment.js'
import EWalletComponent from '@grantstreet/e-wallet-vue/src/components/eWallet.vue'
import VueGtag from 'vue-gtag'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'
import {
  eWalletArgsSchema,
  elementSelectorSchema,
  onFormTypeChangeSchema,
  onTypeChangeSchema,
  onValidationErrorSchema,
} from '@grantstreet/e-wallet-vue/src/external-api-spec.ts'
import { withValidator } from '@grantstreet/schema-validation'

// CSS is loaded via the theme files in ./themes

Vue.use(VueSVGIcon)

// See README for `attachParams` documentation.
const attachFunction = (elSelector, attachParams = {}) => {
  // Construct the props object to be passed to the E-Wallet root component.
  // This destructures out any params that should not be passed through.
  // Eventually we should refactor this to instead explicitly construct the list
  // of supported props (currently someone could pass an E-Wallet prop that
  // isn't explicitly documented in the README interface).
  let {
    onSubmit,
    onTypeChange,
    onFormTypeChange,
    onValidationError,
    jwt,
    environment,
    refreshJwt,
    profile,
    language,
    ...props
  } = attachParams

  if (!jwt) {
    throw new Error('E-Wallet Error: You must pass a \'jwt\'')
  }

  let decodedJwt
  try {
    decodedJwt = jwtDecode(jwt)
  }
  catch (error) {
    throw new Error('E-Wallet Error: You must pass a valid \'jwt\'')
  }

  chainClient(client => client.setContext('jwt.origin', decodedJwt.origin))

  try {
    initWidgetEnvWithFallback({
      explicitEnvironment: environment,
      legacyEnvironmentOverride: eWalletServiceEnv(decodedJwt.origin),
      diagApp: 'e-wallet',
      RequestApi,
      exceptionLogger: sentryException,
    })
  }
  catch (rawError) {
    const error = new Error(`E-Wallet Error: ${rawError.message}`)
    sentryException(error)
    throw error
  }

  const store = createStore()

  // Mimic the payhub API pattern
  store.registerModule('API', api)

  // Install the module itself. (Store, bootstrap, etc)
  const ok = installEWallet(Vue, {
    store,
    initializeModule: () => initializeEWallet(store, { jwt, refreshJwt }),
  })
  if (!ok) {
    return
  }

  if (profile) {
    store.commit('eWallet/setProfile', profile, { root: true })
  }

  if (language) {
    i18n.global.locale.value = language
  }

  // Hack `props` to be reactive so that updateConvenienceFees can update the
  // convenienceFees property on it and have E-Wallet react
  //
  // TODO: We should try to get rid of this spooky mutation at our next
  // plausible opportunity (probably a large refactor).
  props = reactive(props)

  let onFormTypeChangeCallback = onFormTypeChange
  let onTypeChangeCallback = onTypeChange
  let onValidationErrorCallback = onValidationError

  if (onFormTypeChange) {
    onFormTypeChangeCallback = withValidator(
      onFormTypeChange,
      true,
      [onFormTypeChangeSchema],
      false,
      sentryException,
    )
  }

  if (onTypeChange) {
    onTypeChangeCallback = withValidator(
      onTypeChange,
      true,
      [onTypeChangeSchema],
      false,
      sentryException,
    )
  }

  if (onValidationError) {
    onValidationErrorCallback = withValidator(
      onValidationError,
      true,
      [onValidationErrorSchema],
      false,
      sentryException,
    )
  }

  // This handler is named onSubmit in E-Wallet's external attach interface, but
  // internally it is named submitCallback. This changed internally during the
  // Vue 3 migration, since Vue 3 auto-converts any props that start with "on"
  // to event handlers (whereas we need to pass submitCallback as a
  // function-type prop so it can be awaited inside E-Wallet).
  props.submitCallback = onSubmit

  props.onValidationError = onValidationError ? onValidationErrorCallback : null

  if (props.view !== 'management') {
    props.onTenderTypeChanged = onTypeChange ? onTypeChangeCallback : null
    props.onTenderFormTypeChanged = onFormTypeChange ? onFormTypeChangeCallback : null
  }

  const vueInstance = createApp({
    render () {
      return createElement(EWalletComponent, props)
    },
    methods: {
      reenableSubmission () {
        console.error('Method reenableSubmission is depreciated. Use asynchronous function for onSubmit.')
        sentryException('Depreciated method reenableSubmission called.')
        this.$wait.end('submitting')
      },
      updateConvenienceFees (fees) {
        // This depends on the props being made reactive and spooky mutation
        // (see above)
        props.convenienceFees = fees
      },
    },
  })
    .use(store)
    .use(i18n)
    .use(createVueWait({ useVuex: true }))
    .use(VueGtag, {
      config: { id: getPayHubGaId() },
      // Don't report anything in sandboxes
      disableScriptLoad: process.env.NODE_ENV === 'development',
    })
    .use(vueErrorHandler)
    .mount(elSelector)

  return {
    reenableSubmission: vueInstance.reenableSubmission,
    updateConvenienceFees: vueInstance.updateConvenienceFees,
  }
}

export const attach = withValidator(
  attachFunction,
  true,
  [elementSelectorSchema, eWalletArgsSchema],
  true,
  sentryException,
)
