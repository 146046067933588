<template>
  <div
    class="position-relative"
    :class="{
      invalid: !allFieldsValid || disabled,
    }"
  >
    <div>
      <div
        :id="'paypal-button-' + _uid"
        class="my-2 w-100"
        :class="{ invalid: !allFieldsValid || disabled}"
        data-test="paypal-button"
      />
    </div>
    <div
      v-if="!allFieldsValid || disabled"
      :id="'paypal-validator' + _uid"
      class="paypal-validator position-absolute fixed-top w-100 h-100"
      @mouseenter="onMouseenter"
    />
    <b-tooltip
      v-if="!allFieldsValid"
      :target="'paypal-validator' + _uid"
      placement="left"
      triggers="hover focus"
      :title="$t('third_party_payment.error.fields_required')"
    />
  </div>
</template>

<script>
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'

export default {
  emits: ['paypal-error'],
  props: {
    client: {
      type: String,
      required: true,
    },
    merchantIds: {
      type: Array,
      required: true,
    },
    createOrder: {
      type: Function,
      required: true,
    },
    createVaultSetupToken: {
      type: Function,
      required: true,
    },
    saveTender: {
      type: Function,
      required: true,
    },
    approve: {
      type: Function,
      required: true,
    },
    success: {
      type: Function,
      default: async () => {},
    },
    pushConfirmationWithOrderId: {
      type: Function,
      required: true,
    },
    error: {
      type: Function,
      default: async () => {},
    },
    shouldIncludeContact: {
      type: Boolean,
      required: true,
    },
    shouldShowCheckbox: {
      type: Boolean,
      required: true,
    },
    isVenmo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      required: true,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    const sources = [
      'contactInfo',
      'feeAgreement',
    ]

    return {
      // Init for reactivity
      isValid: Object.fromEntries(sources.map(source => [source, false])),

      // These don't need reactivity but it's good practice to list things like
      // this
      validationHandlers: Object.fromEntries(
        sources.map(source => [source, valid => {
          this.isValid[source] = valid
        }]),
      ),
    }
  },

  computed: {
    allFieldsValid () {
      return (this.isValid.contactInfo || !this.shouldIncludeContact || (!this.loggedIn && !this.cartHasDelayedPayments)) &&
        (this.isValid.feeAgreement || !this.shouldShowCheckbox)
    },
  },

  mounted () {
    this.loadScript()
    // Sets up EventBus listeners
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$on(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )

    // Request initial validation state
    this.getFieldValidation()
  },

  beforeUnmount () {
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$off(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )
  },

  // vue-router navigation deactivates/reactivates components, so we need
  // to reload the PayPal button whenever that happens, too. (The button
  // isn't part of the vdom, so it would disappear otherwise.)
  activated () {
    this.showButton()
  },

  methods: {

    loadScript () {
      let el = document.querySelector('#paypal-sdk')
      // If the sdk is already requested then don't duplicate the script tag
      if (el) {
        // If it's already loaded then just use it
        if (window.paypal) {
          this.showButton()
          return
        }
        // Otherwise respectfully wait
        el.addEventListener('load', this.showButton)
        return
      }

      el = document.createElement('script')

      if (this.merchantIds.length > 1) {
        const uniqueMerchantIds = [...new Set(this.merchantIds)]

        el.src = `https://www.paypal.com/sdk/js?merchant-id=*&client-id=${this.client}`
        el.setAttribute('data-merchant-id', uniqueMerchantIds.join(','))
      }
      else {
        el.src = `https://www.paypal.com/sdk/js?merchant-id=${this.merchantIds[0]}&client-id=${this.client}`
      }

      // Add ability to enable venmo
      el.src += '&enable-funding=venmo'
      el.setAttribute('data-csp-nonce', document.querySelector('#nonce')?.value)
      // TODO: Why two loads?
      el.addEventListener('load', () => {
        el.setAttribute('data-loaded', 'true')
      })
      el.addEventListener('load', this.showButton)
      el.addEventListener('error', this.errorHandler)
      el.id = 'paypal-sdk'
      document.head.appendChild(el)
    },

    async showButton () {
      try {
        if (this.cartHasDelayedPayments && this.isVenmo) {
          throw new Error('Venmo Button loaded with delayed dayments')
        }

        // Render the PayPal button depending on if
        // the cart has delayed payments
        if (this.cartHasDelayedPayments) {
          // The PayPal button for delayed PayPal payments, currently only
          // PayPal is supported, not Venmo.
          const delayedPaymentButton = await window.paypal.Buttons({
            style: {
              height: 46,
            },

            createVaultSetupToken: async (data, actions) => {
              try {
                return await this.createVaultSetupToken()
              }
              catch (error) {
                this.errorHandler(error)
              }
            },

            onApprove: async ({ vaultSetupToken }) => {
              try {
                this.$store.dispatch('wait/start', 'inPaypal', { root: true })
                await this.saveTender(vaultSetupToken)
              }
              catch (error) {
                this.errorHandler(error)
              }
              finally {
                this.$store.dispatch('wait/end', 'inPaypal', { root: true })
              }
            },

            onError: this.errorHandler,
          })

          if (!delayedPaymentButton.isEligible()) {
            return
          }
          delayedPaymentButton.render('#paypal-button-' + this._uid)
        }
        else if (this.useCostLinkedPricing) {
          // The PayPal button for immediate PayPal payments
          const immediatePaymentButton = await window.paypal.Buttons({
            style: {
              height: 46,
            },

            // I think this is the only one we'll ever want to use. If we
            // do need to support multiple buttons, we'll need to rethink
            // the UI, too.
            fundingSource: !this.isVenmo ? window.paypal.FUNDING.PAYPAL : window.paypal.FUNDING.VENMO,

            // The @createOrder handler MUST resolve an order id or reject.
            createOrder: async (data, actions) => {
              // PayPal opens a popup and then calls this when the user clicks
              // their button. I don't think we can interfere in this case but
              // beware that Safari automatically blocks popups if they're not
              // *directly* triggered by user interaction. This means anything
              // that takes longer than 1s to pop up (tested). If we're ever
              // making changes that would potentially cause an `await` between
              // button click and opening the popup those can break payments in
              // Safari. GooglePayButton has had this problem.

              try {
                return await this.createOrder(data, actions)
              }
              catch (error) {
                this.errorHandler(error)
              }
            },

            onApprove: async (data, actions) => {
              try {
                this.$store.dispatch('wait/start', 'inPaypal', { root: true })
                this.pushConfirmationWithOrderId(data, actions)
              }
              catch (error) {
                this.errorHandler(error)
              }
              finally {
                this.$store.dispatch('wait/end', 'inPaypal', { root: true })
              }
            },

            onError: this.errorHandler,
          })

          if (!immediatePaymentButton.isEligible()) {
            return
          }
          immediatePaymentButton.render('#paypal-button-' + this._uid)
        }
        else {
          // The PayPal button for immediate PayPal payments
          const immediatePaymentButton = await window.paypal.Buttons({
            style: {
              height: 46,
            },

            // I think this is the only one we'll ever want to use. If we
            // do need to support multiple buttons, we'll need to rethink
            // the UI, too.
            fundingSource: !this.isVenmo ? window.paypal.FUNDING.PAYPAL : window.paypal.FUNDING.VENMO,

            // The @createOrder handler MUST resolve an order id or reject.
            createOrder: async (data, actions) => {
              // PayPal opens a popup and then calls this when the user clicks
              // their button. I don't think we can interfere in this case but
              // beware that Safari automatically blocks popups if they're not
              // *directly* triggered by user interaction. This means anything
              // that takes longer than 1s to pop up (tested). If we're ever
              // making changes that would potentially cause an `await` between
              // button click and opening the popup those can break payments in
              // Safari. GooglePayButton has had this problem.

              try {
                return await this.createOrder(data, actions)
              }
              catch (error) {
                this.errorHandler(error)
              }
            },

            // The @approve handler MUST resolve a receipt id or reject.
            onApprove: async (data, actions) => {
              let id
              try {
                this.$store.dispatch('wait/start', 'inPaypal', { root: true })
                id = await this.approve(data, actions)
                await this.success?.(id)
              }
              catch (error) {
                this.errorHandler(error)
              }
              finally {
                this.$store.dispatch('wait/end', 'inPaypal', { root: true })
              }
              return id
            },

            onError: this.errorHandler,
          })

          if (!immediatePaymentButton.isEligible()) {
            return
          }
          immediatePaymentButton.render('#paypal-button-' + this._uid)
        }
      }
      // Using try / catch here in addition to the onError callback in the
      // paypal.Buttons method because onError doesn't catch every possible
      // error.
      catch (error) {
        this.errorHandler(error)
      }
    },

    errorHandler (error) {
      // Show error that's built into EW. Necessary for embedded EW at least
      this.$emit('paypal-error', error)
      // Call the passed handler. Necessary to prevent issues like PSC-13872
      this.error?.(error)
    },

    // TODO: Make this actually request validation from sources
    validate () {
      return this.allFieldsValid
    },

    // Requests current state of field validation. Does not ask respondent to
    // re-validate unless touch: true is passed
    getFieldValidation ({ touch } = {}) {
      if (this.isVenmo) {
        EventBus.$emit('ewallet.venmoValidation', { touch })
      }
      else {
        EventBus.$emit('ewallet.paypalValidation', { touch })
      }
    },

    onMouseenter () {
      // Insist on a re-validation before starting the popup. (mouseenter
      // events are also triggered on click when another field has been focused.)
      // This handles the edge case where all fields were valid, a user
      // invalidates a field, and clicks the button before blurring the field or
      // when already hovering the button. In that case the field likely won't
      // have validated the new input so the button's hover state (with the
      // cursor, not pointer, still in the offending field) should look okay.
      // When the user clicks though they'll get a full error response from the
      // button and the field.

      // Ask for current state
      this.getFieldValidation({ touch: true })
    },
  },
}
</script>

<style lang="scss" scoped>
  #paypal-button {
    /* Try to match the effective width of the "Review" button,
     * which is the width of the text + 40px on each side for the
     * spinner and the compensating space. Not great. Wouldn't
     * match in Spanish. :/
     */
    min-width: 290px;
  }

  // TODO: If this doesn't already exist in our bootstrap, move it there
  .pe-none {
    pointer-events: none !important;
  }

  .invalid:hover {
    opacity: 0.5;
  }

  .paypal-validator {
    cursor: not-allowed;
  }
</style>
