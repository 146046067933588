<!--
  This has to be named ApplePayButtonWrapper instead of just ApplePayButton
  because that name conflicts with the <apple-pay-button> custom element
  registered by the Apple SDK. If we _also_ name this component ApplePayButton
  then Vue 3 will attempt to render _this_ component recursively instead of the
  component from the Apple Pay SDK.
-->

<template>
  <div
    class="position-relative"
    :class="{
      invalid: !allFieldsValid || disabled,
    }"
  >

    <!-- TODO: Add spinner as placeholder for button -->
    <apple-pay-button
      v-if="ready"
      buttonstyle="black"
      type="plain"
      locale="en"
      data-test="apple-pay-button"
      @click.prevent="startSession"
    />

    <div
      v-if="!allFieldsValid || disabled"
      :id="'apple-pay-validator' + _uid"
      class="position-absolute fixed-top w-100 h-100"
      @mouseenter="
        // Ask for current state on hover
        getFieldValidation()
      "
      @click="
        // Insist on a re-validation on click.
        // This handles the edge case where all fields were valid, a user
        // invalidates a field, and clicks the button before blurring the field.
        // In that case the field likely won't have validated the new input so
        // the button's hover state (with the cursor still in the offending
        // field) should look okay. When the user clicks though they'll get a
        // full error response from the button and the field.
        getFieldValidation({ touch: true })
      "
    />
    <b-tooltip
      v-if="!allFieldsValid"
      :target="'apple-pay-validator' + _uid"
      placement="left"
      triggers="hover focus"
      :title="$t('third_party_payment.error.fields_required')"
    />

  </div>
</template>

<script>
import { sentryException } from '../../sentry.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { mapGetters } from 'vuex'

export default {
  emits: ['payment-authorized', 'apple-pay-error'],
  props: {
    applePayConfig: {
      type: Object,
      required: true,
    },

    shouldIncludeContact: {
      type: Boolean,
      required: true,
    },
    shouldShowCheckbox: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraFieldsData: {
      type: Object,
      default: () => ({}),
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    clientTitle: {
      type: String,
      default: null,
    },
  },

  data () {
    const sources = [
      'applePayData',
      'contactInfo',
      'feeAgreement',
    ]

    return {
      // Init for reactivity
      isValid: sources.reduce((map, source) => {
        map[source] = false
        return map
      }, {}),

      // These don't need reactivity but it's good practice to list things like
      // this
      validationHandlers: sources.reduce((map, source) => {
        map[source] = valid => {
          this.isValid[source] = valid
        }
        return map
      }, {}),

      ready: false,
    }
  },

  computed: {
    allFieldsValid () {
      return this.isValid.applePayData &&
        (this.isValid.contactInfo || !this.shouldIncludeContact || !this.loggedIn) &&
        (this.isValid.feeAgreement || !this.shouldShowCheckbox)
    },

    ...mapGetters('eWallet', [
      'topLevelDomain',
    ]),

  },

  mounted () {
    // The page locks for a second when adding this. The timeout allows the
    // user's original feedback and the TenderManager transition to complete
    // before that happens.
    setTimeout(async () => {
      await this.loadScript()
      this.ready = true
    }, 400)

    // Sets up EventBus listeners
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$on(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )

    // Request initial validation state
    this.getFieldValidation()
  },

  beforeUnmount () {
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$off(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )
  },

  methods: {
    async loadScript () {
      let appleScript = document.querySelector('#apple-pay-sdk')
      // If the sdk is already requested then don't duplicate the script tag
      if (appleScript) {
        return
      }

      appleScript = document.createElement('script')
      appleScript.setAttribute('id', 'apple-pay-sdk')
      appleScript.setAttribute('src', '/js/apple-pay/apple-pay-sdk.js')
      document.head.appendChild(appleScript)
    },

    async startSession () {
      const request = {
        countryCode: 'US',
        currencyCode: 'USD',
        supportedNetworks: this.applePayConfig.supportedNetworks,
        merchantCapabilities: [ 'supports3DS' ],
        requiredBillingContactFields: [ 'postalAddress' ],
        total: this.applePayConfig.total,
        lineItems: this.applePayConfig.lineItems,
      }

      if (!this.loggedIn) {
        request.requiredShippingContactFields = [ 'email', 'phone' ]
      }

      const session = new window.ApplePaySession(3, request)

      session.begin()

      session.onvalidatemerchant = async () => {
        // Will return domain like localhost or beta.govhub.com
        // In the case of being in an iframe, we need to validate with the
        // top level domain
        const targetDomain = this.topLevelDomain ? this.topLevelDomain : window?.location?.hostname
        // This merchant display name is displayed on the macbook touchbar and
        // potentially for iphone apple pay modals as well.
        const merchantDisplayName = this.clientTitle || 'GovHub'
        const { data: { session: merchantSession } } = await this.$store.getters['API/ewallet'].validateApplePayMerchant(targetDomain, merchantDisplayName)

        session.completeMerchantValidation(merchantSession)
      }

      session.onpaymentauthorized = async event => {
        // This should emit an action to go to the receipt page after payment

        // Complete the payment and close the Apple Pay session
        const result = {
          'status': window.ApplePaySession.STATUS_SUCCESS,
        }
        session.completePayment(result)

        try {
          await this.$store.dispatch('eWallet/storeApplePayToken', event.payment.token)

          const addressInfo = event.payment.billingContact
          const fullName = addressInfo.givenName + ' ' + addressInfo.familyName
          const billingAddress = {
            name: fullName,
            userName: fullName,
            address1: addressInfo.addressLines[0],
            address2: addressInfo.addressLines[1],
            city: addressInfo.locality,
            country: addressInfo.countryCode,
            postalCode: addressInfo.postalCode,
            state: addressInfo.administrativeArea,
          }

          const billingData = {
            billingAddress,
            userName: billingAddress.name,
          }

          const contactInfo = event.payment.shippingContact

          // Changing the contact information when not logged in
          if (!this.loggedIn) {
            this.extraFieldsData.phone = contactInfo.phoneNumber
            this.extraFieldsData.email = contactInfo.emailAddress
          }
          this.$emit('payment-authorized', billingData)
        }
        catch (error) {
          this.error(error)
        }
      }

      // TODO: See if Apple Pay reports an error and use that instead.
      // Seems like you can only know if the session was canceled?
      session.oncancel = () => {
        this.error(this.$t('apple.session_canceled'))
      }
    },

    error (error) {
      sentryException(error)
      this.$emit('apple-pay-error', error)
    },

    // TODO: Make this actually request validation from sources
    validate () {
      return this.allFieldsValid
    },

    // Requests current state of field validation. Does not ask respondent to
    // re-validate unless touch: true is passed
    getFieldValidation ({ touch } = {}) {
      EventBus.$emit('ewallet.applePayValidation', { touch })
    },
  },

}
</script>

<style lang="scss" scoped>
apple-pay-button {
  --apple-pay-button-border-radius: #{$border-radius};
  --apple-pay-button-width: 100%;

  // This whole thing is a tad unfortunate. We can't control the font size for
  // the button because it's an svg. So I've ignored that and set the button
  // height to be exact instead. Unfortunately that's not quite straightforward
  // since our buttons heights are based off of the font-size.
  // 1. Ignore --apple-pay-button-padding since we don't have a font size to pad around
  // 2. Use this formula to set the height programmatically:
  //                         calc(        (font-size * line-height)        +             padding     + 2px to account for other button's borders
  --apple-pay-button-height: calc(#{$btn-font-size * $line-height-base} + (2 * #{$btn-padding-y-sm}) + 2px);

  @media (min-width: 768px) {
    --apple-pay-button-height: calc(#{$btn-font-size * $line-height-base } + (2 * #{$btn-padding-y})  + 2px);
  }
}

  // TODO: If this doesn't already exist in our bootstrap, move it there
  .pe-none {
    pointer-events: none !important;
  }

  .invalid:hover {
    opacity: 0.5;
  }

  #apple-pay-validator {
    cursor: not-allowed;
  }

</style>
