<template>
  <div class="saved-tender my-4">
    <label
      :for="labelFor"
      class="h3 pb-0 mb-1 mt-0 tender-name cursor-inherit"
    >
      {{ tender.name }}
    </label>

    <div class="d-flex align-items-center flex-wrap no-gutters">
      <TenderSummary
        :tender="tender"
        show-last-used
        class="col-8 col-sm-auto mb-1 mr-2"
      />

      <div class="col-12 col-sm-auto justify-content-end tender-tools d-flex flex-row ml-auto mb-1 mr-2">
        <b-link
          v-dompurify-html="$t('edit')"
          :disabled="!editable"
          class="edit-tender mr-3 mr-lg-4"
          @click="clickEdit"
        />
        <b-link
          v-dompurify-html="$t('tender.remove.default')"
          :disabled="!editable"
          class="remove-tender"
          @click.stop="$emit('beforeRemove')"
        />
      </div>
    </div>

    <b-row
      v-if="showRemoveConfirmation"
      class="pt-3"
      :class="{ 'mb-2': editable && !selectable }"
    >
      <b-col>
        <ConfirmAlert
          :message="$t('tender.remove.confirmation', { description: tender.name })"
          :error-message="removalError"
          :cancel-prompt="$t('common.cancel')"
          :continue-prompt="$t('tender.remove.default')"
          :waiting="removing"
          @cancel="$emit('cancelRemove')"
          @continue="handleRemove()"
        />
      </b-col>
    </b-row>

    <b-row v-if="editable && !selectable">
      <b-col>
        <Alert
          variant="warning"
          class="mb-0"
          :dismissible="false"
          :show-icon="false"
        >
          {{ $t(`tender_manager.${tender.type}.disabled_but_editable`) }}
        </Alert>
      </b-col>
    </b-row>

    <!-- We use anySavedTenderExtraFields here instead of showExtraFields to
         detect whether ANY extraFields have showForSavedTenders = true. If not,
         we don't want to display any extra padding or height. -->
    <b-row
      v-if="selected && anySavedTenderExtraFields"
      :class="{ 'border-bottom pb-3': showEditPanel }"
      class="pt-1"
    >
      <b-col>
        <ExtraFields
          ref="extraFields"
          :extra-fields="extraFields"
          :extra-fields-data="extraFieldsData"
          inline
          is-saved-tender
        />
      </b-col>
    </b-row>

    <b-row
      v-if="selected && showEditPanel"
      :class="{ 'show': showEditPanel }"
    >
      <b-col cols="12">
        <Alert
          id="edit-error"
          :show="editError !== ''"
          class="mb-0 mt-4"
          data-test="error"
          variant="danger"
          icon="alert-exclamation-triangle"
          icon-color="#63171d"
          @dismissed="clearCardError()"
        >
          <span v-dompurify-html="editError" />
        </Alert>
      </b-col>

      <b-col
        cols="12"
        lg="10"
        xl="8"
        class="mt-3 mb-0"
      >
        <CardInfo
          v-if="tender.isCard"
          ref="cardInfo"
          :card-data="editData"
          :should-save-tender="true"
          :disabled-fields="disabledFields"
          :is-management-view="isManagementView"
          :is-selection-view="isSelectionView"
          :enabled-methods="enabledMethods"
          :always-get-warehouse-token="alwaysGetWarehouseToken"
          :data-vault-token="dataVaultToken"
          show-save-buttons
          @card-number-hint-promise="cardNumberHintPromise = $event"
          @card-expiration-hint-promise="cardExpirationHintPromise = $event"
          @card-csc-hint-promise="cardCscHintPromise = $event"
          @cancel="cancel"
          @save="save"
        />
        <BankInfo
          v-else
          ref="bankInfo"
          :bank-data="editData"
          :show-extra-fields="isSelectionView"
          :needs-billing-address="needsBillingAddress"
          :is-management-view="isManagementView"
          :is-selection-view="isSelectionView"
          :disabled-fields="disabledFields"
          :last-name-required="lastNameRequired"
          show-save-buttons
          prefill-confirmation
          @cancel="cancel"
          @save="save"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardInfo from './CardInfo.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import ConfirmAlert from '@grantstreet/psc-vue/components/ConfirmAlert.vue'
import BankInfo from './BankInfo.vue'
import cloneDeep from 'lodash/cloneDeep.js'
import ExtraFields from './ExtraFields.vue'
import TenderSummary from './TenderSummary.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import { findKey } from '@grantstreet/psc-js/utils/objects.ts'
import { withTimeout, isTimeout } from '@grantstreet/psc-js/utils/timeout.js'

export default {
  emits: [
    'beforeRemove',
    'cancelRemove',
    'removeTender',
    'validationError',
    'formError',
    'updateTender',
  ],
  components: {
    CardInfo,
    BankInfo,
    Alert,
    ConfirmAlert,
    ExtraFields,
    TenderSummary,
  },

  mixins: [ scrollToMixin ],

  props: {
    tender: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    showRemoveConfirmation: {
      type: Boolean,
      default: false,
    },
    needsBillingAddress: {
      type: Boolean,
      default: true,
    },
    showExtraFields: {
      type: Boolean,
      default: false,
    },
    extraFields: {
      type: Object,
      default: () => ({}),
    },
    extraFieldsData: {
      type: Object,
      default: () => ({}),
    },
    labelFor: {
      type: String,
      required: true,
    },
    scrollContainer: {
      type: String,
      required: false,
      // This is intentional. It means that default params will work correctly.
      default: undefined,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    isManagementView: {
      type: Boolean,
      required: true,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    enabledMethods: {
      type: Array,
      required: true,
    },
    alwaysGetWarehouseToken: {
      type: Boolean,
      required: true,
    },
    dataVaultToken: {
      type: String,
      default: '',
    },
    lastNameRequired: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      showEditPanel: false,
      editData: cloneDeep(this.tender),
      removing: false,
      invalid: false,
      editError: '',
      removalError: '',
      cardNumberHintPromise: null,
      cardExpirationHintPromise: null,
      cardCscHintPromise: null,
    }
  },

  computed: {
    // Are there any extraFields that have showForSavedTenders = true?
    anySavedTenderExtraFields () {
      return this.showExtraFields &&
        findKey(this.extraFields, field => field.showForSavedTenders === true)
    },
  },

  unmounted () {
    // This is here so that selectFirstSavedTender in TenderManager doesn't
    // select the tender that is being removed right before it is deleted (if
    // it's the first tender).
    if (this.removing) {
      this.$emit('removeTender', this.tender)
    }
  },

  watch: {
    selected: {
      immediate: true,
      handler: async function () {
        if (this.selected && this.tender.isBank) {
          try {
            await this.$store.dispatch('eWallet/validateTender', this.tender)
          }
          catch (error) {
            if (error?.response?.data?.errorCode === 'TenderInvalid') {
              // Only prevent checkout if we're confident the tender is invalid
              this.invalid = true
              const errorMessage = error?.response?.data?.displayMessage
                ? 'We have removed the saved tender from your account. ' + error?.response?.data?.displayMessage
                : this.$t('api.error')
              this.$emit('validationError', errorMessage)

              await this.handleRemove()
              // This means that handleRemove failed
              if (!this.removing) {
                this.$emit('validationError', this.$t('tender.remove.error', { lastDigits: this.tender.lastDigits }))
              }
            }
            else {
              this.$emit('validationError', error?.response?.data?.displayMessage || this.$t('api.error'))
            }
          }
        }
      },
    },
  },

  methods: {
    clickEdit () {
      this.$gtag.event('E-Wallet', {
        'event_category': 'E-Wallet',
        'event_label': 'Click Edit',
        value: this.tender.type,
      })
      this.showEditPanel = !this.showEditPanel
    },

    async handleRemove () {
      // XXX: Jury-rigged so that destroy() will trigger the removeTender event
      this.removing = true
      this.removalError = ''

      try {
        await this.$store.dispatch('eWallet/removeTender', this.tender)
      }
      catch (error) {
        this.removalError = this.$t('tender.remove.error', { lastDigits: this.tender.lastDigits })
        this.removing = false
        this.$gtag.exception(error.message)
        this.$gtag.event('E-Wallet', {
          'event_category': 'E-Wallet',
          'event_label': 'Removal Error',
          value: error.message,
        })
        return
      }
      this.$gtag.event('E-Wallet', {
        'event_category': 'E-Wallet',
        'event_label': 'Removed',
        value: this.tender.type,
      })
    },

    validate () {
      if (this.invalid) {
        return false
      }

      const extraValid = !this.anySavedTenderExtraFields || this.$refs.extraFields.validate()

      if (this.showEditPanel) {
        if (this.tender.isCard) {
          const cardValid = this.$refs.cardInfo.validate()
          return cardValid && extraValid
        }
        else {
          const bankValid = this.$refs.bankInfo.validate()
          return bankValid && extraValid
        }
      }
      else {
        return extraValid
      }
    },

    // Make sure all of the DataVault hints have been retrieved.
    // See: CardInfo.vue@emitHintPromise

    // The timeout is needed because if the user focuses but doesn't
    // modify a field, the hint promise will never resolve. In that
    // case, we spin for 5 seconds before proceeding.
    async awaitCardInputs () {
      try {
        await withTimeout(
          Promise.all([
            this.cardNumberHintPromise,
            this.cardExpirationHintPromise,
            this.cardCscHintPromise,
          ]),
          5 * 1000,
        )
      }
      catch (error) {
        if (isTimeout(error)) {
          return
        }
        this.showError(this.$t('api.error'))
        throw error
      }
    },

    cancel () {
      this.showEditPanel = false
      this.editData = cloneDeep(this.tender) // discard changes
    },

    async save () {
      this.$store.dispatch('wait/start', 'submitting', { root: true })

      if (this.tender.isCard) {
        await this.awaitCardInputs()
      }

      if (!this.validate()) {
        this.$emit('formError')
        return
      }

      this.editError = ''

      if (this.tender.isCard) {
        this.$refs.cardInfo?.disableInputs()
      }

      try {
        await this.$store.dispatch('eWallet/updateTender', {
          input: this.editData,
          needsBillingAddress: this.needsBillingAddress,
          isManagementView: this.isManagementView,
        })
        this.$gtag.event('E-Wallet', {
          'event_category': 'E-Wallet',
          'event_label': 'Edited',
          value: this.tender.type,
        })
        this.showEditPanel = false
        this.editData = cloneDeep(this.tender)
        this.$emit('updateTender', this.tender)

        this.$store.dispatch('wait/end', 'submitting', { root: true })
      }
      catch (error) {
        const { errorMessage, displayMessage } = error?.response?.data || {}
        this.editError = errorMessage || displayMessage || this.$t('api.error')

        this.scrollTo('#edit-error', { container: this.scrollContainer })

        this.$gtag.exception(this.editError)
        this.$gtag.event('E-Wallet', {
          'event_category': 'E-Wallet',
          'event_label': 'Edit Error',
          value: this.editError,
        })
      }
    },
  },
}
</script>
