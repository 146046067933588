/**
 * Returns true if the passed object has all of the passed required keys.
 */
export default function requireKeys (obj, requiredKeys) {
  for (const requiredKey of requiredKeys) {
    if (!obj.hasOwnProperty(requiredKey)) {
      return false
    }
  }
  return true
}
