<template>
  <b-row
    :id="'payment-radio-card-' + _uid"
    class="payment-radio-card"
  >
    <div
      v-if="showRadio"
      class="d-flex flex-column mr-2 mt-2 pt-4 mr-sm-3"
    >
      <div
        class="custom-control custom-radio mt-1"
        :class="{ 'mt-2': expandable }"
        @click="select"
      >
        <input
          :id="inputId"
          :disabled="!selectable"
          :checked="checked"
          type="radio"
          data-test="card-radio-button"
          :data-tender-id="$attrs['data-tender-id']"
          class="custom-control-input"
        >
        <label class="custom-control-label">
          <span class="sr-only sr-only-focusable">{{ label }}</span>
        </label>
      </div>
    </div>

    <b-col
      :class="{
        'cursor-pointer': selectable && !checked && showMousePointer,
        'card-fields': selectable || editable,
        'bg-white': selectable || editable,
        'bg-disabled': !selectable && !editable,
      }"
      data-test="payment-card"
      class="payment-card border rounded my-1"
      @click="select"
    >

      <slot />
    </b-col>
    <!-- Styles are scoped in some modules so they don't apply properly to .tooltips appended to the body. A container="id-goes-here" is necessary. -->
    <b-tooltip
      v-if="!selectable && !editable && showTooltipWhenDisabled"
      :target="'payment-radio-card-' + _uid"
      container="e-wallet"
      placement="bottom"
      class="tooltip-inner"
      triggers="hover"
      :title="disabledTooltipMessage"
    />
  </b-row>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showMousePointer: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    showRadio: {
      type: Boolean,
      default: true,
    },
    isManagementView: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: '',
    },
    // Delivery Method provides a badge rather than a tooltip
    showTooltipWhenDisabled: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  computed: {
    disabledTooltipMessage () {
      return this.disabledMessage || this.$t('tender_manager.disabled_method_tooltip')
    },
  },

  methods: {
    select () {
      if ((this.selectable || this.isManagementView) && !this.checked) {
        this.$emit('select')
      }
    },
  },
}
</script>
<style scoped lang="scss">
  .bg-disabled {
    background-color: $off-white;
  }
</style>
