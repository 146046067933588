<template>
  <div
    :id="id"
    class="card-info info-form tooltips-secondary"
  >
    <Alert
      v-if="dvFailedToLoad"
      :dismissible="false"
      variant="danger"
      icon="alert-exclamation-triangle"
      icon-color="#63171d"
      class="mb-0"
    >
      {{ enabledMethods.includes('bank') ? t('card.data_vault_undefined.bank') : t('card.data_vault_undefined.no_bank') }}
    </Alert>
    <div v-else>
      <b-form-row class="mb-2 required">
        <b-col
          cols="12"
          md="3"
        >
          <div
            v-dompurify-html="t('card.number.label')"
            class="col-form-label"
            @click="focusCardInput('cardNumber')"
          />
        </b-col>
        <b-col
          cols="12"
          md="9"
          class="validated-form-input"
        >
          <div
            :class="{
              'has-brand': brandImage,
              'is-valid': !v$.cardData.cardNumber.$error,
              'is-invalid': v$.cardData.cardNumber.$error,
              'form-control-focus': fieldsFocused.cardNumber && !disabled,
              'lock-iframe': disabled,
            }"
            class="iframe-input-wrapper form-control card-number"
          >
            <iframe
              ref="cardNumber"
              :src="getCardInputUrl({
                type:'card-number',
                env: environment,
                token: dataVaultToken,
              })"
              data-test="card-number-iframe"
              @load="clearSensitiveInputs({ clearFromDV: false, reloadInputs: false })"
              @input="wipeValidationErr('cardNumber')"
            />
          </div>
          <ValidationErrors
            :validator="v$.cardData.cardNumber"
            :errors="{
              noDvErrors: dvErrors.cardNumber ? t(dvErrors.cardNumber) : '',
              expired: t('card.field_expired'),
              disabledCardBrands: cardData.cardBrand ? t(`card.brand.disabled.${friendlyToUglyBrands[cardData.cardBrand]}`) : '',
            }"
            data-test="cardData.cardNumber.error"
          />
          <b-img
            v-if="cardData.cardBrand && brandImage"
            :src="brandImage"
            :alt="cardData.cardBrand"
            :title="cardData.cardBrand"
            class="card-brand"
            data-test="card-brand-hint"
          />
        </b-col>
      </b-form-row>

      <b-form-row class="mb-1 required">
        <b-col
          cols="12"
          md="3"
        />
        <div
          v-if="shouldDisplayNoRefundBanner"
          class="no-refund-convenience-banner"
          :style="{ marginLeft: '3px' }"
          data-test="norefund-banner"
        >
          {{ $t('no_refund_banner.amex', { feeName }) }}
        </div>
      </b-form-row>

      <b-form-row class="mb-2 required">
        <b-col
          cols="12"
          md="3"
        >
          <div
            v-dompurify-html="t('card.expiration.label')"
            class="col-form-label"
            @click="focusCardInput('cardExpiration')"
          />
        </b-col>
        <b-col
          cols="5"
          md="3"
          lg="2"
          class="validated-form-input"
        >
          <div
            :class="{
              'is-valid': !v$.cardData.cardExpiration.$error,
              'is-invalid': v$.cardData.cardExpiration.$error,
              'form-control-focus': fieldsFocused.cardExpiration && !disabled,
              'lock-iframe': disabled,
            }"
            class="iframe-input-wrapper form-control card-expiration"
          >
            <iframe
              ref="cardExpiration"
              :src="getCardInputUrl({
                type:'card-expiration',
                env: environment,
                token: dataVaultToken,
              })"
              data-test="card-expiration-iframe"
              @input="wipeValidationErr('cardExpiration')"
            />
          </div>
          <ValidationErrors
            :validator="v$.cardData.cardExpiration"
            :errors="{
              noDvErrors: dvErrors.cardExpiration ? t(dvErrors.cardExpiration) : '',
              expired: t('card.field_expired'),
            }"
            data-test="cardData.cardExpiration.error"
          />
        </b-col>
        <b-col cols="7" />
      </b-form-row>

      <b-form-row class="mb-2 required">
        <b-col
          cols="12"
          md="3"
        >
          <div
            v-dompurify-html="t('card.csc.label')"
            class="col-form-label"
            @click="focusCardInput('cardCsc')"
          />
        </b-col>
        <b-col
          cols="5"
          md="3"
          lg="2"
          class="validated-form-input"
        >
          <div
            :class="{
              'is-valid': !v$.cardData.cardCsc.$error,
              'is-invalid': v$.cardData.cardCsc.$error,
              'form-control-focus': fieldsFocused.cardCsc && !disabled,
              'lock-iframe': disabled,
            }"
            class="iframe-input-wrapper form-control card-csc"
          >
            <iframe
              ref="cardCsc"
              :src="getCardInputUrl({
                type:'card-csc',
                env: environment,
                token: dataVaultToken,
              })"
              data-test="card-csc-iframe"
              @input="wipeValidationErr('cardCsc')"
            />
          </div>
          <ValidationErrors
            :validator="v$.cardData.cardCsc"
            :errors="{
              noDvErrors: dvErrors.cardCsc ? t(dvErrors.cardCsc) : '',
              expired: t('card.field_expired'),
            }"
            data-test="cardData.cardCsc.error"
          />
        </b-col>
        <b-col
          cols="1"
          class="d-flex"
        >
          <svgicon
            id="question"
            :fill="false"
            icon="question-mark-circle"
            width="1.125rem"
            height="1.125rem"
            color="currentColor"
            tabindex="-1"
          />

          <!-- Styles are scoped in some modules so they don't apply properly to .tooltips appended to the body. A container="id-goes-here" is necessary. -->
          <b-tooltip
            target="question"
            :container="id"
            placement="auto"
            class="tooltip-inner"
            triggers="hover click blur"
          >
            <i18n-t
              :i18n="i18n"
              keypath="b-tooltip-hack"
            >
              <b-img
                :src="cvvImage"
                :alt="cvvAltText"
              />
            </i18n-t>
          </b-tooltip>
        </b-col>
        <b-col cols="7" />
      </b-form-row>

      <b-form-group
        :label="t('card.name.label')"
        :label-for="'name-on-card-' + _uid"
        label-cols-md="3"
        class="required"
      >
        <!-- See the same field in the various <Type>Info components -->
        <b-form-input
          :id="'name-on-card-' + _uid"
          ref="nameOnCard"
          v-model="cardData.userName"
          :state="!v$.cardData.userName.$error"
          name="user-name"
          autocomplete="cc-name"
          :aria-describedby="'name-on-card-validation-errors-' + _uid"
          @input="v$.cardData.userName.$reset()"
          @blur="updateCardName"
        />
        <ValidationErrors
          :id="'name-on-card-validation-errors-' + _uid"
          :validator="v$.cardData.userName"
          :errors="{
            required: t('card.name.required'),
            maxLength: t('field_max_length', { len: 50 }),
            noLongNumbers: t('validation.cannot_contain_long_numbers'),
          }"
        />
      </b-form-group>

      <BillingAddressInfo
        ref="billingAddressInfo"
        :billing-data="cardData.billingAddress"
        :disabled-fields="disabledFields"
        :default-billing-address="defaultBillingAddress"
        :pre-fill-address-label="preFillAddressLabel"
      />

      <PaymentFormFooter
        ref="paymentFormFooter"
        :payment-data="cardData"
        :nickname-placeholder="t('card.nickname.placeholder')"
        :show-save-buttons="showSaveButtons"
        :is-selection-view="isSelectionView"
        :show-save-checkbox="showSaveCheckbox"
        :show-one-time-use-disclosure="alwaysGetWarehouseToken &&
          showOneTimeUseDisclosure"
        :only-anonymous-tenders="onlyAnonymousTenders"
        :force-save-method="forceSaveMethod"
        tender-type="card"
        @cancel="$emit('cancel')"
        @save="$emit('save')"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import { mapActions, mapGetters } from 'vuex'
import BillingAddressInfo from '@grantstreet/psc-vue/components/BillingAddressInfo.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'
import { noLongNumbers } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import { getBrandImage, getCvvImage, getCvvAltText, normalizeCardBrand } from '../card-brands.js'
import PaymentFormFooter from './PaymentFormFooter.vue'
import '@grantstreet/bootstrap/icons/js/question-mark-circle.js'
import { sentryException } from '../../sentry.js'
import { findKey } from '@grantstreet/psc-js/utils/objects.ts'
import { friendlyToUglyBrands } from '@grantstreet/psc-js/utils/cards.js'
import { getCardInputUrl } from '../utils.js'
import { useI18n } from 'vue-i18n'
import { v4 as uuid } from 'uuid'

const fieldHintMap = {
  cardNumber: ['cardBrand', 'numberLength', 'lastDigits'],
  cardExpiration: ['cardExpirationMonth', 'cardExpirationYear'],
  cardCsc: ['cscLength'],
}

function noDvErrors (field) {
  return helpers.withParams({ type: 'noDvErrors', field }, function (val) {
    return !this.dvErrors[field]
  })
}

function expired (field) {
  return helpers.withParams({ type: 'expired', field }, function (val) {
    return !this.fieldsExpired[field]
  })
}

function disabledCardBrands (field) {
  return helpers.withParams({ type: 'disabledCardBrands', field }, function (val) {
    return !this.disabledCardBrands.includes(this.cardData.cardBrand)
  })
}

export default {
  emits: [
    'save',
    'cancel',
    'error',
    'cardNumberHintPromise',
    'cardExpirationHintPromise',
    'cardCscHintPromise',
  ],
  components: {
    BillingAddressInfo,
    ValidationErrors,
    PaymentFormFooter,
    Alert,
  },

  setup () {
    const i18n = useI18n()

    return {
      id: `card-form-${uuid()}`,
      i18n,
      t: i18n.t,
      v$: useVuelidate(),
    }
  },

  props: {
    cardData: {
      type: Object,
      required: true,
    },
    showSaveCheckbox: {
      type: Boolean,
      default: false,
    },
    showSaveButtons: {
      type: Boolean,
      default: false,
    },
    checkMistakenCardNumber: {
      type: Function,
      default: () => {},
    },
    isManagementView: {
      type: Boolean,
      required: true,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    enabledMethods: {
      type: Array,
      required: true,
    },
    alwaysGetWarehouseToken: {
      type: Boolean,
      required: true,
    },
    showOneTimeUseDisclosure: {
      type: Boolean,
      required: true,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    dataVaultToken: {
      type: String,
      required: true,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
    disabledCardBrands: {
      type: Array,
      default: () => ([]),
    },
    shouldDisplayNoRefundBanner: {
      type: Boolean,
      default: false,
    },
  },

  validations: {
    cardData: {
      cardNumber: {
        noDvErrors: noDvErrors('cardNumber'),
        expired: expired('cardNumber'),
        disabledCardBrands: disabledCardBrands('cardNumber'),
      },
      cardExpiration: {
        noDvErrors: noDvErrors('cardExpiration'),
        expired: expired('cardExpiration'),
      },
      cardCsc: {
        noDvErrors: noDvErrors('cardCsc'),
        expired: expired('cardCsc'),
      },
      userName: {
        required,
        maxLength: maxLength(50),
        // Prevent all long numbers that _could_ be CCNs, even typo'd ones that
        // don't pass the Luhn check. (Sometimes people add a digit or mix one
        // up in the last four, and it's possible to reconstruct from their
        // request data.)
        noLongNumbers,
      },
    },
  },

  data () {
    const data = {
      // Maps field keys in cardData to their HTML Ids. The HTML Ids have to be
      // specific values in order for DataVault to store them correctly.
      fieldIds: {
        cardNumber: 'cc_credit_card_number',
        cardExpiration: 'cc_expiration_date',
        cardCsc: 'cc_cvv_number',
      },

      dvErrors: {
        // These are initially true because the inputs are empty
        cardNumber: 'card.number.required',
        cardExpiration: 'card.expiration.required',
        cardCsc: 'card.csc.required',
      },

      fieldsExpired: {
        cardNumber: false,
        cardExpiration: false,
        cardCsc: false,
      },

      fieldsFocused: {
        cardNumber: false,
        cardExpiration: false,
        cardCsc: false,
      },

      // See emitHintPromise and resolveHintPromise
      hintPromiseResolvers: {
        cardNumber: undefined,
        cardExpiration: undefined,
        cardCsc: undefined,
      },

      loadFailure: false,
      disabled: false,

      friendlyToUglyBrands,
    }

    data.requiredMessages = { ...data.dvErrors }

    return data
  },

  computed: {
    brandImage () {
      return getBrandImage(this.cardData.cardBrand)
    },
    cvvImage () {
      return getCvvImage(this.cardData.cardBrand, this.$i18n.locale)
    },
    cvvAltText () {
      return getCvvAltText(this.cardData.cardBrand, this)
    },

    // Map our hint names to the DV hint names, only saving the hints we care about
    hintNameMap () {
      return {
        // DV prepends the Ids that we use for the card fields to the hints.
        // Our name: DV name
        cardBrand: this.fieldIds.cardNumber + '_brand',
        numberLength: this.fieldIds.cardNumber + '_length',
        lastDigits: this.fieldIds.cardNumber + '_suffix',
        cardExpirationMonth: this.fieldIds.cardExpiration + '_month',
        cardExpirationYear: this.fieldIds.cardExpiration + '_year',
        cscLength: this.fieldIds.cardCsc + '_length',
      }
    },

    dvFailedToLoad () {
      const failed = this.loadFailure || !this.dataVaultToken
      if (failed) {
        sentryException(new Error('Could not load CC input fields'))
      }
      return failed
    },

    feeName () {
      return this.$t('fee.name')
    },

    ...mapGetters('eWallet', [
      'loggedIn',
      'environment',
    ]),
  },

  created () {
    // Listen for iframe messages
    window.addEventListener('message', this.receiveIframeMessage)
  },

  beforeUnmount () {
    window.removeEventListener('message', this.receiveIframeMessage)
  },

  methods: {
    getCardInputUrl,

    fieldKeyFromId (fieldId) {
      return findKey(this.fieldIds, id => id === fieldId)
    },

    clearCardBrand () {
      Vue.nextTick(() => {
        Vue.set(this.cardData, 'cardBrand', '')
      })
    },

    // We are clearing the validation error on the DV fields once the user
    // starts typing/modifying the input instead of on focus to allow the
    // error to be accessed and read out by aria screen readers.
    wipeValidationErr (field) {
      if (this.v$.field?.error) {
        this.dvErrors[field] = false
      }
    },

    addHints (field, newHints) {
      if (!newHints) {
        return
      }

      for (const hint of fieldHintMap[field]) {
        const dvName = this.hintNameMap[hint]
        const dvHint = newHints[dvName]
        if (!dvHint) {
          sentryException(new Error(`Missing expected hint ${dvName} for field ${field}`))
          return
        }

        this.updateHint(hint, dvHint)
      }

      // Build the 'MM/YY' expiration format which we use as a fallback in
      // tender.js If somehow month and year are not set (which I don't think
      // should be possible)
      if (newHints.cc_expiration_date) {
        const month = newHints.cc_expiration_date_month_mm
        const year = newHints.cc_expiration_date_year_yy
        this.cardData.cardExpiration = `${month}/${year}`
      }
    },

    clearHints (field) {
      // TODO: We're trying to root out unnecessary Vue.set()s. We should
      // investigate whether this is actually required or not
      fieldHintMap[field].map(hint => Vue.set(this.cardData, hint, ''))
    },

    updateHint (ourName, dvHint) {
      Vue.set(this.cardData, ourName, dvHint)
    },

    updateCardName () {
      this.v$.cardData.userName.$touch()
    },

    // ValidationErrors can't be set as an aria-describedby
    // for an input in an iframe.
    focusOnError () {
      if (this.v$.cardData.cardNumber.$error && this.$refs.cardNumber) {
        this.$refs.cardNumber.focus()
        return
      }
      if (this.v$.cardData.cardExpiration.$error && this.$refs.cardExpiration) {
        this.$refs.cardExpiration.focus()
        return
      }
      if (this.v$.cardData.cardCsc.$error && this.$refs.cardCsc) {
        this.$refs.cardCsc.focus()
        return
      }
      if (this.v$.cardData.userName.$error && this.$refs.nameOnCard) {
        this.$refs.nameOnCard.focus()
        return
      }
      if (this.$refs.billingAddressInfo.v$.$errors.length) {
        this.$refs.billingAddressInfo.focusOnError()
      }
    },

    validate (forSubmission = false) {
      this.v$.$touch()
      const billingValid = this.$refs.billingAddressInfo.validate()
      const footerValid = this.$refs.paymentFormFooter.validate()
      if (this.v$.$invalid || !billingValid || !footerValid) {
        return false
      }

      const invalidHints = []
      for (const field of Object.keys(fieldHintMap)) {
        let clearField = false
        for (const hint of fieldHintMap[field]) {
          if (!this.cardData[hint]) {
            invalidHints.push(hint)
            clearField = true
          }
        }

        if (clearField) {
          this.clearSensitiveInput(field)
          this.v$.cardData[field].$touch()
        }
      }

      if (invalidHints.length > 0) {
        // As documented in PSC-5997, we've been seeing e-wallet submissions
        // missing information that *should* be garnered from hint data.
        // This intends to make users fill out that data again,
        // rather than cause issues down the line.
        if (forSubmission) {
          sentryException(new Error('Unexpectedly missing the following hints: ' + JSON.stringify(invalidHints)))
        }
        return false
      }

      return true
    },

    clearSensitiveInputs ({ clearFromDV = true, reloadInputs = true } = {}) {
      this.clearCardBrand()

      for (const fieldId of Object.keys(this.fieldIds)) {
        this.clearSensitiveInput(fieldId, { clearFromDV, reloadInputs })
      }
      this.v$.$reset()
    },

    clearSensitiveInput (fieldId, { clearFromDV = true, reloadInputs = true } = {}) {
      if (clearFromDV) {
        this.$refs[fieldId].contentWindow.postMessage({ type: 'clear' }, '*')
      }
      else if (reloadInputs) {
        // The token has expired, so DataVault.clear won't work. Instead,
        // just reload the iframes. This is the only way to clear the inputs
        // without DataVault or directly touching them.
        this.$refs[fieldId].src += ''
      }
      this.resetFieldRequiredState(fieldId)
      this.clearHints(fieldId)
    },

    // Resets the error state for a sensitive input to "this field is required"
    resetFieldRequiredState (fieldId) {
      this.dvErrors[fieldId] = this.requiredMessages[fieldId]
    },

    focusCardInput (fieldId) {
      if (this.$refs[fieldId] && this.$refs[fieldId].contentWindow) {
        this.$refs[fieldId].contentWindow.focus()
      }
    },

    // This emits a promise so that the parent component can prevent submitting
    // E-Wallet until all of the DataVault hints have been received.
    //
    // We have to make sure the user can't submit E-Wallet before DataVault
    // returns card input hints for a changed input. Otherwise if the user
    // entered one card number, blurred the field, and then entered a different
    // card number and clicked Submit before blurring the field again, we would
    // save their tender to E-Wallet with the FIRST card number's hints (brand
    // and last digits). The same applies to the other fields. See PSC-5914.
    //
    // To prevent this, we set a promise for the changed field when vault.js
    // reports that it has started waiting for DataVault hints (via the 'start'
    // event). The parent component can await this promise and then continue
    // knowing that the field hints have been received. We can't resolve the
    // promise until we get the 'complete' event from vault.js, which means we
    // unfortunately need to expose the resolve() function to the component so
    // the 'complete' event can call it (basically implementing Promise.defer()
    // which used to be supported but is now obsolete).
    //
    // We don't worry about calling reject() because we have the dvErrors object
    // for tracking failures to prevent submission separately.
    emitHintPromise (field) {
      // This can emit:
      //  - cardNumberHintPromise
      //  - cardExpirationHintPromise
      //  - cardCscHintPromise
      if (!this.hintPromiseResolvers[field]) {
        this.$emit(`${field}HintPromise`, new Promise(resolve => {
          this.hintPromiseResolvers[field] = resolve
        }))
      }
    },

    // Resolves the hint promise for a field to indicate the DataVault hints
    // have been retrieved. See emitHintPromise.
    resolveHintPromise (field) {
      if (this.hintPromiseResolvers[field]) {
        this.hintPromiseResolvers[field]()
        this.hintPromiseResolvers[field] = undefined
      }
    },

    toggleInputs (state) {
      const type = state ? 'enable' : 'disable'
      this.disabled = !state
      for (const fieldId of Object.keys(this.fieldIds)) {
        // Optional chain; don't attempt if iframe is dead (like after
        // navigation)
        this.$refs[fieldId].contentWindow?.postMessage({ type }, '*')
      }
    },

    disableInputs () {
      this.toggleInputs(false)
    },

    enableInputs () {
      this.toggleInputs(true)
    },

    initialize () {
      this.clearSensitiveInputs()
      this.$refs.paymentFormFooter.initialize()
      this.v$.$reset()
      this.$refs.billingAddressInfo.v$.$reset()
    },

    receiveIframeMessage (event) {
      const data = event.data || {}
      const type = data.type
      const options = data.options

      if (!type) {
        // We did not create this message (could have been init message or
        // message from DV itself)
        return
      }

      const field = this.fieldKeyFromId(options?.field?.id)
      if (options?.field && !field) {
        // This is intended for a different component (MobilePayInfo.vue)
        return
      }

      // Handle DataVault events
      if (type === 'start') {
        this.emitHintPromise(field)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'complete') {
        this.fieldsExpired[field] = false
        this.resolveHintPromise(field)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'success') {
        this.dvErrors[field] = false

        // Parse the returned hints and add them to the data
        this.addHints(field, options.hints)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'error') {
        const defaultError = 'data_vault.storage_error'
        let error = options.message

        if (
          error.includes('Card number rejected') ||
          error.includes('credit card number must')
        ) {
          error = 'card.number.invalid'
        }
        else if (error.includes('Security code rejected')) {
          error = 'card.csc.invalid'
        }
        else if (
          error.includes('invalid expiration date') ||
          error.includes('date month rejected') ||
          error.includes('date year rejected')
        ) {
          error = 'card.expiration.invalid'
        }
        else if (error.includes('DataVault token expired')) {
          // The DV token expired after 24 hours
          this.$emit('error', this.t('data_vault.token_expired'))
          this.clearSensitiveInputs({ clearFromDV: false })
          return
        }
        else {
          const message = 'DataVault error: ' + error
          this.$gtag.exception({ description: message })
          this.$gtag.event(
            'DataVault Error',
            {
              'event_category': 'E-Wallet',
              'event_label': message,
            },
          )
          sentryException(new Error(message))
        }

        this.dvErrors[field] = error || defaultError

        this.v$.cardData[field].$touch()
        this.clearHints(field)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'fieldExpired') {
        this.fieldsExpired[field] = true
        this.v$.cardData[field].$touch()
        this.clearHints(field)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'cardBrand') {
        if (options.brand) {
          // The card brands from this cardBrand event aren't normalized
          // according to PEx's standards, so we normalize them here. This brand
          // will be overwritten by the official card brand hint (which DOES use
          // the standard PEx brands), so we shouldn't need to do this
          // normalization, but this is here just to be careful (see PSC-5914).
          Vue.set(this.cardData, 'cardBrand', normalizeCardBrand(options.brand))

          const field = this.fieldKeyFromId(options.field.id)
          this.v$.cardData[field].$touch()
        }
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: null })
      }
      else if (type === 'loadFailure') {
        this.loadFailure = true
      }
      else if (type === 'focus') {
        // Notify the parent element that we're waiting for DataVault hints as
        // soon as the user focuses the input. This is because sometimes
        // DataVault doesn't send the 'start' message until after E-Wallet is
        // already submitting. If the user blurs without changing the field,
        // we'll never get a completed or clear event, so the parent will need
        // to implement a timeout when waiting for the hint promise to resolve.
        this.emitHintPromise(field)

        // First, unfocus everything
        for (const field of Object.keys(this.fieldsFocused)) {
          this.fieldsFocused[field] = false
        }
        this.fieldsFocused[field] = true

        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'blur') {
        this.fieldsFocused[field] = false
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'clear') {
        // One or more fields were cleared from DV
        const fields = options.fields
        for (const fieldId of fields) {
          const field = this.fieldKeyFromId(fieldId)
          this.resetFieldRequiredState(field)
          this.resolveHintPromise(field)
          this.logDiagnostics({ dataVaultEvent: type, dataVaultField: fieldId })
        }
      }
    },

    ...mapActions('eWallet', [
      'logDiagnostics',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.card-brand {
  bottom: 0;
  height: 29px;
  margin: auto;
  padding: 0 calc(.375rem + 6px) 0 0; // form-row padding + input padding + border width
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2; // above .form-control (which is 1)
}

.iframe-input-wrapper,
#e-wallet .iframe-input-wrapper {
  line-height: 0 !important;
  overflow: hidden; // otherwise some browsers will not clip border-radius
  padding: 0 !important;
  position: relative;

  iframe {
    border: none;
    border-radius: 5px;
    height: 40px;
    padding-right: 1px; // sometimes it overlapped the wrapper border
    width: 100%;

  }

  &.lock-iframe {
    // This is the same as $input-disabled-bg
    background-color: #e9ecef !important;
    pointer-events: none;

    &.input-control-focus {
      box-shadow: none;
    }
  }

  &.has-brand iframe {
    padding-right: 52px; // to account for card brand
  }
}

.tooltip-inner {
  img {
    width: 259px;
    height: 181px;
  }
}

.col-form-label {
  cursor: default;
}

.no-refund-convenience-banner {
  background-color: #ffc107; /* yellow background */
  color: #000; /* black text */
  font-weight: bold;
  text-align: center;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 10px;
  margin: 3px 0;
  max-width: fit-content;
}

</style>
