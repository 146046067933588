<template>
  <b-modal
    ref="modal"
    class=""
    modal-class="fee-conflict-modal"
    :title="$t('international_card.title')"
    :ok-title="$t('confirm')"
    :cancel-title="$t('payment.edit_payment_method')"
    static
    lazy
    centered
    container="E-Wallet"
    @ok="$emit('ok')"
    @shown="renderCount++"
    @hidden="$emit('hidden')"
  >
    <span
      :key="renderCount"
      v-dompurify-html="$t('international_card.fee_notice', {
        fee,
      })"
      data-test="fee-conflict-text"
    />
  </b-modal>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/x.js'
import '@grantstreet/bootstrap/icons/js/print.js'

export default {
  emits: ['ok', 'hidden'],

  props: {
    fee: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    // For some reason dompurify-html gets cleared after opening/closing the
    // modal a few times. Use a key to force a re-render.
    renderCount: 0,
  }),

  computed: {
    feeName () {
      return this.$t('fee.name')
    },
  },

  methods: {
    show () {
      this.$refs.modal.show()
    },
  },
}
</script>

<style lang="scss">
</style>
