<template>
  <div data-test="tender-summary">
    <template v-if="tender.isCard">
      <b-img
        v-if="brandImage"
        :src="brandImage"
        :alt="tender.capitalizedType"
        height="23"
        class="brand-image mb-1 mr-1"
      />
      <template v-else>{{ tender.capitalizedType }}</template>
    </template>
    <span
      v-else
      :class="{ 'font-weight-bold': bold }"
    >
      <template v-if="tender.bankName">
        <span :class="{ 'font-weight-bold': bold }">{{ tender.niceBankName }}</span> -
      </template>
      {{ tender.capitalizedType }} Account
    </span>
    <span
      v-if="tender.lastDigits"
      :class="{ 'font-weight-bold': bold }"
    >
      {{ $t('card.ending_in', { last_digits: tender.lastDigits }) }}
    </span>

    <span v-if="tender.cardExpiration"> -
      <span
        v-if="tender.expired"
        v-dompurify-html="$t('card.expired', { date: tender.cardExpirationMMYY })"
        class="text-danger font-weight-bold"
      />
      <span
        v-else
        v-dompurify-html="$t('card.expires', { date: tender.cardExpirationMMYY })"
      />
    </span>

    <template v-if="showLastUsed && tender.isLastUsed">
      <span class="mr-2" />
      <b-badge
        v-dompurify-html="$t('tender.last_used')"
        pill
      />
    </template>
  </div>
</template>

<script>
import getBrandImage from '../card-brands.js'

export default {
  props: {
    tender: {
      type: Object,
      required: true,
    },
    showLastUsed: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    brandImage () {
      if (this.tender.isCard) {
        return getBrandImage(this.tender.cardBrand)
      }
      return ''
    },
  },
}
</script>
