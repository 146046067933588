<template>
  <div class="submit-button checkout-button">
    <template
      v-if="paypalConfig && ['paypal', 'venmo'].includes(selectedTenderFormType)"
    >
      <!-- In some ways it's nice to have these properties and callbacks split
      out but I'm not at all convinced it's the right choice. -->
      <!-- The dynamic key (when changed) forces the existing component to
      destroy, and re-render. This is necessary because the paypal button is
      created and attached to the dom when the component is mounted, and is
      unchanged by prop changes. This allows the paypal button to switch to the
      venmo button when the paypalAsVenmo prop changes.-->
      <PaypalButton
        :key="paypalConfig.useVenmo && paypalAsVenmo ? 'paypal-button-paypal' : 'paypal-button-venmo'"
        :data-test="`paypal-button-${paypalConfig.useVenmo && paypalAsVenmo ?
          'venmo' : 'main'}`"
        class="d-block"
        :cart-has-delayed-payments="cartHasDelayedPayments"
        :class="{
          'd-none': $wait.is('inPaypal'),
        }"
        :client="paypalConfig.clientId"
        :merchant-ids="paypalConfig.merchantIds"
        :create-order="paypalCreateOrder"
        :create-vault-setup-token="paypalConfig.createVaultSetupToken"
        :save-tender="paypalConfig.savePayPalTender"
        :approve="paypalConfig.onApprove"
        :success="paypalConfig.success"
        :push-confirmation-with-order-id="paypalConfig.pushConfirmationWithOrderID"
        :error="paypalConfig.error || null"
        :should-include-contact="shouldIncludeContact"
        :should-show-checkbox="shouldShowCheckbox"
        :disabled="!enabled"
        :is-venmo="paypalConfig.useVenmo && paypalAsVenmo"
        :use-cost-linked-pricing="useCostLinkedPricing"
        @create-order="$emit('paypal-create-order')"
        @paypal-error="$emit('paypal-error', $event)"
      />
    </template>
    <template
      v-else-if="applePayConfig && selectedTenderFormType === 'apple'"
    >
      <ApplePayButtonWrapper
        :apple-pay-config="applePayConfig"
        :disabled="!enabled"
        :should-include-contact="shouldIncludeContact"
        :should-show-checkbox="shouldShowCheckbox"
        :extra-fields-data="extraFields"
        :logged-in="loggedIn"
        :client-title="clientTitle"
        @apple-pay-error="$emit('apple-pay-error', $event)"
        @payment-authorized="$emit('submit', $event)"
      />
    </template>
    <template
      v-else-if="googlePayConfig && selectedTenderFormType === 'google'"
    >
      <GooglePayButton
        :google-pay-config="googlePayConfig"
        :disabled="!enabled"
        :extra-fields-data="extraFields"
        :logged-in="loggedIn"
        :should-include-contact="shouldIncludeContact"
        :should-show-checkbox="shouldShowCheckbox"
        @google-pay-error="$emit('google-pay-error', $event)"
        @payment-authorized="$emit('submit', $event)"
      />
    </template>
    <!-- PSC-18962 We've run into multiple issues with this tooltip.
      1. Tooltips won't show on disabled buttons so the button needs a wrapper.

      2. For some reason this tooltip doesn't properly respect its v-if. When
         users are redirected back from the checkout page (PSC-15683) something
         does awry and the tooltip will still exist even if the v-if value is
         false. We haven't been able to figure out why this happens so there are
         several layers of fallback here.

         a. The span :id is a ternaries so there will be nothing to target if
         the condition is false. This works.

         b. Same thing for the tooltip :target, just as insurance.

         c. There's a watcher on selectedTenderFormType which programmatically
         disables the button when there is a truthy type. This also works.

      3. Doing tooltip :disabled.sync="!selectedTenderFormType" as in PSC-15683
         breaks the tooltip and ProgressButton :disabled so that neither work
         (PSC-18962). That probably is because the .sync attempts to update
         value of whatever it's passed.
         (The b-tooltip docs do recommend the disabled.sync method but it's
         never worked here.)
         (The vue documentation for .sync says it doesn't work with expressions
         like that but I did try it with a straight value and it still broke.)
         -->
    <span
      v-else
      :id="!selectedTenderFormType ? 'submit-tooltip-target' : undefined"
    >
      <ProgressButton
        id="submit-button"
        :disabled="!enabled || !selectedTenderFormType"
        :waiting="$wait.is('saving tender') || $wait.is('submitting')"
        variant="primary"
        data-test="submit-button"
        class="submit-button w-100"
        @click="$emit('submit')"
      >
        {{ buttonText || $t('submit_button') }}
      </ProgressButton>
    </span>

    <b-tooltip
      v-if="!selectedTenderFormType"
      ref="tooltip"
      :title="$t('submit.error.choose_a_method')"
      container="e-wallet"
      :target="!selectedTenderFormType ? 'submit-tooltip-target' : undefined"
    />
  </div>
</template>

<script>
import ProgressButton from '@grantstreet/psc-vue/components/ProgressButton.vue'
import PaypalButton from './PaypalButton.vue'
import ApplePayButtonWrapper from './ApplePayButtonWrapper.vue'
import GooglePayButton from './GooglePayButton.vue'

export default {
  emits: [
    'submit',
    'paypal-create-order',
    'paypal-error',
    'apple-pay-error',
    'google-pay-error',
  ],
  components: {
    ProgressButton,
    PaypalButton,
    ApplePayButtonWrapper,
    GooglePayButton,
  },

  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    selectedTenderFormType: {
      type: [String, Number],
      required: true,
    },
    paypalConfig: {
      type: Object,
      default: null,
    },
    paypalAsVenmo: {
      type: Boolean,
      default: false,
    },
    paymentData: {
      type: Object,
      default: () => ({}),
    },
    extraFields: {
      type: Object,
      default: () => ({}),
    },
    shouldIncludeContact: {
      type: Boolean,
      required: true,
    },
    shouldShowCheckbox: {
      type: Boolean,
      required: true,
    },
    applePayConfig: {
      type: Object,
      default: null,
    },
    googlePayConfig: {
      type: Object,
      default: null,
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    clientTitle: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      required: true,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    // Programmatically kill the tooltip. See comment in template.
    selectedTenderFormType (value) {
      this.$refs.tooltip?.$emit(value ? 'disable' : 'enable')
    },
  },

  methods: {
    async paypalCreateOrder (data, actions) {
      return this.paypalConfig.createOrder(data, actions, this.paymentData, this.extraFields)
    },
  },
}
</script>
