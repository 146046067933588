<template>
  <b-form-input
    v-bind="$attrs"
    ref="maskedInput"
    :value="displayValue"
    :state="state"
    class="monospace"
    @focus="active = true; $emit('focus')"
    @blur="active = false; $emit('blur')"
    @input="val => $emit('input', val)"
  />
</template>

<script>
export default {
  emits: ['focus', 'blur', 'input'],
  props: {
    value: {
      type: String,
      default: '',
    },
    state: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      active: false,
    }
  },

  computed: {
    displayValue () {
      // Show the value while editing or if there's an error
      if (this.active || !this.state) return this.value

      return this.maskedValue
    },

    maskedValue () {
      // Display as all bullets except the last two chars
      const v = this.value || ''
      return v.replace(/(...*?)(.?.?)$/, (m, head, tail) => '\u2022'.repeat(head.length) + tail)
    },
  },
}
</script>
