<template>
  <div>
    <b-form-checkbox
      ref="policyCheckbox"
      v-model="confirmed"
      :state="!v$.confirmed.$error"
      data-test="policy-agreement-checkbox"
      class="mt-2 d-flex align-items-start policy-agreement-checkbox"
      inline
      aria-describedby="policy-agreement-checkbox-validation-errors"
      @change="$emit('input', $event)"
    >
      <i18n-t
        :keypath="policyCheckboxTranslation"
        tag="span"
        class="text-body"
        scope="global"
      >
        <!-- Newlines translate to spaces inside this tag -->
        <template #link>
          <a
            :id="policyAgreementId"
            v-b-modal="policyAgreementId"
            href="#!"
            scope="global"
            @click.prevent
          >
            {{ linkText }}
          </a>
        </template>
      </i18n-t>
      <ValidationErrors
        id="policy-agreement-checkbox-validation-errors"
        :validator="v$.confirmed"
        :errors="t('policy_agreement.required')"
        data-test="policy-agreement-checkbox-error"
      />
    </b-form-checkbox>

    <ECheckConfirmationModal
      v-if="tenderType === 'bank'"
      :id="policyAgreementId"
      :policy-text="policyText"
      :bank-data="bankData"
      data-test="policy-agreement-modal"
    />

    <!-- We always use top, even for fallback, because something with VDomPurify
    clears out the tooltip content when falling back.
    See https://grantstreet.slack.com/archives/GEU7XAAB1/p1582035790084500 -->
    <b-tooltip
      v-else
      :target="policyAgreementId"
      placement="top"
      :fallback-placement="['top']"
      custom-class="tooltip-large policy-agreement-tooltip"
      container="e-wallet"
    >
      <i18n-t
        :i18n="i18n"
        keypath="b-tooltip-hack"
      >
        <div v-dompurify-html="policyText" />
      </i18n-t>
    </b-tooltip>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { checked } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import ECheckConfirmationModal from './ECheckConfirmationModal.vue'
import { useI18n } from 'vue-i18n'

export default {
  emits: ['input'],
  components: {
    ValidationErrors,
    ECheckConfirmationModal,
  },

  setup () {
    const i18n = useI18n()

    return {
      i18n,
      t: i18n.t,
      v$: useVuelidate(),
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    policyCheckboxTranslation: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    policyText: {
      type: String,
      required: true,
    },
    bankData: {
      type: Object,
      required: true,
    },
    tenderType: {
      type: String,
      default: 'card',
    },
  },

  data () {
    return {
      confirmed: false,
    }
  },

  computed: {
    policyAgreementId () {
      return `bank-policy-modal-${this._uid}`
    },
  },

  validations: {
    confirmed: {
      checked,
    },
  },

  mounted () {
    this.v$.$reset()
  },

  methods: {
    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },
    focusOnError () {
      if (this.v$.confirmed.$error) {
        return this.$refs.policyCheckbox.focus()
      }
    },
  },
}
</script>
