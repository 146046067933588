<template>
  <div class="info-form">
    <!-- Hidden DV input for token -->
    <iframe
      ref="mobilePayToken"
      class="d-none"
      :src="getCardInputUrl({
        type: tokenType,
        env: environment,
        token: dataVaultToken,
      })"
      @load="clearTokenInput({ clearFromDV: false, reloadInputs: false })"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { useVuelidate } from '@vuelidate/core'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { mapActions, mapGetters } from 'vuex'
import { sentryException } from '../../sentry.js'
import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'
import { getCardInputUrl } from '../utils.js'

const hints = {
  apple: [
    'applePay',
    'applePayTokenLength',
    'cardBrand',
    'numberLength',
    'lastDigits',
    'cardExpirationMonth',
    'cardExpirationYear',
  ],
  google: [
    'googlePay',
    'cardBrand',
    'numberLength',
    'lastDigits',
    'cardExpirationMonth',
    'cardExpirationYear',
  ],
}

// Map our hint names to the DV hint names, only saving the hints we care about
const hintNameMap = {
  applePayTokenLength: 'apple_pay_token_length',
  applePay: 'apple_pay',
  googlePay: 'google_pay',
  cardBrand: 'card_number_brand',
  numberLength: 'card_number_length',
  lastDigits: 'card_number_suffix',
  cardExpirationMonth: 'expiration_date_month',
  cardExpirationYear: 'expiration_date_year',
}

export default {
  emits: ['mobilePayHintPromise', 'error', 'dv-error'],
  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    mobileType: {
      type: String,
      required: true,
      validator: type => [ 'apple', 'google' ].includes(type),
    },
    mobilePayData: {
      type: Object,
      required: true,
    },
    dataVaultToken: {
      type: String,
      required: true,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data () {
    const isApplePay = this.mobileType === 'apple'
    const isGooglePay = this.mobileType === 'google'

    let tokenType = ''
    let tokenId = ''
    let injectTokenHandlerName = ''
    let eventBusEventName = ''
    if (isApplePay) {
      tokenType = 'apple-pay-token'
      tokenId = 'apple_pay_token'
      injectTokenHandlerName = 'eWallet/injectApplePayTokenHandler'
      eventBusEventName = 'ewallet.applePayValidation'
    }
    else if (isGooglePay) {
      tokenType = 'google-pay-token'
      tokenId = 'google_pay_token'
      injectTokenHandlerName = 'eWallet/injectGooglePayTokenHandler'
      eventBusEventName = 'ewallet.googlePayValidation'
    }

    return {
      fieldExpired: false,
      fieldFocused: false,

      // See emitHintPromise and resolveHintPromise
      hintPromise: undefined,
      hintPromiseResolver: undefined,

      loadFailure: false,
      disabled: false,

      isApplePay,
      isGooglePay,
      tokenType,
      tokenId,
      injectTokenHandlerName,
      eventBusEventName,
    }
  },

  computed: {
    ...mapGetters('eWallet', [
      'environment',
    ]),
  },

  validations () {
    return {}
  },

  mounted () {
    // This allows the ApplePayButtonWrapper to kick off token storage using the
    // input
    this.$store.commit(this.injectTokenHandlerName, this.storeToken)

    // Return current state unless asked for a re-validation
    EventBus.$on(this.eventBusEventName, this.handleValidation)

    // Listen for iframe messages
    window.addEventListener('message', this.receiveIframeMessage)
  },

  beforeUnmount () {
    window.removeEventListener('message', this.receiveIframeMessage)

    EventBus.$off(this.eventBusEventName, this.handleValidation)
  },

  methods: {
    getCardInputUrl,

    // Two different ways to handle validation. By parent request
    // (this.$ref.validate()) and upon request via event (see mounted hook).

    // When a parent asks for validation directly check all sources: local
    // inputs, children, and then hints.
    validate (forSubmission = false) {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return false
      }

      return this.validateHints(forSubmission)
    },

    // Validate just hints
    validateHints (forSubmission = false) {
      const invalidHints = []

      for (const hint of hints[this.mobileType]) {
        if (!this.mobilePayData[hint]) {
          invalidHints.push(hint)
        }
      }

      if (invalidHints.length > 0) {
        this.clearTokenInput()
        // As documented in PSC-5997, we've been seeing e-wallet submissions
        // missing information that *should* be garnered from hint data.
        // This intends to make users fill out that data again,
        // rather than cause issues down the line.
        if (forSubmission) {
          sentryException(new Error('Unexpectedly missing the following hints: ' + JSON.stringify(invalidHints)))
        }
        return false
      }

      return true
    },

    // Each component with fields responds to these events individually so the
    // $refs aren't touched.
    // Hints are also not touched since this the hidden input can't be filled
    // until *after* this clears the button and the Apple Pay/Google Pay SDK has
    // done its thing.
    handleValidation ({ touch }) {
      return touch ? this.validateLocalAndEmit() : this.emitValidation()
    },

    emitValidation () {
      const eventName = `ewallet.validation.${this.mobileType}PayData`
      EventBus.$emit(eventName, !this.v$.$invalid)
    },

    validateLocalAndEmit () {
      this.v$.mobilePayData?.userName.$touch()
      this.emitValidation()
    },

    addHints (newHints) {
      if (!newHints) {
        return
      }

      for (const hint of hints[this.mobileType]) {
        const dvName = hintNameMap[hint]
        const dvHint = newHints[dvName]
        if (!dvHint) {
          sentryException(new Error(`Missing expected hint ${dvName} for field ${this.tokenId}`))
          return
        }

        this.updateHint(hint, dvHint)
      }
    },

    clearHints () {
      // TODO: We're trying to root out unnecessary Vue.set()s. We should
      // investigate whether this is actually required or not
      hints[this.mobileType].map(hint => Vue.set(this.mobilePayData, hint, ''))
    },

    updateHint (ourName, dvHint) {
      Vue.set(this.mobilePayData, ourName, dvHint)
    },

    clearTokenInput ({ clearFromDV = true, reloadInputs = true } = {}) {
      if (clearFromDV) {
        this.$refs.mobilePayToken.contentWindow.postMessage({ type: 'clear' }, '*')
      }
      else if (reloadInputs) {
        // The token has expired, so DataVault.clear won't work. Instead,
        // just reload the iframes. This is the only way to clear the inputs
        // without DataVault or directly touching them.
        this.$refs.mobilePayToken.src += ''
      }
      this.clearHints()
    },

    // XXX: I've left this comment unchanged between here and CardInfo.vue for
    // simplicity, if edits are needed.
    // This emits a promise so that the parent component can prevent submitting
    // E-Wallet until all of the DataVault hints have been received.
    //
    // We have to make sure the user can't submit E-Wallet before DataVault
    // returns card input hints for a changed input. Otherwise if the user
    // entered one card number, blurred the field, and then entered a different
    // card number and clicked Submit before blurring the field again, we would
    // save their tender to E-Wallet with the FIRST card number's hints (brand
    // and last digits). The same applies to the other fields. See PSC-5914.
    //
    // To prevent this, we set a promise for the changed field when vault.js
    // reports that it has started waiting for DataVault hints (via the 'start'
    // event). The parent component can await this promise and then continue
    // knowing that the field hints have been received. We can't resolve the
    // promise until we get the 'complete' event from vault.js, which means we
    // unfortunately need to expose the resolve() function to the component so
    // the 'complete' event can call it (basically implementing Promise.defer()
    // which used to be supported but is now obsolete).
    //
    // We don't worry about calling reject() because we have the dvError object
    // for tracking failures to prevent submission separately.
    emitHintPromise () {
      if (this.hintPromise && this.hintPromise.emitted) {
        return
      }
      this.setHintPromise()
      this.$emit('mobilePayHintPromise', this.hintPromise)
      this.hintPromise.emitted = true
    },

    setHintPromise () {
      if (this.hintPromise) {
        return
      }
      this.hintPromise = new Promise(resolve => {
        this.hintPromiseResolver = resolve
      })
      return this.hintPromise
    },

    // Resolves the hint promise for a field to indicate the DataVault hints
    // have been retrieved. See emitHintPromise.
    resolveHintPromise (data) {
      this.hintPromiseResolver?.(data)
      this.hintPromiseResolver = null
      this.hintPromise = null
    },

    toggleInput (state) {
      const type = state ? 'enable' : 'disable'
      this.disabled = !state
      // Optional chain; don't attempt if iframe is dead (like after
      // navigation)
      this.$refs.mobilePayToken.contentWindow?.postMessage({ type }, '*')
    },

    storeToken (token) {
      if (this.hintPromise) {
        throw new Error('Token storage in progress')
      }
      // Optional chain; don't attempt if iframe is dead (like after
      // navigation)
      this.$refs.mobilePayToken.contentWindow?.postMessage({ type: 'hidden-fill', data: token }, '*')
      return this.setHintPromise()
    },

    disableInput () {
      this.toggleInput(false)
    },

    enableInput () {
      this.toggleInput(true)
    },

    initialize () {
      this.clearTokenInput()
      this.v$.reset()
    },

    receiveIframeMessage (event) {
      const data = event.data || {}
      const type = data.type
      const options = data.options
      if (!type) {
        // We did not create this message (could have been init message or
        // message from DV itself)
        return
      }

      if (options?.field?.id && options?.field.id !== this.tokenId) {
        // This is in intended for a different component (CardInfo.vue)
        return
      }

      // Handle DataVault events
      if (type === 'start') {
        this.emitHintPromise()
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'complete') {
        this.fieldExpired = false
        this.resolveHintPromise(this.mobilePayData)
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'success') {
        // Parse the returned hints and add them to the data
        this.addHints(options.hints)

        // TODO: This is dummied up until all the we figure out what to do about
        // terms
        this.updateHint(
          'savedPaymentMethodDisclosure',
          firstToUpperCase(this.mobileType) + ' Pay terms were agreed to.',
        )

        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'error') {
        const error = options.message

        // TODO: There might be more of these
        if (error.includes('DataVault token expired')) {
          // The DV token expired after 24 hours
          this.$emit('error', this.$t('data_vault.token_expired'))
          this.clearTokenInput({ clearFromDV: false })
          return
        }
        else {
          const message = 'DataVault error: ' + error
          this.$gtag.exception({ description: message })
          this.$gtag.event(
            'DataVault Error',
            {
              'event_category': 'E-Wallet',
              'event_label': message,
            },
          )
          sentryException(new Error(message))
        }

        this.dvError = error || 'data_vault.storage_error'

        this.clearHints()
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })

        // We emit an error here because there is no appropriate place to put the datavault error in this component
        // when checking out.
        this.$emit('dv-error', error)
      }
      else if (type === 'fieldExpired') {
        this.fieldExpired = true
        this.clearHints()
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'loadFailure') {
        this.loadFailure = true
      }
      else if (type === 'focus') {
        // Notify the parent element that we're waiting for DataVault hints as
        // soon as the user focuses the input. This is because sometimes
        // DataVault doesn't send the 'start' message until after E-Wallet is
        // already submitting. If the user blurs without changing the field,
        // we'll never get a completed or clear event, so the parent will need
        // to implement a timeout when waiting for the hint promise to resolve.
        this.emitHintPromise()

        this.fieldFocused = true

        // Clear the "field required" error for this input. This is for when the
        // user fills a card input and clicks Submit before blurring the field.
        // We want to use the emitHintPromise workflow to make E-Wallet submit
        // automatically in that case, but the validation would interrupt that.
        this.dvError = false
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'blur') {
        this.fieldFocused = false
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: options.field.id })
      }
      else if (type === 'clear') {
        this.resolveHintPromise()
        this.logDiagnostics({ dataVaultEvent: type, dataVaultField: this.tokenId })
      }
    },

    ...mapActions('eWallet', [
      'logDiagnostics',
    ]),
  },
}
</script>

<style lang="scss" scoped>
</style>
