<template>
  <b-modal
    :id="id"
    ref="modal"
    class="e-check-confirmation-modal-outer"
    modal-class="e-check-confirmation-modal policy-agreement-modal"
    ok-only
    :ok-title="$t('ok.caps')"
    static
    lazy
    centered
    @shown="renderCount++"
  >
    <template #modal-title>
      <div class="mt-1 d-flex small font-weight-bold justify-content-between">
        <i18n-t
          keypath="echeck_confirm.title"
          scope="global"
        />
        <a
          class="d-flex align-items-center mr-8 font-weight-bold small"
          href="#!"
          @click.prevent="print"
        >
          <svgicon
            class="mr-2"
            width="24"
            height="24"
            color="#666"
            :fill="false"
            icon="print"
          />
          <i18n-t
            keypath="print"
            scope="global"
          />
        </a>
      </div>
    </template>
    <template #modal-header-close>
      <svgicon
        :fill="false"
        icon="x"
      />
    </template>
    <span
      :key="renderCount"
      v-dompurify-html="policyText"
    />
  </b-modal>
</template>

<script>
import '@grantstreet/bootstrap/icons/js/x.js'
import '@grantstreet/bootstrap/icons/js/print.js'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    bankData: {
      type: Object,
      required: true,
    },
    policyText: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    // For some reason dompurify-html gets cleared after opening/closing the
    // modal a few times. Use a key to force a re-render.
    renderCount: false,
  }),

  methods: {
    print () {
      // In order to print correctly across browsers, the echeck_confirm info
      // needs to be written to an iframe that is appended to the document
      // and then printed
      const iframe = document.createElement('iframe')
      iframe.id = 'print'
      document.body.appendChild(iframe)
      const doc = iframe.contentWindow.document
      doc.open()
      doc.write(`<html>
        <head>
          <title>
            ${this.$t('echeck_confirm.title')}
          </title>
        </head>
        <body>
        <h5 class="mt-1 d-flex small font-weight-bold justify-content-between">
          ${this.$t('echeck_confirm.title')}
        </h5>
        ${this.policyText}
        </body>
      </html>`)
      doc.close()
      // Remove iframe once printed/print dialog closes
      iframe.contentWindow.onafterprint = function (event) {
        iframe.parentNode.removeChild(iframe)
      }
      // Focus included for IE
      iframe.contentWindow.focus()
      iframe.contentWindow.print()
    },
  },
}
</script>
<style lang="scss">
.e-check-confirmation-modal {
  .modal-title {
    flex: 1;
  }
}
</style>
