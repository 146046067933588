<template>
  <div data-test="tender-manager">
    <b-container fluid>
      <b-row
        v-if="messages.length || (disabledTendersMessage && enabledMethods.length > 0)"
        id="status-messages"
        class="py-0 pl-3"
      >
        <div
          class="d-flex flex-column my-1 py-4"
          cols="1"
        />
        <b-col>
          <Alert
            v-if="disabledTendersMessage && enabledMethods.length > 0"
            class="mb-3 preserve-newlines"
            variant="warning"
            :dismissible="false"
            icon="alert-exclamation-triangle"
            icon-color="#63171d"
          >
            <span v-dompurify-html="disabledTendersMessage" />
          </Alert>
          <Alert
            v-for="({variant, text, preserveNewlines}, index) in messages"
            :key="index + text"
            class="mb-3"
            :class="preserveNewlines ? 'preserve-newlines' : ''"
            :variant="variant"
            :dismissible="variant === 'success'"
            :icon="variant === 'success' ? 'check-circle' : 'alert-exclamation-triangle'"
            :icon-color="variant === 'success' ? '#7CC437' : '#63171d'"
            @dismissed="messages.splice(index, 1)"
          >
            <span v-dompurify-html="text" />
          </Alert>
        </b-col>
      </b-row>

      <template
        v-if="hasSavedTenders && !adminFilling"
      >
        <SectionHeading
          :class="{'pl-custom': showRadioButtons}"
        >
          {{ $t('tender_manager.methods') }}
        </SectionHeading>
        <div
          data-test="saved-tenders"
          class="mb-3"
        >
          <div
            v-for="tender in filteredSavedTenders"
            :key="tender.tenderId"
          >
            <PaymentRadioCard
              v-if="isTenderSelectable(tender)"
              :label="tender.name"
              :data-tender-id="tender.tenderId"
              :input-id="'select-tender-' + tender.tenderId"
              :checked="selectedOption === tender.tenderId"
              :show-mouse-pointer="isSelectionView"
              :show-radio="showRadioButtons"
              :is-management-view="isManagementView"
              @select="selectedOption = tender.tenderId"
            >

              <Tender
                ref="tenders"
                :tender="tender"
                :label-for="'select-tender-' + tender.tenderId"
                :show-remove-confirmation="showingRemoveConfirmation === tender.tenderId"
                :show-extra-fields="showExtraFields"
                :extra-fields="extraFields"
                :extra-fields-data="extraFieldsData"
                :needs-billing-address="needsBillingAddress"
                :selectable="isTenderSelectable(tender)"
                :editable="isTenderSelectable(tender)"
                :selected="tender.tenderId === selectedOption"
                :scroll-container="scrollContainer"
                :is-management-view="isManagementView"
                :disabled-fields="disabledFields"
                :is-selection-view="isSelectionView"
                :enabled-methods="enabledMethods"
                :always-get-warehouse-token="alwaysGetWarehouseToken"
                :data-vault-token="dataVaultToken"
                :last-name-required="lastNameRequired"
                @update-tender="handleUpdateTender"
                @before-remove="showingRemoveConfirmation = tender.tenderId"
                @cancel-remove="showingRemoveConfirmation = null"
                @remove-tender="handleRemoveTender"
                @form-error="scrollToFirstError"
                @validation-error="handleTenderValidationError"
              />
            </PaymentRadioCard>
          </div>
        </div>
      </template>

      <SectionHeading
        :class="{'pl-custom': showRadioButtons}"
        data-test="add-method-heading"
      >
        {{ addMethodHeading }}
        <template #extra>
          <b-link
            class="ml-3"
            target="_blank"
            @click="openPrivacyPolicy()"
          >
            {{ $t('privacy_policy.default') }}
          </b-link>
        </template>
      </SectionHeading>

      <div class="d-flex flex-column">
        <PaymentRadioCard
          v-if="isAllowedType({ allowedMethods, type: 'card' }) && isEnabledType('card')"
          :label="$t('tender_manager.card.heading')"
          :checked="selectedOption === 'card'"
          :show-radio="showRadioButtons"
          :is-management-view="isManagementView"
          data-test="card-payment-radio-card"
          input-id="select-card"
          :class="{'order-2' : bankFirst}"
          @select="() => {if (canSelect) selectedOption = 'card'}"
        >

          <div
            id="card-radio-card-header"
            class="d-flex justify-content-between"
          >
            <label
              for="select-card"
              class="show-card-form my-4 h3 text-primary font-weight-normal"
              :class="{ 'cursor-pointer' : selectedOption !== 'card' }"
              data-test="show-card-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isEnabledType('card') ? 0 : -1">
                {{ $t('tender_manager.card.heading') }}
              </b-link>
            </label>
            <span class="d-none d-md-flex flex-column justify-content-center pr-2">
              <span>
                <span class="d-block ">{{ $t('tender_manager.we_accept_these_cards') }}</span>
                <span class="d-block">
                  <!-- The hard coded disabledCardBrands string values are specced to be the same as
                    https://support.grantstreet.com/wiki/display/PEX/DataVault+Spec#DataVaultSpec-5.2.2CreditCardBrand
                    -->
                  <b-img
                    v-if="!disabledCardBrands.includes('VISA')"
                    :src="getBrandImage('visa')"
                    alt="Visa"
                    title="Visa"
                    height="26"
                    data-test="visa-brand-image"
                  />
                  <b-img
                    v-if="!disabledCardBrands.includes('MasterCard')"
                    :src="getBrandImage('mastercard')"
                    alt="Mastercard"
                    title="Mastercard"
                    height="26"
                    data-test="mastercard-brand-image"
                  />
                  <b-img
                    v-if="!disabledCardBrands.includes('American Express')"
                    :src="getBrandImage('amex')"
                    alt="Amex"
                    title="Amex"
                    height="26"
                    data-test="amex-brand-image"
                  />
                  <b-img
                    v-if="!disabledCardBrands.includes('Discover Card')"
                    :src="getBrandImage('discover')"
                    alt="Discover"
                    title="Discover"
                    height="26"
                    data-test="discover-brand-image"
                  />
                </span>
              </span>
            </span>
          </div>

          <b-collapse
            id="card-accordion"
            :visible="showCard"
            class="row"
            @shown="scrollToForm"
          >
            <b-col cols="12">
              <Alert
                id="card-error"
                :show="showCardError"
                class="mb-4"
                data-test="cardError"
                variant="danger"
                icon="alert-exclamation-triangle"
                icon-color="#63171d"
                :dismissible="!cardErrorCallback"
                :show-icon="!cardErrorCallback"
                @dismissed="clearCardError()"
              >
                <span
                  class="w-100 d-lg-flex justify-content-between"
                >
                  <span
                    id="card-error"
                    v-dompurify-html="cardError"
                  />
                  <div
                    v-if="cardErrorCallback"
                    class="mt-lg-0 mt-3 d-flex justify-content-end"
                  >
                    <b-button
                      variant="outline-danger"
                      class="mr-2 px-4"
                      @click="clearCardError()"
                    >
                      {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                      variant="danger"
                      class="px-6"
                      @click="cardErrorCallback(); clearCardError()"
                    >
                      {{ $t('common.yes') }}
                    </b-button>
                  </div>
                </span>
              </Alert>
            </b-col>
            <b-col
              cols="12"
              lg="10"
              xl="8"
              class="mb-3"
            >
              <CardInfo
                :key="tenderFormsReload"
                ref="cardInfo"
                :card-data="cardData"
                :show-save-buttons="isManagementView"
                :show-one-time-use-disclosure="showOneTimeUseDisclosure"
                :check-mistaken-card-number="checkIfCardNumber"
                :show-save-checkbox="showSaveCheckbox"
                :is-management-view="isManagementView"
                :is-selection-view="isSelectionView"
                :enabled-methods="enabledMethods"
                :always-get-warehouse-token="alwaysGetWarehouseToken"
                :disabled-fields="disabledFields"
                :data-vault-token="dataVaultToken"
                :only-anonymous-tenders="onlyAnonymousTenders"
                :default-billing-address="defaultBillingAddress"
                :pre-fill-address-label="preFillAddressLabel"
                :force-save-method="forceSaveMethod"
                :disabled-card-brands="disabledCardBrands"
                :should-display-no-refund-banner="shouldShowNonRefundableFeeBanner"
                @card-number-hint-promise="cardNumberHintPromise = $event"
                @card-expiration-hint-promise="cardExpirationHintPromise = $event"
                @card-csc-hint-promise="cardCscHintPromise = $event"
                @cancel="cancelTender"
                @error="showError"
                @save="submit"
              />
            </b-col>
          </b-collapse>
        </PaymentRadioCard>

        <PaymentRadioCard
          v-if="paypalConfig &&
            !isManagementView &&
            isAllowedType({ allowedMethods, type: 'paypal' }) &&
            isEnabledType('paypal') &&
            !autoPaySelected &&
            !isMixedRexCart"
          label="PayPal"
          :editable="false"
          :checked="selectedOption === 'paypal'"
          :show-radio="showRadioButtons"
          data-test="paypal-payment-radio-card"
          input-id="select-paypal"
          class="order-2"
          @select="handleSelectDigitalTender('paypal')"
        >

          <div class="d-flex justify-content-between">
            <label
              for="select-paypal"
              class="my-4 h3 text-primary font-weight-normal"
              :class="{ 'cursor-pointer' : selectedOption !== 'paypal' }"
              data-test="show-paypal-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isAllowedType({ allowedMethods, type: 'paypal' }) ? 0 : -1">
                PayPal
              </b-link>
            </label>
          </div>

          <b-collapse
            id="paypal-accordion"
            :visible="showPayPal"
            class="row"
            @show="scrollToForm"
          >
            <b-col cols="12">
              <Alert
                id="paypal-error"
                :show="paypalError !== ''"
                data-test="paypalError"
                variant="danger"
                icon="alert-exclamation-triangle"
                icon-color="#63171d"
                @dismissed="clearPaypalError"
              >
                {{ paypalError }}
              </Alert>
            </b-col>

          </b-collapse>
        </PaymentRadioCard>
        <!-- TODO: Handle disabling alternate card sources when card is disabled -->
        <PaymentRadioCard
          v-if="isAllowedType({ allowedMethods, type: 'apple' }) &&
            !isManagementView &&
            isEnabledType('card') &&
            !autoPaySelected"
          :key="tenderFormsReload"
          label="Apple"
          :checked="selectedOption === 'apple'"
          :show-radio="showRadioButtons"
          data-test="apple-payment-radio-card"
          input-id="select-apple"
          class="order-2"
          @select="handleSelectDigitalTender('apple')"
        >

          <div class="d-flex justify-content-between">
            <label
              for="select-apple"
              class="my-4 h3 text-primary font-weight-normal"
              :class="{ 'cursor-pointer' : selectedOption !== 'apple' }"
              data-test="show-apple-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isAllowedType({ allowedMethods, type: 'apple' }) ? 0 : -1">
                Apple Pay
              </b-link>
            </label>
            <span class="d-none d-md-flex flex-column justify-content-center pr-2 text-black">
              <!--
                  This api is rough. Two options:
                  1. Use original fill https://stackoverflow.com/questions/49742679/vue-svgicon-converting-fill-to-fill,
                     AND ALSO :fill="true".
                  2. Specify stroke and fill colors https://mmf-fe.github.io/svgicon/en/guide/advanced.html#component.
                     In this case: color="r-#000 #fff #000"
                -->
              <svgicon
                fill
                original
                icon="apple-pay-mark"
                width="2.75rem"
                height="1.75rem"
              />
            </span>
          </div>

          <b-collapse
            id="apple-pay-accordion"
            :visible="showApplePay"
            class="row"
            @show="scrollToForm"
          >
            <b-col
              cols="12"
              lg="10"
              xl="8"
            >
              <MobilePayInfo
                id="apple-pay-form"
                ref="applePayInfo"
                :mobile-type="'apple'"
                :mobile-pay-data="applePayData"
                :data-vault-token="dataVaultToken"
                :disabled-fields="disabledFields"
                :default-billing-address="defaultBillingAddress"
                :pre-fill-address-label="preFillAddressLabel"
                @dv-error="setApplePayError"
                @mobile-pay-token-promise="applePayTokenPromise = $event"
              />
            </b-col>
          </b-collapse>

          <Alert
            id="apple-pay-error"
            :show="applePayError !== ''"
            data-test="appleError"
            variant="danger"
            icon="alert-exclamation-triangle"
            icon-color="#63171d"
            @dismissed="clearApplePayError"
          >
            {{ applePayError }}
          </Alert>
        </PaymentRadioCard>

        <PaymentRadioCard
          v-if="isAllowedType({ allowedMethods, type: 'google' }) &&
            !isManagementView &&
            isEnabledType('card') &&
            !autoPaySelected"
          label="Google"
          :checked="selectedOption === 'google'"
          :show-radio="showRadioButtons"
          data-test="google-payment-radio-card"
          input-id="select-google"
          class="order-2"
          @select="handleSelectDigitalTender('google')"
        >

          <div class="d-flex justify-content-between">
            <label
              for="select-google"
              class="my-4 h3 text-primary font-weight-normal"
              :class="{ 'cursor-pointer' : selectedOption !== 'google' }"
              data-test="show-google-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isAllowedType({ allowedMethods, type: 'google' }) ? 0 : -1">
                {{ $t('google.friendly_name.default') }}
              </b-link>
            </label>
            <span class="d-none d-md-flex flex-column justify-content-center pr-2 text-black">
              <svgicon
                fill
                original
                icon="google-pay-mark"
                width="3rem"
                height="1.75rem"
              />
            </span>
          </div>

          <b-collapse
            id="google-pay-accordion"
            :visible="showGooglePay"
            class="row"
            @show="scrollToForm"
          >
            <b-col
              cols="12"
              lg="10"
              xl="8"
            >
              <MobilePayInfo
                id="google-pay-form"
                ref="googlePayInfo"
                :mobile-type="'google'"
                :mobile-pay-data="googlePayData"
                :data-vault-token="dataVaultToken"
                :disabled-fields="disabledFields"
                :default-billing-address="defaultBillingAddress"
                :pre-fill-address-label="preFillAddressLabel"
                @google-pay-token-promise="googlePayTokenPromise = $event"
              />
            </b-col>
          </b-collapse>

          <Alert
            id="google-pay-error"
            :show="googlePayError !== ''"
            data-test="googleError"
            variant="danger"
            icon="alert-exclamation-triangle"
            icon-color="#63171d"
            @dismissed="clearGooglePayError"
          >
            {{ googlePayError }}
          </Alert>
        </PaymentRadioCard>

        <PaymentRadioCard
          v-if="paypalConfig &&
            !isManagementView &&
            isAllowedType({ allowedMethods, type: 'paypal' }) &&
            canUseVenmo &&
            isEnabledType('paypal') &&
            !autoPaySelected"
          label="Venmo"
          :editable="false"
          :checked="selectedOption == 'venmo'"
          :show-radio="showRadioButtons"
          data-test="venmo-payment-radio-card"
          input-id="select-venmo"
          class="order-2"
          @select="handleSelectDigitalTender('venmo')"
        >
          <div class="d-flex justify-content-between">
            <label
              for="select-venmo"
              class="my-4 h3 text-primary font-weight-normal"
              data-test="show-venmo-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isAllowedType({ allowedMethods, type: 'paypal' }) ? 0 : -1">
                Venmo
              </b-link>
            </label>
          </div>
          <b-collapse
            id="venmo-accordion"
            :visible="showVenmo"
            class="row"
            @show="scrollToForm"
          >
            <!-- Venmo is processed through paypal. Any paypal error will show
            up on both the venmo and paypal forms -->
            <b-col cols="12">
              <Alert
                id="venmo-error"
                :show="paypalError !== ''"
                data-test="venmoError"
                variant="danger"
                icon="alert-exclamation-triangle"
                icon-color="#63171d"
                @dismissed="clearPaypalError"
              >
                {{ paypalError }}
              </Alert>
            </b-col>
          </b-collapse>

        </PaymentRadioCard>
        <PaymentRadioCard
          v-if="isAllowedType({ allowedMethods, type: 'bank' }) && isEnabledType('bank')"
          :label="$t('tender_manager.bank_account.heading')"
          :checked="selectedOption === 'bank'"
          :show-radio="showRadioButtons"
          :is-management-view="isManagementView"
          input-id="select-bank"
          data-test="bank-payment-radio-card"
          :class="{'order-1' : bankFirst, 'order-3' : !bankFirst}"
          @select="() => {if (canSelect) selectedOption = 'bank'}"
        >

          <div class="d-flex justify-content-between">
            <label
              for="select-bank"
              class="show-bank-form my-4 h3 text-primary font-weight-normal"
              :class="{ 'cursor-pointer' : selectedOption !== 'bank' }"
              data-test="show-bank-form"
            >
              <!-- Use a link for tabbing accessibility -->
              <b-link :tabindex="!showRadioButtons && isEnabledType('bank') ? 0 : -1">
                {{ $t('tender_manager.bank_account.heading') }}
              </b-link>
            </label>
          </div>

          <b-collapse
            id="bank-accordion"
            :visible="showBank"
            class="row"
            @shown="scrollToForm"
          >
            <b-col cols="12">
              <Alert
                :show="showBankError"
                class="mb-4"
                data-test="bankError"
                variant="danger"
                icon="alert-exclamation-triangle"
                icon-color="#63171d"
                :should-collapse="shouldCollapseError"
                @dismissed="clearBankError()"
                @toggle-collapse="handleToggleErrorCollapse"
              >
                <!-- For mobile screens -->
                <div class="d-md-none">
                  <b-collapse :visible="!isErrorCollapsed">
                    <span
                      id="bank-error"
                      v-dompurify-html="bankError"
                    />
                  </b-collapse>
                  <span
                    v-if="isErrorCollapsed"
                    v-dompurify-html="truncatedBankError"
                  />
                </div>

                <!-- For larger screens -->
                <div class="d-none d-md-block">
                  <span
                    id="bank-error"
                    v-dompurify-html="bankError"
                  />
                </div>
              </Alert>
            </b-col>
            <b-col
              cols="12"
              lg="10"
              xl="8"
              class="mb-3"
            >
              <BankInfo
                id="bank-form"
                ref="bankInfo"
                :key="tenderFormsReload"
                :bank-data="bankData"
                :debit-sending-ids="debitSendingIds"
                :show-save-buttons="isManagementView"
                :needs-billing-address="needsBillingAddress"
                :is-management-view="isManagementView"
                :is-selection-view="isSelectionView"
                :disabled-fields="disabledFields"
                :show-save-checkbox="showSaveCheckbox"
                :show-one-time-use-disclosure="showOneTimeUseDisclosure"
                :always-get-warehouse-token="alwaysGetWarehouseToken"
                :last-name-required="lastNameRequired"
                :only-anonymous-tenders="onlyAnonymousTenders"
                :default-billing-address="defaultBillingAddress"
                :pre-fill-address-label="preFillAddressLabel"
                :max-return-fee="maxReturnFee"
                :force-save-method="forceSaveMethod"
                @cancel="cancelTender"
                @save="submit"
              />
            </b-col>
          </b-collapse>
        </PaymentRadioCard>

      </div>

      <div v-if="shouldIncludeContact()">
        <SectionHeading
          :class="{'pl-custom': showRadioButtons}"
        >
          {{ $t("contact.information") }}
        </SectionHeading>
        <b-row
          :class="{'pl-custom': showRadioButtons}"
        >
          <b-col
            class="border rounded my-1"
          >
            <ContactInfo
              ref="extraFields"
              :enable-sms="enableSms"
              :extra-fields="extraFieldsForContactInfo"
              :extra-fields-data="extraFieldsData"
              :selected-tender-form-type="selectedTenderFormType"
              :cart-has-delayed-payments="cartHasDelayedPayments"
            />
          </b-col>
        </b-row>
      </div>

      <b-row
        v-if="isSelectionView"
        class="payment-info-footer pt-3"
      >
        <b-col
          sm="12"
          md="12"
          lg="5"
          class="d-flex align-items-center flex-column overflow-wrap"
        >

          <div
            v-if="showFilterAdvisory"
            class="ml-sm-5 w-100"
          >
            <span v-dompurify-html="debitFilterAdvisory" />
          </div>

          <ConvenienceFee
            ref="convenienceFee"
            v-model="convenienceFeeAgreement"
            :fee="convenienceFee"
            :translated-fee-keys="translatedFeeKeys"
            :tender-type="prospectiveTenderType"
            :form-type="selectedTenderFormType"
            :should-show-checkbox="shouldShowCheckbox"
            :button-text="buttonText"
            :is-selection-view="isSelectionView"
            :hide-fee-message="hideFeeMessage"
            :use-cost-linked-pricing="useCostLinkedPricing"
            :class="{ 'mt-2': showFilterAdvisory }"
            class="ml-sm-5 w-100"
          />

          <strong
            v-if="selectedTenderFormType && !hasFee"
            class="text-body font-weight-bold ml-sm-5 w-100"
          >
            <span
              v-dompurify-html="$t(`policy_agreement.notice`, { buttonText })"
            />
          </strong>

          <PolicyAgreementCheckbox
            v-if="selectedTenderFormType && policyText"
            ref="policyAgreement"
            :bank-data="bankData"
            :policy-checkbox-translation="`policy_agreement${adminFilling ? '.admin' : '.default'}`"
            :link-text="$t(
              `policy_agreement.link_text`,
              { tenderName: $t(`${selectedTenderFormType}.friendly_name.default`) }
            )"
            :policy-text="policyText"
            :tender-type="prospectiveTenderType"
            :class="{ 'mt-2': $refs.convenienceFee && $refs.convenienceFee.hasFee && isSelectionView }"
            class="ml-sm-5 w-100"
          />

          <slot name="additional-checkboxes" />

          <FeeConflictModal
            ref="feeConflictModal"
            :fee="confirmedFinalFee"
            @ok="resolveFeeConflictPromise"
            @hidden="rejectFeeConflictPromise"
          />

        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="7"
          class="d-flex justify-content-end"
        >
          <span class="w-100 align-items-center d-flex submit-group justify-content-end mt-5 mt-md-0">
            <span class="d-flex flex-wrap justify-content-start align-items-center secure-payment">
              <b-img
                :alt="$t('tender_manager.secure_payment')"
                :src="securePaymentImage"
                class="mr-3 secure-payment-img w-auto mb-3 mb-sm-0"
              />
              <b-img
                :alt="$t('tender_manager.digicert_secured')"
                :src="digicertSecuredImage"
                class="mr-3 digicert-secured-img w-auto mb-3 mb-sm-0"
              />
            </span>

            <EWalletProgressButton
              v-if="isSelectionView && !noSubmitButton"
              :enabled="enabled"
              :selected-tender-form-type="selectedTenderFormType"
              :paypal-config="paypalConfig"
              :paypal-as-venmo="selectedOption === 'venmo'"
              :payment-data="selectedTenderData"
              :extra-fields="extraFieldsData"
              :should-include-contact="shouldIncludeContact()"
              :should-show-checkbox="shouldShowCheckbox"
              :apple-pay-config="applePayConfig"
              :google-pay-config="googlePayConfig"
              :logged-in="loggedIn"
              :client-title="clientTitle"
              :button-text="submitButtonText"
              :cart-has-delayed-payments="cartHasDelayedPayments"
              :use-cost-linked-pricing="useCostLinkedPricing"
              @paypal-create-order="clearPaypalError"
              @paypal-error="setPaypalError"
              @apple-pay-error="setApplePayError"
              @google-pay-error="setGooglePayError"
              @submit="submit"
            />
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import Tender from './Tender.vue'
import ContactInfo from './ContactInfo.vue'
import TenderClass from '../store/tender.js'
import CardInfo from './CardInfo.vue'
import BankInfo from './BankInfo.vue'
import MobilePayInfo from './MobilePayInfo.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import scrollToMixin from '@grantstreet/psc-vue/utils/scrollToMixin.js'
import PaymentRadioCard from '@grantstreet/psc-vue/components/PaymentRadioCard.vue'
import SectionHeading from './SectionHeading.vue'
import '@grantstreet/bootstrap/icons/js/check-circle.js'
import '@grantstreet/bootstrap/icons/js/alert-exclamation-triangle.js'
import '@grantstreet/bootstrap/icons/js/x.js'
import '@grantstreet/bootstrap/icons/js/apple-pay-mark.js'
import '@grantstreet/bootstrap/icons/js/google-pay-mark.js'
import getBrandImage from '../card-brands.js'
import { translateList } from '@grantstreet/psc-vue/utils/i18n.ts'
import { isCardNumber } from '@grantstreet/psc-js/utils/cards.js'
import { withTimeout, isTimeout } from '@grantstreet/psc-js/utils/timeout.js'
import EWalletProgressButton from './EWalletProgressButton.vue'
import ConvenienceFee from './ConvenienceFee.vue'
import securePaymentImageEn from '../assets/images/secure-payment-en.png'
import securePaymentImageEs from '../assets/images/secure-payment-es.png'
import digicertSecuredImage from '../assets/images/digicert-secured.png'
import { sentryException } from '../../sentry.js'
import { sentryCodes } from '@grantstreet/sentry'
import { decimalFormat, parseNumber, amountIsNonzero } from '@grantstreet/psc-js/utils/numbers.js'
import { redactProps } from '@grantstreet/psc-js/utils/objects.ts'
import { formatDate } from '@grantstreet/psc-js/utils/date.js'
import PolicyAgreementCheckbox from './PolicyAgreementCheckbox.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import FeeConflictModal from './FeeConflictModal.vue'
import { UAParser } from 'ua-parser-js'
import { asyncWithValidator } from '@grantstreet/schema-validation'
import {
  getTenderSchema,
  generateExtraFieldsSchema,
  paypalSuccessSchema,
} from '../external-api-spec.ts'
import { z } from 'zod'

export default {
  emits: [
    'tenderFormTypeChanged',
    'tenderTypeChanged',
    'selectedDataChanged',
    'extraFieldsUpdate',
    'validationError',
    'phoneUpdate',
    'contactPreferenceUpdate',
  ],
  components: {
    Tender,
    CardInfo,
    ContactInfo,
    BankInfo,
    PaymentRadioCard,
    SectionHeading,
    Alert,
    EWalletProgressButton,
    ConvenienceFee,
    PolicyAgreementCheckbox,
    MobilePayInfo,
    FeeConflictModal,
  },

  mixins: [
    scrollToMixin,
  ],

  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    extraFields: {
      type: Object,
      required: true,
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
    scrollContainer: {
      type: String,
      required: false,
      // This is intentional. It means that default params will work correctly.
      default: undefined,
    },
    isManagementView: {
      type: Boolean,
      required: true,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    alwaysGetWarehouseToken: {
      type: Boolean,
      required: true,
    },
    alwaysGetWarehouseTokenForBanks: {
      type: Boolean,
      required: true,
    },
    alwaysSaveTender: {
      type: Boolean,
      required: true,
    },
    showOneTimeUseDisclosure: {
      type: Boolean,
      required: true,
    },
    autoPaySelected: {
      type: Boolean,
      required: true,
    },
    convenienceFees: {
      type: Object,
      required: true,
    },
    translatedFeeKeys: {
      type: Array,
      default: () => ([]),
    },
    needsBillingAddress: {
      type: Boolean,
      required: true,
    },
    showFeeCheckbox: {
      type: Boolean,
      required: true,
    },
    showExtraFields: {
      type: Boolean,
      default: false,
    },
    debitSendingIds: {
      type: Array,
      required: true,
    },
    // This prop is ignored if firstMethod == "bank"
    preExpandCardForm: {
      type: Boolean,
      required: true,
    },
    /**
     * allowedMethods is all of the payment methods that are allowed on this
     * site. This may include methods that cannot be used to perform the
     * specific action we need to pick a tender for.
     */
    allowedMethods: {
      type: Array,
      required: true,
    },
    /**
     * restrictedMethods are payment methods that cannot be used to
     * perform this specific action, even if they would otherwise be
     * allowed on the site. The user will be shown information from the
     * disabledTendersTooltips, if available.
     */
    restrictedMethods: {
      type: Array,
      required: true,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    dataVaultToken: {
      type: String,
      required: true,
    },
    // May be the tender id or an ewallet token
    initialTenderId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    showSaveCheckbox: {
      type: Boolean,
      default: true,
    },
    lastNameRequired: {
      type: Boolean,
      default: false,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    adminFilling: {
      type: Boolean,
      default: false,
    },
    maxReturnFee: {
      type: String,
      default: '',
    },
    paypalConfigProp: {
      type: Object,
      default: null,
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
    firstMethod: {
      type: String,
      default: '',
    },
    hideTenders: {
      type: Array,
      default: () => [],
    },
    // A static warning message to display at the top of the tender manager
    // These are calculated based on cart contents
    disabledTendersMessage: {
      type: String,
      default: '',
    },
    /**
     * disabledTendersTooltips is a map of tender type to a string
     * describing why the tender is disabled. This is shown to the user
     * when attempting to select the disabled tender.
     */
    disabledTendersTooltips: {
      type: Object,
      default: () => ({}),
    },
    isTwoPassAuthorization: {
      type: Boolean,
      default: false,
    },
    disabledCardBrands: {
      type: Array,
      default: () => ([]),
    },
    enableSms: {
      type: Boolean,
      required: true,
    },
    cartSubtotal: {
      type: [String, Number],
      default: '',
    },
    clientTitle: {
      type: String,
      default: '',
    },
    noSubmitButton: {
      type: Boolean,
      default: false,
    },
    beforeSubmitProp: {
      type: Function,
      default: null,
    },
    // async callback, awaited just before submitCallbackProp can be called
    getUpdatedFeeProp: {
      type: Function,
      default: null,
    },
    // async callback, awaited just before inputs are re-enabled
    submitCallbackProp: {
      type: Function,
      default: null,
    },
    useExpiringTenders: {
      type: Boolean,
      default: false,
    },
    applePayConfig: {
      type: Object,
      default: null,
    },
    googlePayConfig: {
      type: Object,
      default: null,
    },
    submitButtonText: {
      type: String,
      default: null,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      required: true,
    },
    isMixedRexCart: {
      type: Boolean,
      default: false,
    },
    hideFeeMessage: {
      type: Boolean,
      default: false,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
    forceMultiUseTender: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      selectedOption: '',
      canSelect: true,
      cardData: {},
      bankData: {},
      extraFieldsData: {},
      messages: [],
      cardError: '',
      showCardError: false,
      cardErrorCallback: null,
      bankError: '',
      showBankError: false,
      paypalError: '',
      applePayError: '',
      googlePayError: '',
      showingRemoveConfirmation: null,
      initialTender: this.initialTenderId
        ? this.$store.getters['eWallet/findTenderById'](this.initialTenderId)
        : null,
      convenienceFeeAgreement: false,
      feeValid: true,
      tenderValid: true,
      policyAgreementValid: true,
      extraFieldsValid: true,
      cardNumberHintPromise: undefined,
      cardExpirationHintPromise: undefined,
      cardCscHintPromise: undefined,
      applePayTokenPromise: undefined,
      googlePayTokenPromise: undefined,
      paypalData: {},
      venmoData: {},
      digicertSecuredImage,
      applePayData: {},
      googlePayData: {},
      // Used only when there's a conflict between this.convenienceFee and the
      // final fee returned from the parent app
      confirmedFinalFee: '',
      // Used when interrupting the submission process
      resolveFeeConflictPromise: () => {},
      rejectFeeConflictPromise: () => {},
      // Used to force-reload the tender forms when the user exits the
      // submission process
      tenderFormsReload: 0,
      beforeSubmit: null,
      getUpdatedFee: null,
      paypalConfig: null,
      submitCallback: null,
      shouldCollapseError: true,
      isErrorCollapsed: true,
    }
  },

  computed: {

    buttonText () {
      if (this.selectedTenderFormType === 'venmo') {
        return this.$t('venmo.friendly_name.default')
      }
      if (this.selectedTenderFormType === 'paypal') {
        return this.$t('paypal.friendly_name.default')
      }
      if (this.selectedTenderFormType === 'apple') {
        return this.$t('apple.friendly_name.default')
      }
      if (this.selectedTenderFormType === 'google') {
        return this.$t('google.friendly_name.default')
      }

      return this.submitButtonText || this.$t('submit_button')
    },

    showBank () {
      return this.selectedOption === 'bank'
    },

    showCard () {
      return this.selectedOption === 'card'
    },

    showVenmo () {
      return this.selectedOption === 'venmo'
    },

    showPayPal () {
      return this.selectedOption === 'paypal'
    },

    showApplePay () {
      return this.selectedOption === 'apple'
    },

    showGooglePay () {
      return this.selectedOption === 'google'
    },

    // Orders bank form above card
    // This will cause preExpandCardForm to be ignored
    bankFirst () {
      if (this.firstMethod === 'bank') {
        return true
      }
      return false
    },

    usingNewMethod () {
      return this.selectedOption === 'card' ||
        this.selectedOption === 'bank' ||
        this.selectedOption === 'apple' ||
        this.selectedOption === 'google'
    },

    updatingSavedTender () {
      return !this.usingNewMethod
    },

    securePaymentImage () {
      return this.$i18n.locale === 'es' ? securePaymentImageEs : securePaymentImageEn
    },

    shouldAuthorizeTender () {
      // Parent App requested it always to be authorized
      // (note the default is true here, for backwards compatibility)
      if (this.alwaysGetWarehouseToken && this.selectedOption === 'card') {
        return true
      }

      if (this.alwaysGetWarehouseTokenForBanks && this.selectedOption === 'bank') {
        return true
      }

      // We might reconsider this in the future
      // Right now these imply a logged in user
      // and we always allow logged in users access to immediate $0 auth feedback
      return this.selectedFormData?.displayInWallet || this.updatingSavedTender
    },

    shouldSaveTender () {
      return this.alwaysSaveTender
    },

    shouldShowNonRefundableFeeBanner () {
      // PSC-18976 We want to show a banner to users if they are using amex and their cart is over 100k
      // because their fees are not refundable
      const cartSubtotalValue = parseNumber(this.cartSubtotal || 0)
      return this.cardData.cardBrand === 'American Express' && cartSubtotalValue >= parseNumber('100000.00' || 0)
    },

    selectedFormData () {
      switch (this.selectedOption) {
      case 'card': return this.cardData
      case 'bank': return this.bankData
      case 'paypal': return this.paypalData
      case 'venmo': return this.venmoData
      case 'apple': return this.applePayData
      case 'google': return this.googlePayData
      default: return null
      }
    },

    selectedTenderData () {
      const tender = this.selectedFormData || this.filteredSavedTenders.find(({ tenderId }) => tenderId === this.selectedOption)

      // If the tender doesn't have a source, populate it with null, this would
      // indicate a saved tender as source isn't stored in DB
      if (tender != null && tender?.source === undefined) tender.source = null
      return tender
    },

    addMethodHeading () {
      let heading
      if (this.isManagementView) {
        heading = 'add_method'
      }
      else if (this.hasSavedTenders) {
        heading = 'use_different_method'
      }
      else if (this.onlyOnePaymentOption(this.enabledMethods)) {
        heading = 'enter_details'
      }
      else {
        heading = 'select_method'
      }
      return this.$t(`tender_manager.${heading}`)
    },

    // The distinction here is key. Forms create tenders and both 'apple' and
    // 'google' are options
    selectedTenderFormType () {
      return this.formTypeForOption(this.selectedOption)
    },

    // MobilePayInfo creates 'card' tenders and in some places the distinction
    // matters
    // Keep this in sync with GH Checkout
    prospectiveTenderType () {
      return this.formToTender(this.selectedTenderFormType)
    },

    showRadioButtons () {
      return this.isSelectionView && !this.onlyOnePaymentOption(this.enabledMethods)
    },

    shouldShowCheckbox () {
      return Boolean(this.convenienceFee) && this.showFeeCheckbox && !this.hideFeeMessage
    },

    convenienceFee () {
      const fees = this.convenienceFees
      if (!fees) {
        return
      }

      return fees[this.prospectiveTenderType]
    },

    hasFee () {
      return amountIsNonzero(this.convenienceFee)
    },

    debitFilterAdvisory () {
      return this.debitSendingIds?.length
        ? this.$t('bank.advisory_checkout', { ids: translateList(this.debitSendingIds) })
        : ''
    },

    showFilterAdvisory () {
      return !this.useAltDebitFilterAdvisory &&
        this.debitSendingIds.length &&
        this.selectedTenderFormType === 'bank' &&
        this.selectedTenderData.bankAccountCategory === 'commercial'
    },

    filteredSavedTenders () {
      if (!this.hideTenders || !this.hideTenders.length) {
        return this.savedTenders
      }

      return this.savedTenders.filter(({ token, ewalletToken }) => !this.hideTenders.includes(ewalletToken) && !this.hideTenders.includes(token))
    },

    canUseVenmo () {
      const uaInfo = new UAParser().getResult()
      // Usable if:
      //
      // useVenmo and
      return this.paypalConfig.useVenmo && (
        // a non-mobile device
        uaInfo.device.type !== 'mobile' ||
        (
          // or an IOS device using safari
          (uaInfo.os.name === 'iOS' && /safari/i.test(uaInfo.browser.name)) ||
          // or an Android device using chrome
          (/android/i.test(uaInfo.os.name) && /chrome/i.test(uaInfo.browser.name))
          // This feels pretty restrictive but it's what their documentation lists
          // https://developer.paypal.com/docs/checkout/pay-with-venmo/#:~:text=Payers%20on%20mobile%20devices%20must%20use%20Safari%20on%20iOS%20or%20Chrome%20on%20Android.

        )
      )
    },

    enabledMethods () {
      const enabled = this.allowedMethods.filter(method => !this.restrictedMethods.includes(method))
      if (!enabled) {
        sentryException(new Error('Ewallet Error: No intersection between allowed methods and restricted methods'))
      }

      // When PayPal as a tender is included, we also want to enable venmo.
      if (enabled.includes('paypal')) {
        enabled.push('venmo')
      }

      return enabled
    },

    extraFieldsForContactInfo () {
      const extraFields = { ...this.extraFields }
      if (['apple', 'google'].includes(this.selectedTenderFormType) && !this.loggedIn) {
        // email and phone input fields are hidden for anonymous users checking
        // out via apple or google pay. eWallet.vue sets the phone & email as
        // required fields. We need to update that accordingly for
        // ContactInfo.vue
        if (extraFields.phone) {
          extraFields.phone.required = false
        }
        if (extraFields.email) {
          extraFields.email.required = false
        }
      }
      return extraFields
    },

    policyText () {
      // This is the translation that can be injected from site settings
      const policyTextTranslation = `policy_agreement.${this.prospectiveTenderType}_policy`

      let configuredText
      // For some reason this.$te will fail here if called without a locale
      if (this.$te(policyTextTranslation)) {
        configuredText = this.$t(policyTextTranslation)
      }

      if (this.prospectiveTenderType === 'card' || this.prospectiveTenderType === 'paypal') {
        return configuredText
      }

      // For bank payments always show the checkbox with at least the directpay
      // terms. If additional text is added, insert it.
      let additionalPolicyText = configuredText ? `${configuredText}<br><br>` : ''

      if (this.$te('ach_authorization_text')) {
        additionalPolicyText = `${this.$t('ach_authorization_text')}<br></br>${additionalPolicyText}`
      }
      else if (this.isTwoPassAuthorization && (this.clientTitle && this.cartSubtotal)) {
        // case: a two pass authorization e-check county: keep the cart subtotal and convenience fee seperate in the statement.
        const cartSubtotalValue = parseNumber(this.cartSubtotal || 0)
        const convenienceFeeValue = parseNumber(this.convenienceFee || 0)

        const echeckConfirmAchAuthorization = this.$t('echeck_confirm.two_pass_ach_authorization', {
          clientTitle: this.clientTitle,
          cartSubtotalValue: `$${decimalFormat(cartSubtotalValue)}`,
          convenienceFeeValue: `$${decimalFormat(convenienceFeeValue)}`,
          // Use JWT date to avoid reliance on user system time.
          currentDate: formatDate(new Date(this.jwt.iat * 1000), false, this.$i18n.locale),
        })

        additionalPolicyText = `${echeckConfirmAchAuthorization}<br><br>${additionalPolicyText}`
      }
      else if (this.clientTitle && this.cartSubtotal) {
        // case: a one pass authorization e-check county: combine cart subtotal and convenience fee in the statement.
        const cartSubtotalPlusFee = parseNumber(this.cartSubtotal || 0) + parseNumber(this.convenienceFee || 0)

        const echeckConfirmAchAuthorization = this.$t('echeck_confirm.ach_authorization', {
          clientTitle: this.clientTitle,
          cartSubtotalPlusFee: `$${decimalFormat(cartSubtotalPlusFee)}`,
          // Use JWT date to avoid reliance on user system time.
          currentDate: formatDate(new Date(this.jwt.iat * 1000), false, this.$i18n.locale),
        })

        additionalPolicyText = `${echeckConfirmAchAuthorization}<br><br>${additionalPolicyText}`
      }

      const dateOptions = { month: 'long', year: 'numeric', day: 'numeric' }
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      additionalPolicyText = `${this.$t('echeck_confirm.debit_post', {
        date: tomorrow.toLocaleDateString(undefined, dateOptions),
      })}<br><br>${additionalPolicyText}`

      configuredText = this.$t(`policy_agreement.direct_pay.policy_text${this.maxReturnFee ? '_max' : ''}`, {
        additionalPolicyText,
        max: this.maxReturnFee,
      })

      if (this.selectedTenderData && this.selectedTenderData.bankRoutingNumber && (this.selectedTenderData.lastDigits || this.selectedTenderData.bankAccountNumber)) {
        configuredText += this.$t('policy_agreement.direct_pay.policy_text_bank_details', {
          routingNumber: this.selectedTenderData.bankRoutingNumber,
          accountNumber: this.selectedTenderData.lastDigits || (this.selectedTenderData.bankAccountNumber || '').slice(-2),
        })
      }
      else {
        configuredText += '.'
      }

      return configuredText
    },

    redactedPolicyText () {
      return this.policyText.replace(new RegExp(this.$t('policy_agreement.direct_pay.policy_text_bank_details', {
        routingNumber: '[\\d]+',
        accountNumber: '[\\d]+',
      })), this.$t('policy_agreement.direct_pay.policy_text_bank_details', {
        routingNumber: 'XXXX',
        accountNumber: 'XXXX',
      }))
    },

    truncatedBankError () {
      const truncationLength = 200
      return this.bankError.length > truncationLength ? this.bankError.slice(0, truncationLength) + '...' : this.bankError
    },

    ...mapGetters('eWallet', [
      'hasSavedTenders',
      'savedTenders',
      'lastUsedTender',
      'environment',
      'loggedIn',
      'isAllowedType',
      'oneTenderMethod',
      'onlyOnePaymentOption',
      'jwt',
      'sessionId',
    ]),

    ...mapState('eWallet', [
      'useAltDebitFilterAdvisory',
    ]),
  },

  watch: {
    selectedOption: function (newOption, oldOption) {
      let oldType = this.formTypeForOption(oldOption)
      let newType = this.formTypeForOption(newOption)

      if (newType === oldType) {
        return
      }

      this.clearFeeCheckbox()
      this.$gtag.event('E-Wallet', {
        'event_category': 'E-Wallet',
        'event_label': 'Select Method',
        value: (this.usingNewMethod ? 'New ' : 'Saved ') + newType,
      })
      this.$emit('tenderFormTypeChanged', newType)

      // Here we see the distinction between form type and tender type caused by
      // use Apple/Google Pay
      oldType = this.formToTender(oldType)
      newType = this.formToTender(newType)
      if (newType === oldType) {
        return
      }
      this.$emit('tenderTypeChanged', newType)
    },

    extraFields () {
      this.setExtraFieldsData()
    },

    defaultBillingAddress () {
      this.applyBillingData(this.cardData, true)
      this.applyBillingData(this.bankData, this.needsBillingAddress)
      this.applyBillingData(this.paypalData, false)
      this.applyBillingData(this.venmoData, false)
      this.applyBillingData(this.applePayData, true)
      this.applyBillingData(this.googlePayData, true)
    },

    selectedTenderData: {
      handler: function (val) {
        this.$emit('selectedDataChanged', val)
      },
      deep: true,
    },

    enabledMethods: function (newMethods) {
      if (!newMethods.includes(this.selectedTenderFormType)) {
        return this.selectDefaultTender()
      }
    },
    selectedTenderFormType (type) {
      if (this.beforeSubmitProp) {
        this.beforeSubmit = asyncWithValidator(
          this.beforeSubmitProp,
          true,
          [z.object({
            tender: getTenderSchema(this.prospectiveTenderType),
            extraFields: generateExtraFieldsSchema(this.extraFields),
          })],
          false,
          sentryException,
        )
      }
      if (this.submitCallbackProp) {
        this.submitCallback = asyncWithValidator(
          this.submitCallbackProp,
          true,
          [z.object({
            tender: getTenderSchema(this.prospectiveTenderType),
            extraFields: generateExtraFieldsSchema(this.extraFields),
          })],
          false,
          sentryException,
        )
      }
      if (this.getUpdatedFeeProp) {
        this.getUpdatedFee = asyncWithValidator(
          this.getUpdatedFeeProp,
          true,
          [getTenderSchema(this.prospectiveTenderType)],
          false,
          sentryException,
        )
      }
    },
  },

  created () {
    this.setPaypalConfig()
    this.setAllData()
    EventBus.$emit('ewallet.tenderMgr.includeContact', this.shouldIncludeContact())
    EventBus.$emit('ewallet.tenderMgr.showCheckbox', this.shouldShowCheckbox)
  },

  methods: {
    setPaypalConfig () {
      if (this.paypalConfigProp) {
        this.paypalConfig = { ...this.paypalConfigProp }
        // These appear to be the only two PayPal config methods we actually
        // need to validate parameters for since it's data we send back.
        // The rest either have PayPal data as arguments (which we should
        // validate in the future) or have no arguments.
        if (this.paypalConfig.createOrder) {
          const extraFields = { ...this.extraFields }
          // When a user is anonymous phone and email are
          // received from PayPal when the order is approved.
          if (!this.loggedIn) {
            if (extraFields.phone) {
              extraFields.phone.required = false
            }
            if (extraFields.email) {
              extraFields.email.required = false
            }
          }
          this.paypalConfig.createOrder = asyncWithValidator(
            this.paypalConfig.createOrder,
            true,
            // The first two arguments (data and actions) are passed by PayPal.
            // TODO Validate all arguments.
            [z.object({}), z.object({}), getTenderSchema('paypal'), generateExtraFieldsSchema(extraFields)],
            false,
            sentryException,
          )
        }
        if (this.paypalConfig.success) {
          this.paypalConfig.success = asyncWithValidator(
            this.paypalConfig.success,
            true,
            [paypalSuccessSchema],
            false,
            sentryException,
          )
        }
      }
    },
    formToTender (formType) {
      const exceptions = {
        apple: 'card',
        google: 'card',
        venmo: 'paypal',
      }
      return exceptions[formType] || formType
    },

    setCardData () {
      this.cardData = {}
      this.cardData.displayInWallet = this.loggedIn && !this.onlyAnonymousTenders
      this.cardData.type = 'card'
      this.cardData.source = 'direct'

      this.applyBillingData(this.cardData, true)
    },

    setBankData () {
      this.bankData = {}
      this.bankData.displayInWallet = this.loggedIn && !this.onlyAnonymousTenders
      this.bankData.type = 'bank'
      this.bankData.bankAccountType = 'checking'
      this.bankData.bankAccountCategory = ''
      this.bankData.source = 'direct'

      this.applyBillingData(this.bankData, this.needsBillingAddress)
    },

    setPaypalData () {
      this.paypalData = {}
      this.paypalData.type = 'paypal'
      this.paypalData.source = 'direct'
      this.applyBillingData(this.paypalData, false)
    },

    setVenmoData () {
      this.venmoData = {}
      this.venmoData.type = 'paypal'
      this.venmoData.source = 'venmo'
      this.applyBillingData(this.venmoData, false)
    },

    // Apple Pay and Google Pay have displayInWallet to false because
    // users should not be able to save them to their account
    setAppleData () {
      this.applePayData = {}
      // Apple Pay is just another method to collecting card data. The created
      // tender will be a card
      this.applePayData.type = 'card'
      this.applePayData.source = 'apple'
      this.applePayData.displayInWallet = false
      this.applyBillingData(this.applePayData, true)
    },

    setGoogleData () {
      this.googlePayData = {}
      // Google Pay operates the same as a card tender
      this.googlePayData.type = 'card'
      this.googlePayData.source = 'google'
      this.googlePayData.displayInWallet = false
      this.applyBillingData(this.googlePayData, true)
    },

    applyBillingData (data, needsBillingAddress) {
      const address = this.defaultBillingAddress || {}
      data.userName = address.userName

      if (needsBillingAddress) {
        data.billingAddress = {
          ...address,
          country: address.country || 'US',
          // This handles converting '' to null . The default select item has a
          // value of null and we can't rely on this being passed correctly.
          state: address.state || null,
        }
      }
    },

    setExtraFieldsData () {
      const { email, phone, contactPreference } = this.extraFields

      // Don't override user entered values
      this.extraFieldsData.email = this.extraFieldsData.email || (email ? email.initialValue : email)
      this.extraFieldsData.phone = this.extraFieldsData.phone || (phone ? phone.initialValue : phone)
      this.extraFieldsData.contactPreference = this.extraFieldsData.contactPreference || (contactPreference ? contactPreference.initialValue : contactPreference)

      this.$emit('extraFieldsUpdate', this.extraFieldsData)
    },

    setAllData () {
      this.setCardData()
      this.setBankData()
      this.setPaypalData()
      this.setVenmoData()
      this.setAppleData()
      this.setGoogleData()
      this.selectDefaultTender()
      this.setExtraFieldsData()
    },

    // These setters are needed for the parent to call them
    clearApplePayError () {
      this.applePayError = ''
    },

    setApplePayError (error) {
      this.applePayError = error
      this.scrollTo('#apple-pay-error', { offset: -120, container: this.scrollContainer })
    },

    clearGooglePayError () {
      this.googlePayError = ''
    },

    setGooglePayError (error) {
      this.googlePayError = error
      this.scrollTo('#google-pay-error', { offset: -120, container: this.scrollContainer })
    },

    clearPaypalError () {
      this.paypalError = ''
    },

    setPaypalError (error) {
      // This is how our own validation errors are signaled. We don't
      // display them here, since they're being shown in a tooltip.
      // We also might get a stale paypal button error containing "Document is ready",
      // which we don't need to show

      if (error?.response?.data?.errorCode === 'CartExpired') {
        this.paypalError = this.$t('checkout.session_expired')
      }
      else if (error?.message?.includes('status code 422')) {
        // user tried checking out with a bank
        this.paypalError = this.$t('paypal.error.bank')
      }
      else if (error?.response?.data?.displayMessage?.includes('Payment appears to be a duplicate of a successful charge')) {
        const {
          groups: {
            receiptNumber,
            timeRemaining,
            timeUnit,
          },
        } = error.response.data.displayMessage.match(
          /Receipt Number (?<receiptNumber>[^)]+).+after (?<timeRemaining>[\d]+) (?<timeUnit>[^.]+)/,
        )

        this.paypalError = this.$t('dpv_error.default', {
          receiptNumber,
          timeRemaining,
          timeUnit: this.$t(`dpv_error.${timeUnit}`),
        })
      }
      else if (
        error?.message?.includes('Window closed') ||
        error?.message?.includes('Document is ready') ||
        error?.message === 'Expected an order id to be passed' ||
        error?.message === 'Validation error' ||
        error?.message === 'Expected a vault setup token to be returned from createVaultSetupToken'
      ) {
        // Stale/other errors that don't need to be shown to the user
      }
      else {
        // Show generic error. Actual error will get logged to sentry.
        this.paypalError = this.$t('api.error')
      }

      if (this.paypalError) {
        this.scrollTo('#paypal-error', { offset: -120, container: this.scrollContainer })
      }
    },

    // Really this is either a form type or a tender type but since one set is a
    // subset of the other values we'll call it form type
    formTypeForOption (option) {
      switch (option) {
      case 'bank':
      case 'card':
      case 'paypal':
      case 'apple':
      case 'google':
      case 'venmo':
        return option
      default:
        return this.filteredSavedTenders.find(({ tenderId }) => tenderId === option)?.type || ''
      }
    },

    selectDefaultTender () {
      // If there's a initial tender passed try to use it
      if (
        this.initialTender &&
        this.isEnabledType(this.initialTender.type) &&
        !this.disabledCardBrands.includes(this.initialTender.cardBrand)
      ) {
        this.selectedOption = this.initialTender.tenderId
        return
      }

      // If there are saved tenders pick one
      if (this.hasSavedTenders) {
        if (
          this.lastUsedTender &&
          this.isEnabledType(this.lastUsedTender.type) &&
          !this.disabledCardBrands.includes(this.lastUsedTender.cardBrand)
        ) {
          this.selectedOption = this.lastUsedTender.tenderId
          return
        }
        this.selectFirstEnabledSavedTender()
        return
      }

      // Default to expanding card if its enabled (even if bank is enabled too)
      if (
        this.isEnabledType('card') &&
        this.preExpandCardForm &&
        !this.bankFirst
      ) {
        this.selectedOption = 'card'
        return
      }

      // Next try bank
      if (
        this.isEnabledType('bank') &&
        !this.isEnabledType('card')
      ) {
        this.selectedOption = 'bank'
        return
      }

      // Next try generic
      if (this.enabledMethods.length === 1) {
        this.selectedOption = this.enabledMethods[0]
        return
      }

      this.selectedOption = ''
    },

    // Scroll to the top of whichever form accordion item was just opened, but
    // only if the selected form wasn't on top. This is to fix the problem where
    // the first method form is selected (e.g., card) and the user scrolls down
    // and selects the second method (bank) but when it expands its top is above
    // the viewport.
    scrollToForm () {
      if (this.selectedOption === 'card') {
        // Don't scroll if the new form is at the top and won't move
        return
      }
      // Paypal causes a little lag when it's selected due to the paypal button being created
      // Therefore we need to wait a split second before scrolling to its form
      let timeout = 10
      if (this.selectedOption === 'paypal') {
        timeout = 300
      }
      setTimeout(
        () => this.scrollTo(`#select-${this.selectedOption}`, { offset: -120, container: this.scrollContainer }),
        timeout,
      )
    },

    handleTenderValidationError (displayMessage) {
      const messagePresent = this.messages.find(message => message.text === displayMessage)
      if (!messagePresent) {
        this.messages.push({
          variant: 'danger',
          text: displayMessage,
        })
      }
    },

    handleRemoveTender (tender) {
      this.messages.push({
        variant: 'success',
        text: this.$t('tender.remove_success', { description: tender.name }),
      })

      // They deleted the selected tender
      if (this.selectedOption === tender.tenderId) {
        this.selectFirstEnabledSavedTender()
      }

      this.scrollTo('#status-messages', { offset: -120, container: this.scrollContainer })
    },

    handleUpdateTender (tender) {
      this.messages.push({
        variant: 'success',
        text: this.$t('tender.update_success', { description: tender.name }),
      })
      this.scrollTo('#status-messages', { offset: -120, container: this.scrollContainer })
    },

    handleSelectDigitalTender (tender) {
      if (this.canSelect) {
        this.selectedOption = tender

        this.$gtag.event(
          // Log that the user viewed the tender to Google Analytics
          tender + ' tender selected',
          {
            'event_category': 'E-Wallet',
          },
        )
      }
    },

    selectFirstEnabledSavedTender () {
      if (this.hasSavedTenders) {
        const enabled = this.filteredSavedTenders.filter(tender =>
          this.isEnabledType(tender.type) &&
          !this.disabledCardBrands.includes(tender.cardBrand),
        )
        if (enabled.length) {
          this.selectedOption = enabled[0].tenderId
          return
        }
      }

      this.selectedOption = ''
    },

    scrollToFirstError () {
      if (!this.tenderValid) {
        // Scroll to the first error input in a displayed form. If we don't have
        // this '.show' class, this will try to scroll to the first error in the
        // DOM, which might be hidden, resulting in scrolling to the top.
        this.scrollTo('.payment-card .show .is-invalid ~ .invalid-tooltip', { offset: -120, container: this.scrollContainer })
        if (this.showCard) {
          this.$refs.cardInfo.focusOnError()
        }
        else if (this.showBank) {
          this.$refs.bankInfo.focusOnError()
        }
      }
      else if (!this.feeValid) {
        this.scrollTo('.fee-notice .invalid-tooltip', { offset: -120, container: this.scrollContainer })
        this.$refs.convenienceFee.focusOnError()
      }
      else if (!this.extraFieldsValid) {
        this.scrollTo('.contact-info .is-invalid ~ .invalid-tooltip', { offset: -120, container: this.scrollContainer })
        this.$refs.extraFields.focusOnError()
      }
      else if (!this.policyAgreementValid) {
        this.scrollTo('.policy-agreement-checkbox .invalid-tooltip', { offset: -120, container: this.scrollContainer })
        this.$refs.policyAgreement.focusOnError()
      }
    },

    showError (str, callback) {
      if (this.showCard) {
        this.cardError = str
        this.showCardError = true
        if (callback) {
          this.cardErrorCallback = callback
        }
        this.scrollTo('#card-error', { offset: -120, container: this.scrollContainer })
      }
      else if (this.showBank) {
        this.bankError = str
        this.showBankError = true
        this.scrollTo('#bank-error', { offset: -120, container: this.scrollContainer })
      }
      else if (this.showApplePay) {
        this.applePayError = str
        this.showApplePayError = true
        this.scrollTo('#apple-pay-error', { offset: -120, container: this.scrollContainer })
      }
    },

    setCardError (error) {
      this.selectedOption = 'card'
      this.cardError = error
      this.showCardError = true
      this.scrollTo('#card-error', { offset: -120, container: this.scrollContainer })
    },

    clearCardError () {
      this.cardError = ''
      this.showCardError = false
      this.cardErrorCallback = null
    },

    checkIfCardNumber (value, callback) {
      if (isCardNumber(value)) {
        this.cardErrorCallback = callback
        this.cardError = this.$t('card.wrong.input.error')
        this.showCardError = true
        this.scrollTo('#card-radio-card-header', { offset: -120, container: this.scrollContainer })
      }
    },

    setBankError (error) {
      this.selectedOption = 'bank'
      this.bankError = error
      this.showBankError = true
      this.scrollTo('#bank-error', { offset: -120, container: this.scrollContainer })
    },

    clearBankError () {
      this.bankError = ''
      this.showBankError = false
    },

    getBrandImage,

    shouldIncludeContact () {
      const phone = this.extraFields.phone
      const email = this.extraFields.email
      return phone && email && (!this.isManagementView) && (!this.isSavedTender || (phone.showForSavedTenders && email.showForSavedTenders))
    },

    resetTenderForms () {
      this.selectDefaultTender()
      this.setCardData()
      this.$refs.cardInfo?.initialize?.()
      this.setBankData()
      this.$refs.bankInfo?.initialize?.()
      this.setPaypalData()
      this.setVenmoData()
      this.setAppleData()
      this.$refs.applePayInfo?.initialize?.()
      this.setGoogleData()
      this.$refs.googlePayInfo?.initialize?.()
    },

    disableInputs () {
      this.$refs.cardInfo?.disableInputs()
      this.$refs.applePayInfo?.disableInput()
      this.$refs.googlePayInfo?.disableInput()
    },

    enableInputs () {
      this.$refs.cardInfo?.enableInputs()
      this.$refs.applePayInfo?.enableInput()
      this.$refs.googlePayInfo?.enableInput()
    },

    async saveTender (tender) {
      try {
        tender = await this.$store.dispatch('eWallet/saveNewTender', {
          input: tender,
          needsBillingAddress: tender.type === 'card' || this.needsBillingAddress,
          isManagementView: this.isManagementView,
          useExpiringTenders: this.useExpiringTenders,
          forceMultiUseTender: this.forceMultiUseTender,
        })

        // XXX: After UX-115, this breaks the unit tests for some obscure reason
        // this.selectedOption = ''

        // We don't collapse/init the form in the selection view, otherwise
        // the form will collapse while the user waits for the parent app to
        // redirect them to the next page, which is confusing.
        if (this.isManagementView) {
          this.selectDefaultTender()

          if (tender.type === 'card') {
            this.setCardData()
            Vue.nextTick(this.$refs.cardInfo.initialize)
          }
          else {
            this.setBankData()
            Vue.nextTick(this.$refs.bankInfo.initialize)
          }
        }
        // Make sure the ewallet token is passed on
        this.selectedTenderData.ewalletToken = tender.ewalletToken

        return tender
      }
      catch (error) {
        // This is to help diagnose PSC-10239
        // Allow an error to be logged a second time so that we can clearly see
        // if it's related to 10239
        error.alreadyLogged = false
        sentryException(error, { code: sentryCodes.E_WALLET_RETRY })
        this.logDiagnostics({
          message: `Error saving tender. Potential eWallet Retry Error implications (PSC-10239): ${error.toString()}`,
          error: error.toString(),
          stack: error.stack,
          // TODO: We should add some standard props to always redact inside
          // logDiagnostics
          tender: redactProps(tender, 'bankAccountNumber', '***redacted***'),
        })
        //

        const { errorMessage, displayMessage } = error?.response?.data || {}
        this.showError(displayMessage || errorMessage || this.$t('api.error'))

        // TODO: We shouldn't just allow the user to retry regardless of error
        this.releaseSubmitLock()
        throw error
      }
      finally {
        this.$store.dispatch('wait/end', 'updating tender', { root: true })
      }
    },

    async saveUnauthorizedTender (tender) {
      try {
        const tenderRequest = {
          input: tender,
          needsBillingAddress: tender.type === 'card' || this.needsBillingAddress,
          useExpiringTenders: this.useExpiringTenders,
        }

        // We never want to display Apple Pay or Google Pay tenders in users'
        // wallets.
        if (['apple', 'google'].includes(tender.source)) {
          tenderRequest.displayInWallet = false
        }

        tender = await this.$store.dispatch('eWallet/saveNewUnauthorizedTender', tenderRequest)

        // We don't collapse/init the form in the selection view, otherwise
        // the form will collapse while the user waits for the parent app to
        // redirect them to the next page, which is confusing.
        if (this.isManagementView) {
          this.selectDefaultTender()

          if (tender.type === 'card') {
            this.setCardData()
            Vue.nextTick(this.$refs.cardInfo.initialize)
          }
          else {
            this.setBankData()
            Vue.nextTick(this.$refs.bankInfo.initialize)
          }
        }

        return tender
      }
      catch (error) {
        sentryException(error)
        this.logDiagnostics({
          message: `Error saving an unauthorized tender. ${error.toString()}`,
          error: error.toString(),
          stack: error.stack,
          // TODO: We should add some standard props to always redact inside
          // logDiagnostics
          tender: redactProps(tender, 'bankAccountNumber', '***redacted***'),
        })

        const { errorMessage, displayMessage } = error?.response?.data || {}
        this.showError(displayMessage || errorMessage || this.$t('api.error'))

        // TODO: We shouldn't just allow the user to retry regardless of error
        this.releaseSubmitLock()
        throw error
      }
      finally {
        this.$store.dispatch('wait/end', 'updating tender', { root: true })
      }
    },

    validate (forSubmission = false) {
      const convenienceFee = this.$refs.convenienceFee
      const policyAgreement = this.$refs.policyAgreement
      const extraFields = this.$refs.extraFields

      this.feeValid = convenienceFee ? convenienceFee.validate() : true
      this.policyAgreementValid = policyAgreement && policyAgreement.validate ? policyAgreement.validate() : true
      this.extraFieldsValid = extraFields ? extraFields.validate() : true

      let valid = false

      if (this.showBank) {
        this.tenderValid = this.$refs.bankInfo.validate()
      }
      else if (this.showCard) {
        this.tenderValid = this.$refs.cardInfo.validate(forSubmission)
      }

      // PayPal and Venmo submission info will always be valid. This is because
      // there is no billing information for PayPal or Venmo.
      else if (this.showPayPal) {
        this.tenderValid = true
      }
      else if (this.showVenmo) {
        this.tenderValid = true
      }
      else if (this.showApplePay) {
        this.tenderValid = this.$refs.applePayInfo.validate(forSubmission)
      }
      else if (this.showGooglePay) {
        this.tenderValid = this.$refs.googlePayInfo.validate(forSubmission)
      }
      else if (this.selectedOption) {
        const tenderRef = this.$refs.tenders.find(({ tender }) => tender.tenderId === this.selectedOption)
        this.tenderValid = tenderRef.validate()
      }

      valid = this.tenderValid && this.feeValid && this.policyAgreementValid && this.extraFieldsValid

      if (!valid) {
        // We wait for Vue to render the error messages, and then fish them out of
        // the DOM. This is (unfortunately) much easier than attempting to get them
        // through Vuelidate.

        Vue.nextTick(() => {
          const errors = document.body.querySelectorAll('#e-wallet .is-invalid ~ .invalid-tooltip')
          this.$emit('validationError',
            Array.from(errors).map(elt => elt.innerText.trim()),
          )
        })
      }

      return valid
    },

    validateWithScroll (forSubmission = false) {
      if (!this.validate(forSubmission)) {
        this.scrollToFirstError()
        return false
      }
      return true
    },

    // Make sure all of the DataVault hints have been retrieved.
    // See: CardInfo.vue@emitHintPromise

    // The timeout is needed because if the user focuses but doesn't
    // modify a field, the hint promise will never resolve. In that
    // case, we spin for 5 seconds before proceeding.
    async awaitCardInputs () {
      try {
        await withTimeout(
          Promise.all([
            this.cardNumberHintPromise,
            this.cardExpirationHintPromise,
            this.cardCscHintPromise,
            this.applePayTokenPromise,
          ]),
          5 * 1000,
        )
      }
      catch (error) {
        if (isTimeout(error)) {
          return
        }
        this.showError(this.$t('api.error'))
        throw error
      }
    },

    async logAchAuthorization () {
      await this.$store.dispatch('eWallet/logDiagnostics', {
        url: window.location.pathname,
        // eslint-disable-next-line
        ach_authorization: this.redactedPolicyText,
      })
    },

    async submit (billingData) {
      if (!this.selectedOption) {
        // EWalletProgressButton should prevent this by being disabled with a tooltip.
        sentryException(new Error('Ewallet Error: Ewallet attempted to submit without a selected tender type. This should never happen.'))
        return
      }
      // Sets the billing address info from Google Pay's or Apple Pay's API back to our tender
      if ((this.selectedTenderFormType === 'google' || this.selectedTenderFormType === 'apple') &&
          billingData) {
        Object.assign(this.selectedFormData, billingData)
      }

      // XXX: PSC-5491 - this needs to be tested/corrected:
      // This can be invoked from outside E-Wallet (e.g. by the Delivery
      // Method interface on the PayHub checkout page. In that case, we
      // don't want to do anything for PayPal transactions.
      if (this.showPayPal || this.showVenmo) {
        return
      }

      this.$store.dispatch('wait/start', 'submitting', { root: true })

      if (this.selectedTenderFormType !== 'bank') {
        await this.awaitCardInputs()
      }

      // Trigger validation errors
      if (!this.validateWithScroll(true)) {
        // (It's okay if inputs aren't locked yet)
        this.releaseSubmitLock()
        return
      }
      const extraFields = this.extraFieldsData
      if (extraFields.phone) {
        this.$emit('phoneUpdate', extraFields.phone)
      }

      if (extraFields.contactPreference) {
        this.$emit('contactPreferenceUpdate', extraFields.contactPreference)
      }

      // If this is the management view and a new tender, don't fully submit.
      if (this.isManagementView && !this.usingNewMethod) {
        // (It's okay if inputs aren't locked yet)
        this.releaseSubmitLock()
        return
      }

      // We may need to move these checks into CardInfo if there are more of
      // them, or if we miss them sometimes. I'm really torn on whether the
      // child should silently handle DV failure, or whether the parent should
      // only ask for DV interaction when necessary.
      if (this.selectedTenderFormType !== 'bank') {
        this.disableInputs()
      }

      // Save/Submit
      if (this.usingNewMethod) {
        let tender = new TenderClass(
          { ...(this.selectedFormData || {}) },
          this.selectedFormData?.type === 'card' || this.needsBillingAddress,
        )

        if (!await this.beginSubmission(tender)) {
          this.releaseSubmitLock()
          return
        }

        const isAppleOrGooglePay = ['apple', 'google'].includes(tender.source)

        // We don't want to save Apple or Google Pay tenders (including
        // authorizations) unless tokenizing them for delayed payments
        if (isAppleOrGooglePay) {
          if (this.shouldSaveTender && this.$store.getters['Cart/cart'].hasDelayedItems) {
            tender = await this.saveUnauthorizedTender(tender)
          }
          else if (this.shouldAuthorizeTender) {
            sentryException(new Error('Ewallet Error: Attempted to authorize Apple or Google Pay tender'))
          }
        }
        else if (this.shouldAuthorizeTender) {
          tender = await this.saveTender(tender)
        }
        else if (this.shouldSaveTender) {
          tender = await this.saveUnauthorizedTender(tender)
        }
        // After saving either an authorized or unauthorized tender, check out
        await this.completeSubmission({ tender, extraFields })
        return
      }

      // They are using a saved method, so we need to trigger the Submit event
      const selected = this.selectedOption
      const tender = this.filteredSavedTenders.find(t => t.tenderId === selected)

      if (!await this.beginSubmission(tender)) {
        this.releaseSubmitLock()
        return
      }

      try {
        await this.$store.dispatch('eWallet/setLastUsedTender', tender)
      }
      // Submit even if we can't set the tender to "last_used"
      finally {
        await this.completeSubmission({ tender, extraFields })
      }
    },

    /**
     * @function beginSubmission
     * Begins the process of attempting the transaction. This is used to split
     * out some common functionality and improve readability.
     * @param  {object} tender The tender being used.
     * @return {boolean}       Returns true for a success, false for failure.
     */
    async beginSubmission (tender) {
      if (tender.type === 'bank') {
        await this.logAchAuthorization()
      }
      try {
        if (await this.beforeSubmit?.({ tender, extraFields: this.extraFieldsData }) === false) {
          return false
        }
      }
      catch (error) {
        if (error.message?.includes('Critical validation failed')) {
          this.showError(this.$t('api.error'))
        }
        return false
      }

      return true
    },

    /**
     * @function completeSubmission
     * Completes the submission process and unlocks eWallet. If getUpdatedFee
     * has been provided then the process can be interrupted for, or canceled
     * by, user interaction.
     * Only actually executes submitCallback when in the selection view (not management)
     * @param  {object} [params]             - A params object.
     * @param  {object} [params.tender]      - The Tender to be used for
     * submission.
     * @param  {object} [params.extraFields] - Extra Fields data to be used for
     * submission.
     */
    async completeSubmission ({ tender, extraFields }) {
      // This is to help diagnose PSC-10239
      if (this.isManagementView || typeof this.submitCallback !== 'function') {
        this.logDiagnostics({
          submissionDiagnostics: {
            submitCallbackType: typeof this.submitCallback,
            submitCallback: this.submitCallback?.toString(),
          },
        })
      }

      const event = this.usingNewMethod
        ? `New ${tender.type}` + (tender.isBank ? ` - ${tender.bankAccountType}` : '')
        : `Saved ${tender.type} - ` + (tender.isBank ? `${tender.bankAccountType} - ` : '') + (tender.isLastUsed ? 'last used' : 'not last used')

      if (this.isManagementView) {
        this.$gtag.event('E-Wallet', {
          'event_category': 'E-Wallet',
          'event_label': 'Check Out',
          value: event,
        })
        this.releaseSubmitLock()
        return
      }

      // TODO: Done in PSC-2757. We should check if teams have normalized
      tender.tenderID = tender.tenderId

      const data = { tender, extraFields }

      // If you need to do some long running task *after* the tender is saved
      // then this is the place
      if (this.getUpdatedFee) {
        const feeResponse = await this.interruptForFeeConfirmation({ tender })

        if (feeResponse === false) {
          // User declined fee interrupt. Exit submission
          if (this.shouldSaveTender || this.shouldAuthorizeTender) {
            this.setAllData()
            this.tenderFormsReload++
          }
          this.releaseSubmitLock()
          return
        }

        if (feeResponse) {
          data.feeResponse = feeResponse
        }
      }
      this.$gtag.event('E-Wallet', {
        'event_category': 'E-Wallet',
        'event_label': 'Check Out',
        value: event,
      })
      try {
        await this.submitCallback?.(data)
      }
      catch (error) {
        if (error.message?.includes('Critical validation failed')) {
          this.showError(this.$t('api.error'))
        }
      }
      this.releaseSubmitLock()
    },

    // Ask the parent application to fetch an updated fee and if necessary
    // interrupt the user
    async interruptForFeeConfirmation ({ tender }) {
      // Get updated fee
      let fee
      let feeResponse
      try {
        ;({ fee, response: feeResponse } = await this.getUpdatedFee(tender) || {})
      }
      catch (error) {
        if (error.message?.includes('Critical validation failed')) {
          this.showError(this.$t('api.error'))
          return false
        }
        sentryException(error)

        const { errorMessage, displayMessage } = error?.response?.data || {}
        this.showError(displayMessage || errorMessage || this.$t('api.error'))

        // TODO: We shouldn't just allow the user to retry regardless of error
        this.releaseSubmitLock()
        throw error
      }

      // getUpdatedFee can return early if there's no need to complete this
      // check
      if (typeof fee !== 'string') {
        return feeResponse
      }

      this.confirmedFinalFee = decimalFormat(fee)

      // Compare final fee to our projected fee
      if (decimalFormat(fee) !== decimalFormat(this.convenienceFee) && !this.useCostLinkedPricing) {
        this.$refs.feeConflictModal.show()

        // This tracks when the international fee modal was opened using Google
        // Analytics so we can track whether a user chooses a different tender
        // for check out. See PSC-19936 for more context.
        this.$store.dispatch('eWallet/setInternationalFeeModalHasOpenedFlag', true)
        this.$gtag.event(
          'E-Wallet international fee modal',
          {
            'event_category': 'E-Wallet',
            'event_label': `Opened fee modal: ${this.sessionId}`,
          },
        )

        // Interrupt submit process while looking for a response
        try {
          await new Promise((resolve, reject) => {
            this.resolveFeeConflictPromise = resolve
            this.rejectFeeConflictPromise = reject
          })
        }
        catch (error) {
          // A catch means that the user canceled the modal and we're
          // exiting the submission process
          return false
        }
        finally {
          this.resolveFeeConflictPromise = () => {}
          this.rejectFeeConflictPromise = () => {}
        }
      }

      return feeResponse
    },

    releaseSubmitLock () {
      if (this.selectedTenderFormType !== 'bank') {
        this.enableInputs()
      }
      this.$store.dispatch('wait/end', 'submitting', { root: true })
    },

    cancelTender () {
      this.canSelect = false
      this.selectedOption = ''
      setTimeout(() => {
        this.canSelect = true
      }, 25)
    },

    openPrivacyPolicy () {
      // TODO: Put translations in translation files
      let anchor = 'privacy'
      if (this.$i18n.locale === 'es') {
        anchor = 'privacidad'
      }
      window.open('https://www.grantstreet.com/legal#' + anchor)
    },

    clearFeeCheckbox () {
      if (this.convenienceFeeAgreement) {
        this.convenienceFeeAgreement = false
      }
    },

    isTenderSelectable (tender) {
      if (this.isManagementView) {
        return true
      }

      return this.isEnabledType(tender.type) &&
        !this.disabledCardBrands.includes(tender.cardBrand)
    },

    isEnabledType (tenderType) {
      return this.enabledMethods.includes(tenderType)
    },

    isRestrictedType (tenderType) {
      return this.restrictedMethods.includes(tenderType)
    },

    ...mapActions('eWallet', [
      'logDiagnostics',
    ]),

    handleToggleErrorCollapse (isErrorCollapsed) {
      this.isErrorCollapsed = isErrorCollapsed
    },
  },
}
</script>

<style lang="scss">

.submit-group {
  flex-direction: column !important;
}

@include media-breakpoint-up(sm) {
  .submit-group {
    flex-direction: row !important;
    margin-right: math.div(-$grid-gutter-width, 2) !important;
  }
}

.secure-payment-img {
  max-width: 133px;
  max-height: 20px;
  min-width: 100px;
  min-height: 15px;
}

.digicert-secured-img {
  max-width: 100px;
  max-height: 36px;
  min-width: 67px;
  min-height: 24px;
}

// The custom pl is to imitate the amount of space an input radio button would
// take, to match the rest of the radio cards
.pl-custom {
  padding-left: 1.875rem !important;
}

.payment-info-footer {
  .custom-control {
    z-index: inherit;
  }
}

</style>
