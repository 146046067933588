<template>
  <b-alert
    :variant="variant"
    show
  >
    <div class="d-flex flex-column flex-md-row align-items-center mb-0 gap">
      <div>
        <div v-dompurify-html="message" />
      </div>

      <!-- Use flex-shrink-0 to prevent wrapping the buttons, which looks odd -->
      <b-button
        v-dompurify-html="actualCancelPrompt"
        :disabled="waiting"
        :variant="`outline-${variant}`"
        class="flex-shrink-0 ml-md-auto w-100 w-md-auto"
        data-test="confirm-alert-cancel"
        @click.stop="$emit('cancel')"
      />
      <ProgressButton
        :waiting="waiting"
        :variant="variant"
        class="flex-shrink-0 w-100 w-md-auto"
        data-test="confirm-alert-continue"
        @click.stop="$emit('continue')"
      >
        <span v-dompurify-html="actualContinuePrompt" />
      </ProgressButton>
    </div>

    <template v-if="errorMessage">
      <hr class="my-3">
      <div
        v-dompurify-html="errorMessage"
        class="font-italic"
      />
    </template>
  </b-alert>
</template>

<script>
/**
 * This component provides an alert-like interface
 * for confirming that a user really wants to take an action.
 * For example, we want users to press a bright red button
 * confirming they want to delete a saved e-wallet tender.
 */
import ProgressButton from './ProgressButton.vue'

export default {
  emits: ['continue', 'cancel'],
  props: {
    /**
     * The message to display to the user
     */
    message: {
      type: String,
      required: true,
    },
    /**
     * An error message to always display (eg. indicating that deletion failed).
     */
    errorMessage: {
      type: String,
      default: '',
    },

    /**
     * Disable buttons and display a waiting spinner
     */
    waiting: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'danger',
    },
    /**
     * The button text indicating "don't proceed"
     */
    cancelPrompt: {
      type: String,
      default: '',
    },
    /**
     * The button text indicating confirmation
     */
    continuePrompt: {
      type: String,
      default: '',
    },
  },

  components: {
    ProgressButton,
  },

  computed: {
    actualCancelPrompt () {
      return this.cancelPrompt || this.$t('common.cancel')
    },

    actualContinuePrompt () {
      return this.continuePrompt || this.$t('continue')
    },
  },
}
</script>

<style lang="scss" scoped>
.gap {
  gap: map-get($spacers, 2);
}
</style>
