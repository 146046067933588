<template>
  <div class="payment-form-footer">
    <b-form-group
      label-cols-md="3"
    >* {{ $t('required.default') }}</b-form-group>

    <b-form-group
      v-if="(loggedIn || supportsLogin) && isSelectionView"
      label-cols-md="3"
    >
      <div
        v-if="showSaveCheckbox"
        :id="'save-tender-checkbox' + _uid"
      >
        <b-form-checkbox
          v-model="paymentData.displayInWallet"
          class="save-method-checkbox mt-2 d-flex align-items-start"
          inline
          :disabled="forceSaveMethod"
          @click="preventToggle"
          @change="updateDisclosure"
        >
          <div
            v-dompurify-html="$t('tender.save.default')"
            class="align-middle"
            :class="{ 'text-body': forceSaveMethod}"
          />
        </b-form-checkbox>
        <b-tooltip
          v-if="forceSaveMethod"
          :target="'save-tender-checkbox' + _uid"
          triggers="hover"
          :title="$t('tender.save.required')"
        />
      </div>
      <strong v-else>{{ $t("tender.alt_save_text") }}</strong>
    </b-form-group>

    <b-form-group
      v-if="shouldShowNickname"
      :label="$t('card.nickname.label')"
      :label-for="'tender-nickname' + _uid"
      label-cols-md="3"
    >
      <b-form-input
        :id="'tender-nickname' + _uid"
        v-model="paymentData.nickname"
        :state="!v$.paymentData.nickname.$error"
        :placeholder="nicknamePlaceholder"
        name="nickname"
        @input="v$.paymentData.nickname.$reset()"
        @blur="updateCardNickname"
      />
      <ValidationErrors
        :validator="v$.paymentData.nickname"
        :errors="{
          maxLength: $t('field_max_length', { len: 255 }),
          nonCard: $t('card.prevent.wrong.input.error'),
        }"
      />
    </b-form-group>

    <slot />

    <!-- Saved Payment Method Disclosures -->
    <template v-if="showOneTimeUseDisclosure || paymentData.displayInWallet">
      <div class="pt-2">
        <p><strong>{{ $t('tender_manager.spm_disclosure.heading') }}</strong></p>
        <p
          v-dompurify-html="disclosure"
          data-test="spm-disclosure"
        />
      </div>
    </template>

    <b-form-row
      v-if="showSaveButtons"
      class="justify-content-end mt-3 px-1"
    >
      <span
        :class="{ 'opacity-0': !$wait.is('updating tender') && !$wait.is('submitting') }"
        class="spinner mr-3 align-middle"
      />
      <button
        :disabled="$wait.is('updating tender') || $wait.is('submitting')"
        class="btn btn-outline-primary mr-2"
        data-test="cancel-button"
        type="button"
        @click="$emit('cancel')"
      >{{ $t('common.cancel') }}</button>
      <button
        :disabled="$wait.is('updating tender') || $wait.is('submitting')"
        class="btn btn-primary"
        data-test="save-button"
        type="button"
        @click="$emit('save')"
      >{{ $t('tender.add.save') }}</button>
    </b-form-row>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'
import { nonCard } from '@grantstreet/psc-js/utils/validators.js'
import { mapGetters, mapState } from 'vuex'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'

export default {
  emits: ['save', 'cancel', 'changeSaveCheckbox'],
  components: {
    ValidationErrors,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    paymentData: {
      type: Object,
      required: true,
    },
    nicknamePlaceholder: {
      type: String,
      required: true,
    },
    showSaveCheckbox: {
      type: Boolean,
      default: false,
    },
    showSaveButtons: {
      type: Boolean,
      default: false,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    showOneTimeUseDisclosure: {
      type: Boolean,
      default: true,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    maxReturnFee: {
      type: String,
      default: '',
    },
    tenderType: {
      type: String,
      required: true,
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      confirmed: false,
    }
  },

  computed: {
    shouldShowNickname () {
      // XXX: Test fails mysteriously if this isn't done in advance
      const displayInWallet = this.paymentData && this.paymentData.displayInWallet

      if (!this.loggedIn || this.onlyAnonymousTenders) {
        return false
      }

      // Show if we're not showing the "Save" checkbox, or if they've checked it
      if (!this.showSaveCheckbox || displayInWallet) {
        return true
      }

      return false
    },

    ...mapState('eWallet', [
      'supportsLogin',
    ]),

    ...mapGetters('eWallet', [
      'loggedIn',
    ]),

    disclosure () {
      if (!this.paymentData) {
        return
      }
      let disclosureKey = 'tender_manager.spm_disclosure'
      disclosureKey += this.paymentData.displayInWallet ? '.saving_tender_to_wallet' : '.saving_one_time_payment'
      return this.$t(disclosureKey)
    },
  },

  mounted () {
    this.initialize()
  },

  updated () {
    if (this.forceSaveMethod) {
      this.paymentData.displayInWallet = this.forceSaveMethod
    }
  },

  validations: {
    paymentData: {
      nickname: {
        maxLength: maxLength(255),
        nonCard,
      },
    },
  },

  methods: {
    initialize () {
      this.v$.$reset()
      this.paymentData.savedPaymentMethodDisclosure = this.disclosure
    },

    validate () {
      this.v$.$touch()

      return !this.v$.$invalid
    },

    updateCardNickname () {
      this.v$.paymentData.nickname.$touch()
    },

    preventToggle (event) {
      if (!this.loggedIn) {
        EventBus.$emit('loginRequired', this.$t('login_required.reason.save_payment'))
        event.preventDefault()
      }
    },

    updateDisclosure (value) {
      this.paymentData.savedPaymentMethodDisclosure = this.disclosure
      this.$emit('changeSaveCheckbox', value)
    },
  },
}
</script>
