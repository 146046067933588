<template>
  <div
    class="position-relative h-100"
    :class="{
      invalid: !allFieldsValid || disabled,
    }"
  >
    <div
      v-if="!loaded"
      class="spinner-container position-absolute fixed-top w-100 h-100 d-flex justify-content-center align-items-center"
    >
      <LoadingSpinner />
    </div>
    <div
      :id="'google-pay-button' + _uid"
      ref="googlePayButton"
      class="google-pay-button w-100 h-100"
      data-test="google-pay-button"
      :class="{ invalid: !allFieldsValid || disabled}"
      @mouseenter="awaitValidation"
    />
    <b-tooltip
      v-if="!allFieldsValid"
      :target="'google-pay-button' + _uid"
      placement="left"
      triggers="hover focus"
      :title="$t('third_party_payment.error.fields_required')"
    />

  </div>
</template>

<script>
import { sentryException } from '../../sentry.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import { GSG_ENVIRONMENT } from '@grantstreet/psc-environment/environment.js'
import { sleep } from '@grantstreet/psc-js/utils/sleep.js'
import LoadingSpinner from '@grantstreet/loaders-vue/LoadingSpinner.vue'

// The google pay client can't be attached to the component data. If it is then
// reactivity does *something* that attempts to access GP iframe data and causes
// checkout to fail. (The onPaymentAuthorized callback never gets called.)
// https://stackoverflow.com/questions/66408206/how-to-prevent-blocked-a-frame-error-when-use-google-pay
let googlePayClient

export default {

  components: {
    LoadingSpinner,
  },

  emits: ['payment-authorized', 'google-pay-error'],

  props: {
    googlePayConfig: {
      type: Object,
      required: true,
    },
    shouldIncludeContact: {
      type: Boolean,
      required: true,
    },
    shouldShowCheckbox: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraFieldsData: {
      type: Object,
      default: () => ({}),
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    const sources = [
      'googlePayData',
      'contactInfo',
      'feeAgreement',
    ]

    return {
      // Init for reactivity
      isValid: sources.reduce((map, source) => {
        map[source] = false
        return map
      }, {}),

      validationPromises: {},
      validationResolvers: {},

      // These don't need reactivity but it's good practice to list things like
      // this
      validationHandlers: sources.reduce((map, source) => {
        map[source] = valid => {
          this.isValid[source] = valid
          this.validationResolvers[source]?.()
          this.validationResolvers[source] = null
        }
        return map
      }, {}),

      authJwt: null,

      loaded: false,
    }
  },

  computed: {
    allFieldsValid () {
      return (this.isValid.contactInfo || !this.shouldIncludeContact || !this.loggedIn) &&
        (this.isValid.feeAgreement || !this.shouldShowCheckbox) && Boolean(this.authJwt)
    },
  },

  mounted () {
    // The page locks for a second when adding this. The timeout allows the
    // user's original feedback and the TenderManager transition to complete
    // before that happens.
    setTimeout(async () => {
      await this.loadScript()
    }, 400)

    // Sets up EventBus listeners
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$on(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )

    // Request initial validation state
    this.getFieldValidation()
    // Fetch token and set up token refreshing
    this.fetchGooglePayJwt()
  },

  beforeUnmount () {
    Object.keys(this.validationHandlers).forEach(source =>
      EventBus.$off(`ewallet.validation.${source}`, this.validationHandlers[source]),
    )

    clearTimeout(this.authJwtInterval)

    googlePayClient = null
  },

  watch: {
    '$i18n.locale' () {
      this.showButton()
    },
  },

  methods: {
    async loadScript () {
      let googleScript = document.querySelector('#google-pay-sdk')
      // If the SDK is already requested then don't duplicate the script tag
      if (googleScript) {
        // If the Google library is available, just use it!
        if (window.google) {
          this.showButton()
          return
        }
        // Otherwise, wait patiently
        googleScript.addEventListener('load', this.showButton)
        return
      }

      googleScript = document.createElement('script')
      googleScript.setAttribute('id', 'google-pay-sdk')
      googleScript.setAttribute('src', '/js/google-pay/google-pay-sdk.js')
      document.head.appendChild(googleScript)
      googleScript.addEventListener('load', this.showButton)
    },

    async showButton () {
      // Need to initialize the Google Pay client first
      if (!googlePayClient) {
        await this.initGooglePay()
      }

      const googlePayButton = googlePayClient.createButton({
        buttonColor: 'default',
        buttonType: 'pay',
        buttonLocale: this.$i18n.locale || 'en',
        buttonSizeMode: 'fill',
        onClick: this.clickHandler,
      })

      // If the button exists already, we need to remove it first
      if (this.$refs.googlePayButton.children.length) {
        while (this.$refs.googlePayButton.firstChild) {
          this.$refs.googlePayButton.removeChild(this.$refs.googlePayButton.firstChild)
        }
      }

      this.$refs.googlePayButton.appendChild(googlePayButton)
      this.loaded = true
    },

    async initGooglePay () {
      googlePayClient = new window.google.payments.api.PaymentsClient({
        environment: GSG_ENVIRONMENT === 'prod' ? 'PRODUCTION' : 'TEST',
        paymentDataCallbacks: {
          onPaymentAuthorized: this.onPaymentAuthorized.bind(this),
        },
      })
      try {
        await googlePayClient.isReadyToPay(this.googlePayConfig)
      }
      catch (error) {
        console.error(error)
      }
    },

    async clickHandler () {
      // Safari will block popups which aren't *directly* triggered by user
      // interaction. This means anything that takes longer than 1s to pop up
      // (tested). Beware of this when doing anything async.

      await Promise.race([
        // Insist on a re-validation. This handles the edge case where all
        // fields were valid, a user invalidates a field, and clicks the button
        // before blurring the field or when already hovering the button. In
        // that case the field likely won't have validated the new input so the
        // button's hover state (with the cursor, not pointer, still in the
        // offending field) should look okay. When the user clicks though
        // they'll get a full error response from the button and the field.
        this.awaitValidation(true),
        // If the validation takes longer than 200ms (this should never happen)
        // then proceed anyway. We'll do a final validation check as a failsafe
        // after the user attempts to pay inside the popup.
        sleep(200),
      ])

      // Something isn't valid!
      if (!this.allFieldsValid) {
        return
      }

      // We're using the client display name as the merchant name here, which
      // will show up in Google Pay's pop-up window
      const merchantName = this.googlePayConfig.clientDisplayName

      const paymentDataRequest = {
        apiVersion: this.googlePayConfig.apiVersion,
        apiVersionMinor: this.googlePayConfig.apiVersionMinor,
        allowedPaymentMethods: this.googlePayConfig.allowedPaymentMethods,
        emailRequired: true,
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: this.googlePayConfig.total,
          totalPriceLabel: this.$t('total.label'),
          currencyCode: 'USD',
          countryCode: 'US',
          displayItems: this.googlePayConfig.displayItems,
        },
        merchantInfo: {
          merchantName,
          merchantOrigin: window?.location?.hostname,
          ...(GSG_ENVIRONMENT === 'prod'
            ? { authJwt: this.authJwt }
            : {}),
          merchantId: GSG_ENVIRONMENT === 'prod'
            ? 'BCR2DN4TVDBNZVKH'
            : '12345678901234567890',
        },
        callbackIntents: [ 'PAYMENT_AUTHORIZATION' ],
      }

      try {
        const promise = googlePayClient.loadPaymentData(paymentDataRequest)
        promise.then(
          () => {},
          (error) => {
            console.error(error)
            if (error.statusCode !== 'CANCELED') {
              this.error(error)
              throw error
            }
          },
        )
      }
      catch (error) {
        console.error(error)
        if (error.statusCode !== 'CANCELED') {
          this.error(error)
          throw error
        }
      }
    },

    async onPaymentAuthorized (paymentData) {
      // Do a final validation check as a failsafe (in case it timed out before)
      await Promise.all(Object.values(this.validationPromises))
      if (!this.allFieldsValid) {
        // Don't finish checkout with invalid data
        return {
          transactionState: 'ERROR',
          error: {
            intent: 'PAYMENT_AUTHORIZATION',
            // TODO: Translate
            message: this.$t('google.review_details'),
            reason: 'PAYMENT_DATA_INVALID',
          },
        }
      }

      try {
        await this.processPayment(paymentData)
        return { transactionState: 'SUCCESS' }
      }
      catch (error) {
        return {
          transactionState: 'ERROR',
          error: {
            intent: 'PAYMENT_AUTHORIZATION',
            message: error,
            reason: 'PAYMENT_DATA_INVALID',
          },
        }
      }
    },

    async processPayment (paymentData) {
      try {
        // Google Pay's API returns the token as stringified JSON.
        // As of PEX-22771, we don't need to do any escaping or
        // parsing of the JSON -- we just send it to DataVault as is.
        const token = paymentData.paymentMethodData.tokenizationData.token

        await this.$store.dispatch('eWallet/storeGooglePayToken', token)

        const addressInfo = paymentData.paymentMethodData.info.billingAddress
        const billingAddress = {
          name: addressInfo.name,
          userName: addressInfo.name,
          address1: addressInfo.address1,
          address2: addressInfo.address2,
          city: addressInfo.locality,
          country: addressInfo.countryCode,
          postalCode: addressInfo.postalCode,
          state: addressInfo.administrativeArea,
        }

        if (addressInfo.address3) {
          billingAddress.address2 += `, ${addressInfo.address3}`
        }

        const billingData = {
          billingAddress,
          userName: addressInfo.name,
        }

        // Changing the contact information when not logged in
        if (!this.loggedIn) {
          this.extraFieldsData.phone = addressInfo.phoneNumber
          this.extraFieldsData.email = paymentData.email
        }

        this.$emit('payment-authorized', billingData)
      }
      catch (error) {
        this.error(error)
        throw new Error(error)
      }
    },

    error (error) {
      sentryException(error)
      this.$emit('google-pay-error', error)
    },

    // TODO: Make this actually request validation from sources
    validate () {
      return this.allFieldsValid
    },

    // Requests current state of field validation. Does not ask respondent to
    // re-validate unless touch: true is passed
    getFieldValidation ({ touch } = {}) {
      EventBus.$emit('ewallet.googlePayValidation', { touch })
    },

    setValidationPromises () {
      const promises = []
      for (const source of Object.keys(this.validationHandlers)) {
        this.validationPromises[source] = new Promise(resolve => {
          this.validationResolvers[source] = resolve
        })
        promises.push(this.validationPromises[source])
      }
      return promises
    },

    async fetchGooglePayJwt () {
      // Refresh 2min before the 60min timeout. This interval will be cleared
      // beforeDestroy
      this.authJwtInterval = setTimeout(this.fetchGooglePayJwt, 58 * 60 * 1000)

      const { data: { token } } = await this.$store.getters['API/ewallet'].validateGooglePayDomain(window?.location?.hostname)
      this.authJwt = token
    },

    awaitValidation (touch) {
      const promises = this.setValidationPromises()
      this.getFieldValidation({ touch })
      return Promise.all(promises)
    },
  },

}
</script>

<style lang="scss" scoped>
.google-pay-button {
  // Class name defined in Google's library
  ::v-deep .gpay-button {
    border-radius: #{$border-radius} !important;
  }
}

// TODO: If this doesn't already exist in our bootstrap, move it there
.pe-none {
  pointer-events: none !important;
}

.invalid:hover {
  opacity: 0.5;
}

.spinner-container {
  background: $black;
  border-radius: 5px;
}

</style>
