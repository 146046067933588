<template>
  <div class="bank-info info-form">

    <b-form-row class="mb-2">
      <b-col
        lg="9"
        xl="8"
      >
        <b-img
          :alt="$t('bank.instructions')"
          :src="eCheckImgSrc"
          fluid
        />
      </b-col>
    </b-form-row>

    <p v-dompurify-html="maxReturnFee ? $t('bank.return_warning_max', { max: maxReturnFee }) : $t('bank.return_warning')" />

    <b-form-group
      :label="$t('bank.routing_number.label')"
      :label-for="'routing-number-' + _uid"
      label-cols-md="3"
      class="required"
    >
      <b-form-input
        :id="'routing-number-' + _uid"
        ref="bankRoutingNumber"
        v-model="bankData.bankRoutingNumber"
        :state="!v$.bankData.bankRoutingNumber.$error"
        name="routing-number"
        type="tel"
        class="monospace"
        aria-describedby="bank-routing-number-validation-errors"
        @input="v$.bankData.bankRoutingNumber.$reset()"
        @blur="updateRoutingNumber"
      />
      <ValidationErrors
        id="bank-routing-number-validation-errors"
        :validator="v$.bankData.bankRoutingNumber"
        :errors="{
          required: $t('bank.routing_number.required'),
          maxLength: $t('field_max_length', { len: 9 }),
          validRoutingNumber: bankError,
        }"
      />
    </b-form-group>

    <b-form-row
      v-if="bankMessage"
      class="mb-3"
      data-test="bank-message"
    >
      <b-col md="3" />
      <b-col md="9">
        <div
          v-dompurify-html="bankMessage"
          class="ml-1 pl-2"
        />
      </b-col>
    </b-form-row>

    <b-form-group
      :label="$t('bank.account_number.label')"
      :label-for="'account-number-' + _uid"
      label-cols-md="3"
      class="required"
    >

      <MaskedInput
        :id="'account-number-' + _uid"
        ref="bankAccountNumber"
        v-model="bankData.bankAccountNumber"
        :state="!v$.bankData.bankAccountNumber.$error"
        name="account-number"
        type="tel"
        aria-describedby="bank-account-number-validation-errors"
        @copy.prevent="$refs.copyMessage.$emit('open')"
        @input="v$.bankData.bankAccountNumber.$reset"
        @blur="blurAccountNumber"
      />
      <ValidationErrors
        id="bank-account-number-validation-errors"
        :validator="v$.bankData.bankAccountNumber"
        :errors="{
          required: $t('bank.account_number.required'),
          notTheSameAsRoutingNumber: $t('bank.account_number.not_same_as_routing_number'),
          isValidAccountNumber: $t('bank.account_number_only_alphanumeric'),
          maxLength: $t('field_max_length', { len: 17 }),
          minLength: $t('field_min_length', { len: 3 }),
        }"
      />
      <b-tooltip
        ref="copyMessage"
        :target="'account-number-' + _uid"
        :title="$t('bank.account_number.copying_not_permitted')"
        triggers="blur"
        container="e-wallet"
      />
    </b-form-group>

    <b-form-group
      :label="$t('bank.account_number_confirmation.label')"
      :label-for="'account-number-confirmation-' + _uid"
      label-cols-md="3"
      class="required"
    >
      <MaskedInput
        :id="'account-number-confirmation-' + _uid"
        ref="accountNumberConfirmation"
        v-model="accountNumberConfirmation"
        :state="!v$.accountNumberConfirmation.$error"
        name="account-number-confirmation"
        type="tel"
        aria-describedby="account-number-confirmation-validation-errors"
        @input="v$.accountNumberConfirmation.$reset"
        @blur="blurAccountNumberConfirmation"
      />
      <ValidationErrors
        id="account-number-confirmation-validation-errors"
        class="account-number-confirmation-errors"
        :validator="v$.accountNumberConfirmation"
        :errors="{
          required: $t('bank.account_number.required'),
          sameAsAccountNumber: $t('bank.account_number_confirmation.invalid'),
        }"
      />
    </b-form-group>

    <b-form-group
      v-slot="{ ariaDescribedby }"
      :label="$t('bank.type.label')"
      label-class="form-radio-label"
      label-cols="3"
    >
      <b-form-radio-group
        v-model="bankData.bankAccountType"
        :aria-describedby="ariaDescribedby"
        :options="typeOptions"
        name="account-type"
      />
    </b-form-group>
    <Alert
      v-if="bankData.bankAccountType === 'savings'"
      variant="warning"
      :show-icon="false"
      :dismissible="true"
      class="mb-3"
    >
      {{ $t('bank.type.savings.warning') }}
    </Alert>

    <b-form-group
      :label="$t('bank.name.label')"
      :label-for="'name-on-account-' + _uid"
      label-cols-md="3"
      class="required"
    >
      <!-- See the same field in the various <Type>Info components -->
      <b-form-input
        :id="'name-on-account-' + _uid"
        ref="userName"
        v-model="bankData.userName"
        :state="!v$.bankData.userName.$error"
        name="user-name"
        aria-describedby="bank-username-validation-errors"
        @input="v$.bankData.userName.$reset()"
        @blur="v$.bankData.userName.$touch()"
      />
      <ValidationErrors
        id="bank-username-validation-errors"
        :validator="v$.bankData.userName"
        :errors="{
          required: $t('bank.name.required'),
          lastNameRequired: $t('bank.name.last_name_required'),
          maxLength: $t('field_max_length', { len: 100 }),
          doesNotContainAccountNumber: $t('validation.cannot_contain_account_number'),
          notOnlyDigits: $t('validation.cannot_contain_only_digits'),
          noLongNumbers: $t('validation.cannot_contain_long_numbers'),
        }"
      />
    </b-form-group>

    <BillingAddressInfo
      v-if="needsBillingAddress"
      ref="billingAddressInfo"
      :billing-data="bankData.billingAddress"
      :disabled-fields="disabledFields"
      :default-billing-address="defaultBillingAddress"
      :pre-fill-address-label="preFillAddressLabel"
    />

    <b-form-group
      v-slot="{ ariaDescribedby }"
      :label="$t('bank.account_category.label')"
      label-class="form-radio-label"
      label-cols="3"
      class="required"
    >
      <b-form-radio-group
        v-model="bankData.bankAccountCategory"
        :aria-describedby="ariaDescribedby"
        :class="!v$.bankData.bankAccountCategory.$error
          ? 'is-valid'
          : 'is-invalid'
        "
        name="account-category"
        required
      >
        <b-form-radio
          v-for="{text, value, disabled} in accountCategories"
          :ref="`accountCategory${firstToUpperCase(value)}`"
          :key="value"
          :data-test="`account-category-${value}`"
          :value="value"
          :disabled="disabled"
          aria-describedby="bank-account-category-validation-errors"
        >
          {{ text }}
        </b-form-radio>
      </b-form-radio-group>
      <ValidationErrors
        id="bank-account-category-validation-errors"
        :validator="v$.bankData.bankAccountCategory"
        :errors="{
          required: $t('bank.account_category.required'),
        }"
      />
      <!--
        1. Vue 2 creates an array for any ref set inside a v-for (even if there's only one).
        2. We need to use the $el because the :id would be set on the input element (which is hidden for radios).
        3. :target needs to be a function -->
      <b-tooltip
        v-if="disabledFields.bankCategoryPersonal"
        :target="() => $refs.accountCategoryPersonal[0] && $refs.accountCategoryPersonal[0].$el"
        :title="$t('bank.account_category.commercial.required')"
        triggers="hover"
        custom-class="disabled-personal-accounts-tooltip"
        container="e-wallet"
      />
    </b-form-group>

    <template
      v-if="bankData.bankAccountCategory === 'commercial'"
    >
      <b-alert
        v-if="!isManagementView && useAltDebitFilterAdvisory && debitSendingIds.length"
        show
        variant="warning"
      >
        <span v-dompurify-html="debitFilterAdvisory" />
      </b-alert>
      <p
        v-else-if="isManagementView"
        v-dompurify-html="$t('bank.advisory_management')"
        class="py-2 mb-0"
      />
    </template>

    <PaymentFormFooter
      ref="paymentFormFooter"
      :payment-data="bankData"
      :nickname-placeholder="$t('bank.nickname.placeholder')"
      :show-save-buttons="showSaveButtons"
      :is-selection-view="isSelectionView"
      :show-save-checkbox="showSaveCheckbox"
      :show-one-time-use-disclosure="alwaysGetWarehouseToken &&
        showOneTimeUseDisclosure"
      :only-anonymous-tenders="onlyAnonymousTenders"
      :max-return-fee="maxReturnFee"
      :force-save-method="forceSaveMethod"
      tender-type="bank"
      @cancel="$emit('cancel')"
      @save="$emit('save')"
    />

  </div>
</template>

<script>
import Vue from 'vue'
import BillingAddressInfo from '@grantstreet/psc-vue/components/BillingAddressInfo.vue'
import { mapGetters, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength } from '@vuelidate/validators'
import { lastNameRequired, noLongNumbers } from '@grantstreet/psc-js/utils/validators.js'
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import PaymentFormFooter from './PaymentFormFooter.vue'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import abaValidator from 'bank-routing-number-validator'
import { firstToUpperCase } from '@grantstreet/psc-js/utils/cases.js'
import { translateList } from '@grantstreet/psc-vue/utils/i18n.ts'

import eCheckImgEn from '../assets/images/echeck_sample.png'
import eCheckImgEs from '../assets/images/echeck_sample-es.png'
import MaskedInput from '@grantstreet/psc-vue/components/MaskedInput.vue'

export default {
  emits: ['save', 'cancel'],
  components: {
    BillingAddressInfo,
    ValidationErrors,
    PaymentFormFooter,
    MaskedInput,
    Alert,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    bankData: {
      type: Object,
      required: true,
    },
    debitSendingIds: {
      type: Array,
      required: true,
    },
    needsBillingAddress: {
      type: Boolean,
      default: true,
    },
    showSaveCheckbox: {
      type: Boolean,
      default: false,
    },
    showSaveButtons: {
      type: Boolean,
      default: false,
    },
    prefillConfirmation: {
      type: Boolean,
      default: false,
    },
    isManagementView: {
      type: Boolean,
      required: true,
    },
    isSelectionView: {
      type: Boolean,
      required: true,
    },
    alwaysGetWarehouseToken: {
      type: Boolean,
      required: true,
    },
    showOneTimeUseDisclosure: {
      type: Boolean,
      required: true,
    },
    disabledFields: {
      type: Object,
      required: true,
    },
    lastNameRequired: {
      type: Boolean,
      default: false,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    defaultBillingAddress: {
      type: Object,
      default: () => ({}),
    },
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
    maxReturnFee: {
      type: String,
      default: '',
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      accountNumberConfirmation: this.prefillConfirmation ? this.bankData.bankAccountNumber : null,
      bankMessage: null,
      bankError: null,
      showCopyMessage: false,
    }
  },

  computed: {
    typeOptions () {
      return [
        {
          text: this.$t('bank.type.checking.default'),
          value: 'checking',
        },
        {
          text: this.$t('bank.type.savings.default'),
          value: 'savings',
        },
      ]
    },

    accountCategories () {
      return [
        {
          text: this.$t('bank.account_category.personal'),
          value: 'personal',
          disabled: this.disabledFields.bankCategoryPersonal,
        },
        {
          text: this.$t('bank.account_category.commercial.default'),
          value: 'commercial',
        },
      ]
    },

    eCheckImgSrc () {
      return this.$i18n.locale === 'es' ? eCheckImgEs : eCheckImgEn
    },

    debitFilterAdvisory () {
      return this.debitSendingIds?.length
        ? this.$t('bank.advisory_checkout', { ids: translateList(this.debitSendingIds) })
        : ''
    },

    ...mapGetters('eWallet', [
      'environment',
      'loggedIn',
      '$api',
    ]),

    ...mapState('eWallet', [
      'useAltDebitFilterAdvisory',
    ]),
  },

  watch: {
    'bankData.bankRoutingNumber': async function (val) {
      this.bankError = ''
      this.bankMessage = ''

      if (!val) {
        return
      }

      if (val.length !== 9 || /\D/.test(val)) {
        // XXX: i18n
        this.bankError = 'The routing number must contain nine digits'
        return
      }

      // Perform a checksum validation
      if (!this.isValidABANumber(val)) {
        this.bankError = 'Invalid routing number'
        return
      }

      try {
        // XXX: Might want to cancel any previous requests?
        const { data } = await this.$api.getRoutingNumber(val)

        // If the ABA service is down, still let the user check out
        if (!data) {
          return
        }

        if (data.allow_ach_transfers !== 1) {
          // XXX: i18n
          this.bankError = 'This routing number may not be used for ACH transfers.'
        }
        else if (data.new_routing_number !== data.routing_number) {
          // XXX: i18n
          this.bankMessage = `
              <span class="text-info">According to the Federal Reserve Bank, this routing
                number has been changed to <strong>${data.new_routing_number}</strong>.
                It is recommended that you update the above field accordingly.</span>
            `
        }
        else if (data.customer_name) {
          // XXX: i18n
          this.bankMessage = `
              <span class="text-muted">Bank Name:</span> ${data.customer_name}
            `
        }

        Vue.set(this.bankData, 'bankName', data.customer_name || '')
      }
      catch (error) {
        // XXX: i18n
        if (error?.response?.status === 404) {
          this.bankError = 'Invalid routing number'
        }
        else {
          // We got a non-200, non-404 response from E-Wallet. Either E-Wallet
          // has a bug or the ABA service is down. In either case we should
          // let the user try to check out with the routing number.
          //
          // Parse and stringify because otherwise console.error will
          // auto-stringify the error and lose the details.
          console.error(JSON.parse(JSON.stringify(error)))
        }
      }
    },
  },

  validations: {
    bankData: {
      bankRoutingNumber: {
        required,
        maxLength: maxLength(9),
        validRoutingNumber () {
          return !this.bankError
        },
      },
      bankAccountNumber: {
        required,
        // The built-in "required" doesn't work with the getter/setter
        notTheSameAsRoutingNumber (val) {
          if (!val) {
            return true
          }
          return val !== this.bankData.bankRoutingNumber
        },
        // PEx's requirements:
        isValidAccountNumber (val) {
          return /^[A-Z0-9]+$/i.test(val)
        },
        minLength: minLength(3),
        maxLength: maxLength(17),
      },
      userName: {
        required,
        lastNameRequired (val) {
          if (!this.lastNameRequired) {
            return true
          }
          return lastNameRequired(val)
        },
        maxLength: maxLength(100),
        doesNotContainAccountNumber (val) {
          if (!val) {
            return true
          }
          const stripped = val.replace(/\s/g, '')
          return !stripped.includes(this.bankData.bankAccountNumber)
        },
        notOnlyDigits (val) {
          if (!val) {
            return true
          }
          // Don't report both this and "cannot contain account number"
          const stripped = val.replace(/\s/g, '')
          return stripped.includes(this.bankData.bankAccountNumber) ||
            !/^\d+$/.test(stripped)
        },
        noLongNumbers,
      },
      bankAccountCategory: {
        required,
      },
    },

    // This isn't in bankData so we don't save the confirmation with our data
    accountNumberConfirmation: {
      required,
      // This has to be a function to access the nested bankData.bankAccountNumber:
      sameAsAccountNumber (val) {
        if (!val) {
          return true
        }
        return val === this.bankData.bankAccountNumber
      },
    },
  },

  methods: {
    firstToUpperCase,

    validate () {
      this.v$.$touch()
      const billingValid = !this.needsBillingAddress || this.$refs.billingAddressInfo.validate()
      const footerValid = this.$refs.paymentFormFooter.validate()

      return !this.v$.$invalid && billingValid && footerValid
    },

    isValidABANumber (val) {
      if (!this.environment.match(/prod/)) {
        switch (val) {
        case '222222226':
        case '333333334':
        case '444444442':
        case '555555000':
        case '666666668':
        case '777777776':
        case '999999992':
          return true
        }
      }

      return abaValidator.ABARoutingNumberIsValid(val)
    },

    initialize () {
      this.accountNumberConfirmation = ''
      this.$refs.paymentFormFooter.initialize()
      this.v$.$reset()
      if (this.needsBillingAddress) {
        this.$refs.billingAddressInfo.v$.$reset()
      }
    },
    focusOnError () {
      if (this.v$.bankData.bankRoutingNumber.$error) {
        this.$refs.bankRoutingNumber.focus()
        return
      }
      if (this.v$.bankData.bankAccountNumber.$error) {
        this.$refs.bankAccountNumber.$refs.maskedInput.focus()
        return
      }
      if (this.v$.accountNumberConfirmation.$error) {
        this.$refs.accountNumberConfirmation.$refs.maskedInput.focus()
        return
      }
      if (this.v$.bankData.userName.$error) {
        this.$refs.userName.focus()
        return
      }
      if (this.$refs.billingAddressInfo.v$.$errors?.length) {
        this.$refs.billingAddressInfo.focusOnError()
        return
      }
      if (this.v$.bankData.bankAccountCategory.$error) {
        this.$refs.accountCategoryPersonal[0].focus()
      }
    },

    updateRoutingNumber () {
      if (this.bankData.bankRoutingNumber) {
        this.bankData.bankRoutingNumber = this.bankData.bankRoutingNumber.replace(/\D/g, '')
      }
      this.v$.bankData.bankRoutingNumber.$touch()
    },

    blurAccountNumber () {
      if (this.bankData.bankAccountNumber) {
        this.bankData.bankAccountNumber = this.bankData.bankAccountNumber.trim()
      }
      this.v$.bankData.bankAccountNumber.$touch()
    },

    blurAccountNumberConfirmation () {
      if (this.accountNumberConfirmation) {
        this.accountNumberConfirmation = this.accountNumberConfirmation.trim()
      }
      this.v$.accountNumberConfirmation.$touch()
    },
  },
}
</script>
