<template>
  <div
    id="e-wallet"
  >
    <div
      v-if="error || (isManagementView && !loggedIn)"
      class="text-danger p-3 text-center font-italic"
    >
      {{ errorMessage }}
    </div>
    <template v-else>

      <b-overlay
        :show="$wait.is('submitting') || $wait.is('inPaypal')"
        rounded="sm"
        variant="white"
        opacity="0.65"
        no-wrap
      >
        <template #overlay>
          <div class="py-4 text-center">
            <div class="spinner spinner-large" />
          </div>
        </template>
      </b-overlay>

      <b-overlay
        :show="!shouldDisplayEwallet || !haveEnabledMethods"
        rounded="sm"
        variant="white"
        opacity="0.65"
        no-wrap
      >
        <template #overlay>
          <Alert
            v-if="true"
            :variant="'warning'"
            :dismissible="false"
            :show-icon="true"
            class="m-1"
          >
            <div data-test="ewallet-lock-message">
              {{ $t(disableEwalletMessageFinal) }}
            </div>
          </Alert>
        </template>
      </b-overlay>

      <template v-if="ready">
        <TenderManager
          ref="tenderManager"
          :enabled="enabled && shouldDisplayEwallet"
          :extra-fields="extraFieldsFinal"
          :default-billing-address="defaultBillingAddressCopy"
          :pre-fill-address-label="preFillAddressLabel"
          :scroll-container="scrollContainer"
          :is-management-view="isManagementView"
          :is-selection-view="isSelectionView"
          :show-one-time-use-disclosure="showOneTimeUseDisclosure"
          :always-get-warehouse-token="alwaysGetWarehouseToken"
          :always-get-warehouse-token-for-banks="alwaysGetWarehouseTokenForBanks"
          :always-save-tender="verifiedAlwaysSaveTender"
          :auto-pay-selected="autoPaySelected"
          :convenience-fees="convenienceFees"
          :translated-fee-keys="translatedFeeKeys"
          :needs-billing-address="needsBillingAddress"
          :show-fee-checkbox="showFeeCheckbox"
          :debit-sending-ids="debitSendingIds"
          :pre-expand-card-form="preExpandCardForm"
          :allowed-methods="allowedMethodsFinal"
          :restricted-methods="restrictedMethods"
          :disabled-fields="disabledFieldsFinal"
          :data-vault-token="dataVaultTokenFinal"
          :initial-tender-id="initialTenderId"
          :show-save-checkbox="showSaveCheckbox"
          :last-name-required="lastNameRequired"
          :only-anonymous-tenders="onlyAnonymousTenders"
          :admin-filling="adminFilling"
          :max-return-fee="maxReturnFee"
          :paypal-config-prop="paypalConfig"
          :force-save-method="forceSaveMethod"
          :first-method="firstMethod"
          :hide-tenders="hideTenders"
          :disabled-tenders-message="disabledTendersMessage"
          :disabled-tenders-tooltips="disabledTendersTooltips"
          :is-two-pass-authorization="isTwoPassAuthorization"
          :enable-sms="enableSms"
          :cart-subtotal="cartSubtotal"
          :client-title="clientTitle"
          :no-submit-button="noSubmitButton"
          :before-submit-prop="beforeSubmit"
          :get-updated-fee-prop="getUpdatedFee"
          :submit-callback-prop="submitCallback"
          :use-expiring-tenders="useExpiringTenders"
          :disabled-card-brands="disabledCardBrands"
          :apple-pay-config="applePayConfig"
          :google-pay-config="googlePayConfig"
          :submit-button-text="submitButtonText"
          :cart-has-delayed-payments="cartHasDelayedPayments"
          :is-mixed-rex-cart="isMixedRexCart"
          :hide-fee-message="hideFeeMessage"
          :use-cost-linked-pricing="useCostLinkedPricing"
          :force-multi-use-tender="forceMultiUseTender"
          @validation-error="errors => $emit('validationError', errors)"
          @tender-type-changed="type => $emit('tenderTypeChanged', type)"
          @tender-form-type-changed="type => $emit('tenderFormTypeChanged', type)"
          @selected-data-changed="data => $emit('selectedDataChanged', data)"
          @extra-fields-update="data => $emit('extraFieldsUpdate', data)"
          @phone-update="phone => $emit('phoneUpdate', phone)"
          @contact-preference-update="preference => $emit('contactPreferenceUpdate', preference)"
        >
          <template #additional-checkboxes>
            <slot name="additional-checkboxes" />
          </template>
        </TenderManager>
      </template>

      <LoadingBars
        v-else
        class="py-4"
      />

    </template>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import TenderManager from './TenderManager.vue'
import requireKeys from '@grantstreet/psc-js/utils/require-keys.js'
import { sentryException } from '../../sentry.js'
import Alert from '@grantstreet/psc-vue/components/Alert.vue'
import LoadingBars from '@grantstreet/loaders-vue/LoadingBars.vue'
import { canUseApplePay } from '@grantstreet/psc-js/utils/apple-pay.js'
import { addI18n } from '@grantstreet/psc-vue/utils/i18n.ts'

export default {
  emits: [
    'validationError',
    'tenderTypeChanged',
    'tenderFormTypeChanged',
    'selectedDataChanged',
    'extraFieldsUpdate',
    'phoneUpdate',
    'contactPreferenceUpdate',
  ],
  components: {
    TenderManager,
    Alert,
    LoadingBars,
  },

  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    defaultBillingAddress: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // This is a localization object, like: { en: '', es: '' }
    preFillAddressLabel: {
      type: Object,
      required: false,
      default: null,
    },
    allowedMethods: {
      type: Array,
      default: () => (['card', 'bank']),
    },
    restrictedMethods: {
      type: Array,
      default: () => ([]),
    },
    scrollContainer: {
      type: String,
      required: false,
      // This is intentional. It means that default params will work correctly.
      default: undefined,
    },
    dataVaultToken: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: 'selection',
    },
    autoPaySelected: {
      type: Boolean,
      default: false,
    },
    convenienceFees: {
      type: Object,
      default: () => ({}),
    },
    // An array of fee structure descriptions that will be displayed in the
    // "about the fee" modal. Each array element should be an object with
    // translation strings ({ en: '...', es: '...' }).
    translatedFeeKeys: {
      type: Array,
      default: () => ([]),
    },
    showFeeCheckbox: {
      type: Boolean,
      default: false,
    },
    preExpandCardForm: {
      type: Boolean,
      default: true,
    },
    needsBillingAddress: {
      type: Boolean,
      default: true,
    },
    debitSendingIds: {
      type: Array,
      default: () => ([]),
    },
    // Remove after A/B test
    useAltDebitFilterAdvisory: {
      type: Boolean,
      default: false,
    },
    // Perform a $0 authorization on e-wallet submission
    // to associate a reusable warehouse_token with the tender
    alwaysGetWarehouseToken: {
      type: Boolean,
      default: true,
    },
    // Perform a $0 authorization on e-wallet submission for banks
    alwaysGetWarehouseTokenForBanks: {
      type: Boolean,
      default: true,
    },
    showOneTimeUseDisclosure: {
      type: Boolean,
      default: true,
    },
    // Always save the e-wallet tender.
    // This should not be explicitly false if alwaysGetWarehouseToken is true.
    alwaysSaveTender: {
      type: Boolean,
      default: null,
    },
    feeName: {
      type: String,
      default: null,
    },
    extraFields: {
      type: Object,
      default: () => ({}),
    },
    disabledFields: {
      type: Array,
      default: () => ([]),
    },
    textOverrides: {
      type: Object,
      default: () => ({}),
    },
    // May be an ewalletToken or the tenderId
    initialTenderId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    showSaveCheckbox: {
      type: Boolean,
      default: true,
    },
    lastNameRequired: {
      type: Boolean,
      default: false,
    },
    onlyAnonymousTenders: {
      type: Boolean,
      default: false,
    },
    adminFilling: {
      type: Boolean,
      default: false,
    },
    maxReturnFee: {
      type: String,
      default: '',
    },
    noSubmitButton: {
      type: Boolean,
      default: false,
    },
    paypalConfig: {
      type: Object,
      default: null,
    },
    forceSaveMethod: {
      type: Boolean,
      default: false,
    },
    firstMethod: {
      type: String,
      default: '',
    },
    hideTenders: {
      type: Array,
      default: () => [],
    },
    disabledTendersMessage: {
      type: String,
      default: '',
    },
    disabledTendersTooltips: {
      type: Object,
      default: () => ({}),
    },
    isTwoPassAuthorization: {
      type: Boolean,
      default: false,
    },
    enableSms: {
      type: Boolean,
      default: false,
    },
    cartSubtotal: {
      type: [String, Number],
      default: '',
    },
    clientTitle: {
      type: String,
      default: '',
    },
    useExpiringTenders: {
      type: Boolean,
      default: false,
    },
    achAuthorizationText: {
      type: Object,
      default: () => ({}),
    },
    beforeSubmit: {
      type: Function,
      default: null,
    },
    getUpdatedFee: {
      type: Function,
      default: null,
    },
    submitCallback: {
      type: Function,
      default: null,
    },
    shouldDisplayEwallet: {
      type: Boolean,
      default: true,
    },
    disableEwalletMessage: {
      type: String,
      default: '',
    },
    disabledCardBrands: {
      type: Array,
      default: () => ([]),
    },
    applePayConfig: {
      type: Object,
      default: null,
    },
    googlePayConfig: {
      type: Object,
      default: null,
    },
    submitButtonText: {
      type: String,
      default: null,
    },
    cartHasDelayedPayments: {
      type: Boolean,
      default: false,
    },
    isMixedRexCart: {
      type: Boolean,
      default: false,
    },
    hideFeeMessage: {
      type: Boolean,
      default: false,
    },
    useCostLinkedPricing: {
      type: Boolean,
      default: false,
    },
    forceMultiUseTender: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      ready: false,
      error: false,
      errorMessage: 'You must be logged in to manage your payment methods.',
      disabledFieldsFinal: {},
      allowedMethodsValidated: [],
      defaultBillingAddressCopy: {},
    }
  },

  computed: {
    allowedMethodsFinal () {
      const allowedMethods = [ ...this.allowedMethodsValidated ]

      // Add Apple Pay if user can do so.
      if (this.applePayConfig && canUseApplePay(sentryException)) {
        allowedMethods.push('apple')
      }

      // Add Google Pay if the site setting is enabled (the config will be
      // non-null)
      if (this.googlePayConfig) {
        allowedMethods.push('google')
      }

      return allowedMethods
    },

    dataVaultTokenFinal () {
      // This allows setting to null/undefined specifically so that
      // we can test it in the little demo page.
      return !this.dataVaultToken
        ? this.jwt.public_dv_token
        : this.dataVaultToken
    },

    isManagementView () {
      return this.view === 'management'
    },

    isSelectionView () {
      return this.view === 'selection'
    },

    extraFieldsFinal () {
      const allowedExtraFields = ['phone', 'email', 'contactPreference']
      const extraFieldsFinal = {}

      for (const [field, opts] of Object.entries(this.extraFields)) {
        if (!allowedExtraFields.includes(field)) {
          console.error(`E-Wallet Error: "${field}" (in extraFieldsProp) is not supported`)
          continue
        }
        if (!requireKeys(opts, ['required'])) {
          console.error(`E-Wallet Error: extraFieldsProp["${field}"] must have a "required" property`)
          continue
        }

        extraFieldsFinal[field] = this.extraFields[field]
      }

      return extraFieldsFinal
    },

    haveEnabledMethods () {
      return this.allowedMethods.filter(method => !this.restrictedMethods.includes(method)).length > 0
    },

    disableEwalletMessageFinal () {
      if (!this.haveEnabledMethods) {
        return 'tender_manager.not_payable'
      }
      return this.disableEwalletMessage
    },

    // Provide a reasonable value for alwaysSaveTender
    // taking alwaysGetWarehouseToken into consideration
    verifiedAlwaysSaveTender () {
      if (this.alwaysSaveTender === null) {
        return this.alwaysGetWarehouseToken && this.alwaysGetWarehouseTokenForBanks
      }

      if (this.alwaysGetWarehouseToken && this.alwaysGetWarehouseTokenForBanks && !this.alwaysSaveTender) {
        // Intentionally being cagey here
        // as alwaysGetWarehouseToken = true and as alwaysGetWareHouseTokenForBanks
        // potentially allows for $0 authorizations and we shouldn't advertise that
        sentryException(new Error('Invalid configuration, tenders will always be saved'))
        return true
      }

      return this.alwaysSaveTender
    },

    // XXX: No vuex modules but eWallet and API are available here because we
    // embed in non-GH environments.
    ...mapGetters('eWallet', [
      'loggedIn',
      'jwt',
      'networkError',
    ]),

    ...mapState('eWallet', [
      'jwtFailure',
    ]),
  },

  watch: {
    allowedMethods (newVal) {
      this.setAllowedMethods(newVal)
    },

    disabledFields (newVal) {
      this.setDisabledFields(newVal)
    },

    jwtFailure (failed) {
      if (failed) {
        this.error = true
        this.errorMessage = this.$t('jwt.expired')
        return
      }
      this.error = false
      this.errorMessage = ''
    },

    // in case of special network errors, show the appropriate translation and
    // hide e-wallet
    networkError (err) {
      this.error = true
      this.errorMessage = this.$t(err)
    },
  },

  created () {
    const overrides = {}
    // fee.label, along with several other agreements and notices, is a
    // translation which is dependant on the feeNameKey. fee.name is provided so
    // these translations can be used when needed. This means that eWallet
    // can continue to use and respect the config feeNameKey and the
    // preset values it controls on a per instance basis.
    // * Handling it this way means that parent apps like PayHub can set a default
    // * fee.name without waiting for eWallet to load.
    if (Boolean(this.feeName) || !this.$te('fee.name')) {
      let feeNameKey = this.feeName || 'convenience'

      if (!['convenience', 'service', 'processing'].includes(feeNameKey)) {
        const error = `E-Wallet: "${feeNameKey}" is not a supported fee name`
        sentryException(error)
        console.error(error)
        feeNameKey = 'convenience'
      }

      // Setup i18n linked message
      feeNameKey = `@:${feeNameKey}`

      overrides['fee.name'] = { en: feeNameKey, es: feeNameKey }
    }

    // eslint-disable-next-line camelcase
    overrides.ach_authorization_text = this.achAuthorizationText

    for (const [key, mapping] of Object.entries(this.textOverrides)) {
      overrides[key] = mapping
    }

    addI18n(overrides, sentryException)
  },

  mounted () {
    // Log props passed to e-wallet
    try {
      this.logDiagnostics({
        // We stringify these at PLAT's request so we're not blowing up kibana
        // with new fields
        eWalletProps: JSON.stringify(this.$props),
      })
    }
    catch (error) {
      sentryException(new Error('Couldn\'t stringify eWallet.vue props.'))
    }

    this.defaultBillingAddressCopy = this.defaultBillingAddress

    this.$store.commit('eWallet/setDebitFilterAdvisoryFlag', this.useAltDebitFilterAdvisory)

    // Setup tenders
    if (this.loggedIn && !this.onlyAnonymousTenders) {
      this.$store.dispatch('eWallet/retrieveTenders')
        .then(() => {
          this.ready = true
        })
        .catch(error => {
          this.error = true
          this.errorMessage = 'Could not load E-Wallet. Please reload the page.'
        })
    }
    else {
      this.$store.commit('eWallet/setTenders', [])
      this.ready = true
    }

    if (
      this.dataVaultToken &&
      this.jwt.public_dv_token &&
      this.dataVaultToken !== this.jwt.public_dv_token
    ) {
      // Log to Sentry (PSC-462)
      console.error(`JWT mismatch: ${this.dataVaultToken} !== ${this.jwt.public_dv_token}`)
      sentryException(new Error('JWT Mismatch'))
    }

    // Validate our config options
    if (!this.dataVaultTokenFinal && this.allowedMethods.includes('card')) {
      console.error('E-Wallet Error: dataVaultToken is required when card payments are allowed')
    }

    this.setDisabledFields(this.disabledFields)

    this.setAllowedMethods(this.allowedMethods)

    // Paypal requires some additional configuration:
    if (this.allowedMethods.includes('paypal')) {
      const cfg = this.paypalConfig

      if (!cfg) {
        console.error('E-Wallet Error: You must provide PayPal configuration')
      }
      else if (!cfg.clientId || !cfg.merchantIds) {
        console.error('E-Wallet Error: You must provide PayPal clientId and merchantIds')
      }
      else if (!cfg.createOrder || !cfg.onApprove) {
        console.error('E-Wallet Error: You must provide PayPal createOrder and onApprove callbacks')
      }
      else if (this.cartHasDelayedPayments && (!cfg.createVaultSetupToken || !cfg.savePayPalTender)) {
        console.error('E-Wallet Error: You must provide PayPal createVaultSetupToken and savePayPalTender callbacks for delayed payments')
      }
    }

    if (this.networkError) {
      this.error = true
      this.errorMessage = this.$t(this.networkError)
    }
  },

  // If the user is behind a VPN on the payhub site, they may be redirected away
  // from E-Wallet to view the network error page. If they choose to navigate
  // back to E-Wallet, we don't want them to proceed. Unfortunately the
  // networkError watcher above won't trigger when they're off the checkout
  // page, so we have to manually call it here when they navigate back
  activated () {
    if (this.networkError) {
      this.error = true
      this.errorMessage = this.$t(this.networkError)
    }
  },

  methods: {
    ...mapActions('eWallet', [
      'logDiagnostics',
    ]),

    submit () {
      return this.$refs.tenderManager.submit()
    },

    validate () {
      return this.$refs.tenderManager.validateWithScroll()
    },

    setApplePayError (error) {
      this.$refs.tenderManager.setApplePayError(error)
    },

    clearApplePayError () {
      this.$refs.tenderManager.clearApplePayError()
    },

    setGooglePayError (error) {
      this.$refs.tenderManager.setGooglePayError(error)
    },

    clearGooglePayError () {
      this.$refs.tenderManager.clearGooglePayError()
    },

    setCardError (error) {
      this.$refs.tenderManager.setCardError(error)
    },

    setBankError (error) {
      this.$refs.tenderManager.setBankError(error)
    },

    setPaypalError (error) {
      this.$refs.tenderManager.setPaypalError(error)
    },

    clearPaypalError () {
      this.$refs.tenderManager.clearPaypalError()
    },

    clearFeeCheckbox () {
      this.$refs.tenderManager.clearFeeCheckbox()
    },

    setDisabledFields (disabledFields) {
      if (!disabledFields) {
        return
      }

      const allowedDisabledFields = ['address2', 'bankCategoryPersonal']

      for (const field of disabledFields) {
        if (!allowedDisabledFields.includes(field)) {
          console.error(`E-Wallet Error: "${field}" (in disabledFieldsProp) is not supported`)
          continue
        }
        this.disabledFieldsFinal[field] = true
      }
    },

    setAllowedMethods (methods) {
      // If nothing is passed default to card and bank. This is probably best
      // for external integrations like REx. If an empty array is passed then
      // use it. PayHub et al may want to pass along a payable that doesn't
      // allow payment.
      if (!Array.isArray(methods)) {
        const error = new Error('E-Wallet Error: allowedMethodsProp must be an array containing one or more of "bank", "card", and "paypal". No array provided. Defaulting to ["bank", "card"].')
        sentryException(error)
        console.error(error)
        this.allowedMethodsValidated = ['card', 'bank']
        return
      }

      // If an invalid method is passed ignore it
      this.allowedMethodsValidated = methods.filter(method => {
        if (method === 'bank' || method === 'card' || method === 'paypal') {
          return true
        }
        const error = new Error(`E-Wallet Error: unknown method "${method}" in allowedMethodsProp. Must be "bank", "card", or "paypal". Skipping method.`)
        sentryException(error)
        console.error(error)
        return undefined
      })
    },

    resetTenderForms () {
      this.$refs.tenderManager.resetTenderForms()
    },

  },
}
</script>

<style lang="scss">

#e-wallet {
  // Necessary to support b-overlay. Cannot be done with bootstrap classes since
  // those are scoped *inside* this div for embedded sites
  position: relative;
}

</style>
